import { ImFilesEmpty } from "react-icons/im";
import { ColumnsConfig, Table } from "../../../components/table";
import { BreadcrumbList } from "../../../components/breadcrumb";
import { useCollectiveAgreementRequestsFetch } from "./hooks";
import { FaTimes, FaEye } from "react-icons/fa";
import { CollectiveAgreementDetailContent } from "../collectiveAgreementDetail/CollectiveAgreementDetailContent";
import { useState } from "react";
import { CollectiveAgreementRequestsApi } from "./hooks/CollectiveAgreementRequestApi.types";
import { useNavigate } from "react-router";
import { LayoutCard } from "./_components/LayoutCard";
import Modal from "react-bootstrap/Modal";
import { SpinerFullScreen } from "../../../components/commonComponents/SpinerFullScreen";
import { Pagination } from "./_components/Pagination";

export const CollectiveAgreementRequestsContent = () => {
  const { collectiveAgreement, submitUpdateBenefitRequest, isLoading } =
    useCollectiveAgreementRequestsFetch();
  const [selectedRequest, setSelectedRequest] =
    useState<CollectiveAgreementRequestsApi | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalIcon, setModalIcon] = useState<"success" | "error">("success");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const statusColors: { [key: string]: string } = {
    Aprobado: "#90ee90",
    Pendiente: "#87cefa",
    Cancelado: "#f08080",
  };

  const displayModal = (message: string, icon: "success" | "error") => {
    setModalMessage(message);
    setModalIcon(icon);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleViewDetails = (
    colectiveRequest: CollectiveAgreementRequestsApi
  ) => {
    setSelectedRequest(colectiveRequest);
  };

  const handleBackClick = () => {
    setSelectedRequest(null);
  };

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/co/beneficios/convencion-colectiva/solicitud");
  };

  const handleDeleteRequest = async (requestId: number) => {
    try {
      const result = await submitUpdateBenefitRequest(requestId);
      if (result.success) {
        displayModal("La solicitud se canceló correctamente", "success");
      } else {
        displayModal(result.message, "error");
      }
    } catch (error) {
      displayModal("Error al cancelar la solicitud", "error");
    }
  };

  const mappedData = collectiveAgreement?.map((item) => ({
    ...item,
    status: (
      <span>
        <span style={{ color: statusColors[item.status], marginRight: "8px" }}>
          ●
        </span>
        {item.status}
      </span>
    ),
    action: (
      <div
        className="d-flex justify-content-center"
        style={{ minWidth: "100px" }}
      >
        {item.status !== "Cancelado" && item.status !== "Aprobado" ? (
          <button
            className="btn btn-link p-0"
            onClick={() => handleDeleteRequest(item.requestId)}
          >
            <FaTimes size={20} color="#FF6347" />
          </button>
        ) : (
          <span style={{ width: "24px" }}></span>
        )}
        <button
          className="btn btn-link p-0"
          onClick={() => handleViewDetails(item)}
        >
          <FaEye size={20} color="#FFB74D" />
        </button>
      </div>
    ),
  }));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = mappedData?.slice(indexOfFirstItem, indexOfLastItem);


  const columnsConfigCollective: ColumnsConfig[] = [
    {
      accessor: "requestId",
      header: "Nro Solicitud",
      className: "text-center",
    },
    {
      accessor: "benefitName",
      header: "Beneficio",
      className: "text-center",
    },
    {
      accessor: "dateRequest",
      header: "Fecha Solicitud",
      className: "text-center",
    },
    {
      accessor: "status",
      header: "Estado",
      className: "text-center",
    },
    {
      accessor: "action",
      header: "Acciones",
      className: "text-center",
    },
  ];

  const totalItems = mappedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <>
      <BreadcrumbList
        items={["Beneficios", "Convención colectiva", "Mis solicitudes"]}
      />

      <section>
        <h1>Mis solicitudes</h1>
        <div className="text-end mb-3">
          <button className="btn btn-degradado" onClick={handleRedirect}>
            Solicitar Beneficio
          </button>
        </div>
      </section>
      {isLoading && <SpinerFullScreen />}

      {!isLoading && selectedRequest ? (
        <CollectiveAgreementDetailContent
          selectedRequest={selectedRequest}
          onBack={handleBackClick}
        />
      ) : collectiveAgreement?.length === 0 ? (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "300px", textAlign: "center" }}
        >
          <ImFilesEmpty
            style={{ width: "100px", height: "100px", marginBottom: "20px" }}
          />
          <p className="fs-4 text-muted">
            Actualmente no tienes solicitudes creadas
          </p>
        </div>
      ) : (
        <>
          <div
            className="box col-12 col-xl-12 d-flex flex-column"
            style={{ height: "60%" }}
          >
            <div className="flex-grow-1">
              <LayoutCard
                title={"Solicitudes"}
                columnsConfig={columnsConfigCollective}
                data={currentItems}
              />
            </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
          </div>
        </>
      )}

      <Modal show={showModal} onHide={hideModal} centered>
        <Modal.Body className="text-center py-4">
          <i
            className={`fa ${
              modalIcon === "success"
                ? "fa-check-circle-o"
                : "fa-times-circle-o"
            } fa-3x mb-2`}
            style={{
              color: modalIcon === "success" ? "green" : "red",
            }}
            aria-hidden="true"
          ></i>
          <h2 className="h5">{modalMessage}</h2>
        </Modal.Body>
      </Modal>
    </>
  );
};
