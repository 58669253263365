import { useState, useEffect, useRef, FC, ReactNode } from "react";
import { useForm } from "react-hook-form";
import OfflineModal from "../../commons/plain/modals/offline";
import services from "../../../../components/newsletter/services";
import { scrollToTop } from "../../../../services/utiles";
import { Props1, Request } from "./interfaces";
import NewsletterSurvey from "./components/NewsletterSurvey";
import { toggleModal } from "../../../../components/commonComponents/Modal";

const TenthStep: FC<Props1> = ({ prevStep, token }) => {
    const { register, handleSubmit, watch } = useForm();
    const [disableButton, setDisableButton] = useState(true);
    const [newsletter, setNewsletter] = useState<Array<{ [K: string]: string }>>([]);
    const [newsLetterStatus, setNewsLetterStatus] = useState('init');
    
    const fetchNewsletter = async () => {
        setNewsLetterStatus('loading');
        const nl = await services.getNewsLetter(token);
        if (nl && nl.subscriptions && nl.subscriptions.length > 0) {
            setNewsletter(nl.subscriptions);
            setNewsLetterStatus('init');
        }
    };

    useEffect(() => {
        scrollToTop();
        fetchNewsletter();
    }, []);

    useEffect(() => {
        if (newsLetterStatus === 'loading') toggleModal({ variant: "loading" });
        else toggleModal();
    }, [newsLetterStatus]);

    const openRetryModal = () => {
        toggleModal({
            variant: "default",
            content: <>
                <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                <p>No hemos logrado completar la petición, vuelve a intentar porfavor.</p>
            </>
        });
    }

    const fetchData = async (request : any) => {
        setNewsLetterStatus('loading');
        const status = await services.postNewsLetter({ request }, token);
        if (status?.valid) {
            setNewsLetterStatus('success');
        }
        else {
            setNewsLetterStatus('init');
            openRetryModal();
            setDisableButton(false);
        }
    };

    const exit = async (request: any) => {
        setNewsLetterStatus('loading');
        document.getElementById("openThanksModal")?.click();
        const status = await services.postNewsLetter({ request }, token);
        if (status?.valid) {
            setNewsLetterStatus('success');
        }
        else {
            setNewsLetterStatus('init');
            openRetryModal();
            setDisableButton(false);
        }
    };

    const onSubmit = (data: any) => {
        if (navigator.onLine) {
            setDisableButton(true);
            let request: Request = {
                subscribed: data["subscribed"] === "true",
                subscriptions: [],
            };
            let subscriptions: Array<string> = [];
            if (data["subscribed"] === "true") {
                for (let key in data) if (data[key] && key !== "subscribed") subscriptions.push(key);
                request["subscriptions"] = subscriptions;
                fetchData(request);
            } else {
                exit(request);
            }
        } else {
            document.getElementById("offline")?.click();
        }
    };

    return <>
        {newsletter.length > 0 && newsLetterStatus === 'init' &&
            <form className="needs-validation" onSubmit={handleSubmit(onSubmit)} >
                <h1 className="text-center">Únete a nuestras suscripciones </h1>
                <div className="box">
                    <h2 className="h4 tit-section">Elegir suscripción</h2>
                    <div className="row justify-content-center">
                        { newsletter.map((nl: any, i) => {
                            const isSelected = watch(nl.value)
                            return <div key={i} className="col-md-5 mb-4 d-flex newsletter-ficha-box">
                                <label className="text-center">
                                    <input
                                        type="checkbox"
                                        id={nl.value}
                                        defaultChecked={nl.selected}
                                        className=""
                                        {...register(nl.value)}
                                    />
                                    <h3 className="h4">{nl.title}</h3>
                                    <p>{nl.description}</p>
                                    {
                                        isSelected ? 
                                        <i className="fa fa-check-circle-o color-orange fa-2x" aria-hidden="true" /> :
                                        <p className="p-3"></p>
                                    }
                                </label>
                            </div>
                        })}
                    </div>
                    <div className="pt-4 mt-2 border-top">
                        <div className="form-check mb-2 ms-1 mb-3">
                            <input className="form-check-input h5" type="radio" value="true" {...register("subscribed")} onChange={() => { setDisableButton(false) }} />
                            <label className="form-check-label fw-normal color-primary ms-2" htmlFor="consentimiento">
                                <strong>
                                    Doy mi consentimiento para que Cencosud envíe información de mi interés como Noticias, Comunicados, Novedades sobre Beneficios, Servicios u otros, 
                                    a mi correo personal, por mensaje de Texto o WhatsApp a mi número de teléfono y correos personal registrados en los sistemas de RR.HH.
                                </strong>
                            </label>
                        </div>
                        <div className="form-check mb-2 ms-1 mb-3">
                            <input className="form-check-input h5" type="radio" value="false" {...register("subscribed")} onChange={() => { setDisableButton(false) }} />
                            <label className="form-check-label fw-normal color-primary ms-2" htmlFor="no-suscripcion">
                                No, no deseo suscribirme.
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-end mt-5 mb-5">
                    <div className="col-12 d-flex justify-content-between">
                        <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); prevStep() }}>
                            <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                            Anterior
                        </button>
                        <button type="submit" className="btn btn-degradado" disabled={disableButton}>
                            Siguiente <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </form>
        }
        {newsLetterStatus === 'success' && <NewsletterSurvey token={token} /> }
        <OfflineModal />
    </>;
};

export default TenthStep;
