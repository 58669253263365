import { BreadcrumbList } from "../../components/breadcrumb";
import { LunchScheduleModal } from "./LunchScheduleModal";
import { useLunchScheduleProvider } from "./context/LunchScheduleContext";
import { SpinerFullScreen } from "../../components/commonComponents/SpinerFullScreen";
import { useState } from "react";
import { CalendarContainer } from "./CalendarContainer";

export const LunchScheduleContent = () => {
  const {
    monthDays,
    selectedDays,
    handleDayClick,
    handleLoadData,
    showModal,
    handleSave,
    handleDelete,
    handleSelectChange,
    modalData,
    months,
    selectedMonth,
    handleMonthChange,
    isLoading,
    handleCloseModal,
    handleUpdate,
  } = useLunchScheduleProvider();

  const [hoveredDate, setHoveredDate] = useState<string | null>(null);

  return (
    <div className="p-sm-3 container m-auto">
      <BreadcrumbList items={["Programación Comedores"]} />
      <h1>Programación Comedores</h1>
      {isLoading ? (
        <SpinerFullScreen />
      ) : (
        <div className="box mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="h4 tit-section">Menú de {selectedMonth}</h2>
            <select
              className="p-2 rounded-lg border border-gray-300"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {months.map((month) => (
                <option key={month.id} value={month.month}>
                  {month.month}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-3">
            <CalendarContainer
              monthDays={monthDays}
              selectedMonth={selectedMonth}
              selectedDays={selectedDays}
              handleDelete={handleDelete}
              handleDayClick={handleDayClick}
              setHoveredDate={setHoveredDate}
              hoveredDate={hoveredDate}
            />
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="btn btn-degradado"
              disabled={selectedDays.length === 0}
              onClick={handleLoadData}
            >
              Cargar datos
            </button>
          </div>
        </div>
      )}
      {showModal && (
        <LunchScheduleModal
          modalData={modalData}
          handleSelectChange={handleSelectChange}
          handleDelete={handleDelete}
          handleSave={handleSave}
          handleCloseModal={handleCloseModal}
          handleUpdate={handleUpdate}
        />
      )}
    </div>
  );
};
