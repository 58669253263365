export const StepThree = () => {
  const data = [
    { name: "Mateo Fernández", percentage: "50%" },
    { name: "Juan Pérez", percentage: "40%" },
    { name: "Ana Gómez", percentage: "60%" },
    { name: "Laura Martínez", percentage: "75%" },
  ];

  return (
    <>
      <div className="box" style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <h3>Beneficiarios</h3>
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 0",
              fontSize: "14px",
            }}
          >
            <span style={{ fontWeight: "normal" }}>{item.name}</span>
            <span style={{ color: "#007bff", fontWeight: "bold" }}>
              {item.percentage}
            </span>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center" style={{marginTop: '16px',}}>
        <button
          onClick={() => console.log("boton")}
          className="btn btn-primary"
          type="button"
        >
          Descargar PDF
        </button>
      </div>
      <div className="d-flex justify-content-end">
        <button
          onClick={() => console.log("boton")}
          className="btn btn-degradado"
          type="button"
        >
          Firmar
        </button>
      </div>
    </>
  );
};
