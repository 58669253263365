import { Dispatch, RefObject, SetStateAction } from "react";
import * as t from "io-ts";
import { Control, FieldErrors, UseFormClearErrors, UseFormRegister, UseFormSetError, UseFormSetValue, UseFormTrigger, UseFormWatch } from "react-hook-form";

interface refs {
    refStatus?: RefObject<HTMLButtonElement>;
    refConfirmAction?: RefObject<HTMLButtonElement>;
    refLoad?: RefObject<HTMLButtonElement>;
    refLoadClose?: RefObject<HTMLButtonElement>;
    refCloseStatus?: RefObject<HTMLButtonElement>;
}
interface formPropsNews {
    control: Control<NewsProps>;
    errors: FieldErrors<NewsProps>;
}
interface showTable {
    setShowTable: Dispatch<SetStateAction<boolean>>;
}
interface IndexProps extends refs, showTable {
    setNewNews: Dispatch<SetStateAction<boolean>>;
    setAdminCategory: Dispatch<SetStateAction<boolean>>;
    setIdNews: Dispatch<SetStateAction<number>>;
    setIsEditNews: Dispatch<SetStateAction<boolean>>;
    idNews: number;
    reload: boolean;
    setReload: Dispatch<SetStateAction<boolean>>;
    pais: string;
    isSubtitleVisible: boolean;
    setIsSubtitleVisible: Dispatch<SetStateAction<boolean>>;
}
interface TableProps extends refs, showTable {
    newsList: ResponseNewsListData;
    filterCountry: string;
    startDateFilter: string;
    endDateFilter: string;
    filterCategory: number;
    filterStatus: string;
    setIdNews: Dispatch<SetStateAction<number>>;
    setIsEditNews: Dispatch<SetStateAction<boolean>>;
    setDataModal: Dispatch<SetStateAction<DataModalDinamicProps>>;
}
interface SelectIndexProps {
    filters: filtersIndex;
    pais: string;
    filterCountry: string;
    setFilterCountry: Dispatch<SetStateAction<string>>;
    setStartDateFilter: Dispatch<SetStateAction<string>>;
    setEndDateFilter: Dispatch<SetStateAction<string>>;
    setFilterCategory: Dispatch<SetStateAction<number>>;
    setFilterStatus: Dispatch<SetStateAction<string>>;
}
interface TableCategoryProps extends refs {
    categoryList: CategoryProps[];
    setIsEditCategory: Dispatch<SetStateAction<boolean>>;
    setEditProps: Dispatch<SetStateAction<CategoryProps>>;
}
interface AdminCategoryProps extends refs, showTable {
    pais: string
    setAdminCategory: Dispatch<SetStateAction<boolean>>;
}
interface ContentNewsProps extends formPropsNews {
    isEditBenef: boolean;
    paramFilter: ParametersFilter[];
    textDescr: string;
    textCond: string;
    errorUrl: string;
    selectModule: string;
    arrayLinks: typeLinks[];
    history: HistoryProp[];
    setArrayLinks: Dispatch<SetStateAction<typeLinks[]>>;
    setTextDescr: Dispatch<SetStateAction<string>>;
    setTextCond: Dispatch<SetStateAction<string>>;
    setValue: UseFormSetValue<NewsProps>;
    setSelectModule: Dispatch<SetStateAction<string>>;
    register: UseFormRegister<NewsProps>;
    trigger: UseFormTrigger<NewsProps>;
}
interface NewNewsProps extends refs {
    isEditNews: boolean;
    idNews: number;
    setShowTable: Dispatch<SetStateAction<boolean>>;
    setNewNews: Dispatch<SetStateAction<boolean>>;
    setIsEditNews: Dispatch<SetStateAction<boolean>>;
    setIdNews: Dispatch<SetStateAction<number>>;
    pais: string;
    isSubtitleVisible: boolean;
    setIsSubtitleVisible: Dispatch<SetStateAction<boolean>>;

}
interface SearchBarProps {
    wordEntered: string;
    iconArray: SearchProps[];
    errors: FieldErrors<CategoryProps>;
    register: UseFormRegister<CategoryProps>;
    setSearchResults: Dispatch<SetStateAction<SearchProps[]>>;
    setWordEntered: Dispatch<SetStateAction<string>>;
}
interface SearchProps {
    label: string;
    value: string;
}
interface DataModalDinamicProps {
    icon: string;
    msg: string;
    submsg?: string;
    callBack: () => void;
}
interface DynamicSelectProps {
    arrayData: Data[];
    setStatus: Dispatch<SetStateAction<any>>;
    status?: string;
    isLabel: boolean;
    pais?: string;
    isRequired?: boolean;
}
interface ModalAsignCatProps extends refs {
    editProps: CategoryProps;
    isEditCategory: boolean;
    defaultPropsEdit: CategoryProps
    countryFilters: Data[];
    pais: string;
    setEditProps: Dispatch<SetStateAction<CategoryProps>>;
    setReload: Dispatch<SetStateAction<boolean>>;
    setDataModal: Dispatch<SetStateAction<DataModalDinamicProps>>;
}
interface Groups {
    id: number | string;
    title: string;
    description?: string;
    disabled?: boolean
}
interface SelectGroupProps {
    arrayGroup: Groups[];
    setArrayGroup: Dispatch<SetStateAction<Groups[]>>;
    group: typeGroups[];
}
interface typeLinks {
    id?: number;
    url: string;
}
interface SelectLinkProps {
    arrayLinks: typeLinks[];
    setArrayLinks: Dispatch<SetStateAction<typeLinks[]>>;
}
interface typeFile {
    file: string;
    name_file: string;
}
interface FormNewsProps extends formPropsNews {
    msjErrorDate: string;
    isEditNews: boolean;
    paramFilter: ParametersFilter[];
    arrayGroup: Groups[];
    selectCountry: string;
    errorSelectGroup: string;
    imgBanner: string;
    imgCard: string;
    setImgBanner: Dispatch<SetStateAction<string>>;
    setSelectCountry: Dispatch<SetStateAction<string>>;
    setImgCard: Dispatch<SetStateAction<string>>;
    setFile: Dispatch<SetStateAction<typeFile>>;
    setSelectCategory: Dispatch<SetStateAction<string>>;
    setArrayGroup: Dispatch<SetStateAction<Groups[]>>;
    register: UseFormRegister<NewsProps>;
    isErrorPhoto: boolean;
    pais: string;
    isFile: boolean;
    idNews: number;
    clearErrors: UseFormClearErrors<NewsProps>;
    setError: UseFormSetError<NewsProps>;
    file: typeFile;
    setIsFile: Dispatch<SetStateAction<boolean>>;
    setValue: UseFormSetValue<NewsProps>;
    watch: UseFormWatch<NewsProps>;
    isSubtitleVisible: boolean;
    setIsSubtitleVisible: Dispatch<SetStateAction<boolean>>;

}

interface modalPreview {
    watchedValues: any,
    imgCard: string,
    arrayLinks: typeLinks[];
    isFile: boolean;
}

//Groups

export const groupsArray = t.type({
    id: t.union([t.string, t.number]),
    title: t.string,
    disabled: t.union([t.undefined, t.boolean])
})

export type typeGroups = t.TypeOf<typeof groupsArray>

export const groupsParams = t.type({
    valid: t.string,
    status: t.string,
    groups: t.array(groupsArray)
});

export const dataSelects = t.type({
    id: t.union([t.string, t.number]),
    label: t.string,
    country_id: t.union([t.string, t.undefined]),
})

export type Data = t.TypeOf<typeof dataSelects>

export const FiltersTable = t.type({
    countryFilters: t.array(dataSelects),
    categoryFilters: t.array(dataSelects),
    statusFilter: t.array(dataSelects),
})

export const NewsListTable = t.type({
    id: t.number,
    created_by: t.string,
    title: t.string,
    date_to: t.string,
    date_from: t.string,
    status: t.string,
    category: t.type({
        id: t.number,
        title: t.string,
        country: t.type({
            id: t.string,
            description: t.string
        })
    }),
    news_group: t.array(t.type({
        title: t.string
    })),
    total_likes: t.number,
    total_comments: t.number,
    total_views: t.number,
})

export type NewsTable = t.TypeOf<typeof NewsListTable>

export type filtersIndex = t.TypeOf<typeof FiltersTable>;

export const ResponseNews = t.type({
    newsList: t.array(NewsListTable),
    filters: FiltersTable,
});

export const fetchGetNewsListCodec = t.type({
    response: ResponseNews
});

export type ResponseNewsListData = t.TypeOf<typeof ResponseNews>;

export type ResponseNewsList = t.TypeOf<typeof fetchGetNewsListCodec>;

export const fetchPostNewsCodec = t.type({
    valid: t.boolean
});
export interface bodyPostCategoryNews {
    id?: number,
    country_id?: string,
    title?: string,
    icon?: string,
}
export interface bodyPostNewsList {
    id?: number,
}

export type PostNews = t.TypeOf<typeof fetchPostNewsCodec>;

export interface bodyPostCommentNews {
    id?: number,
    comment?: string
}

//Category

export const CategoryTable = t.type({
    id: t.union([t.undefined, t.number]),
    country_id: t.string,
    description_country: t.string,
    icon: t.string,
    title: t.string,
})

export const ResponseCategory = t.type({
    categories: t.array(CategoryTable),
    countryFilter: t.array(dataSelects),
});

export type CategoryProps = t.TypeOf<typeof CategoryTable>

export const fetchGetCategoryListCodec = t.type({
    response: ResponseCategory
});

export type ResponseCategoryList = t.TypeOf<typeof fetchGetCategoryListCodec>;

//News

export const News = t.type({
    id: t.union([t.undefined, t.number]),
    title: t.string,
    is_visible_title: t.boolean,
    is_featured: t.boolean,
    subtitle: t.string,
    is_visible_subtitle: t.boolean,
    category_id: t.number,
    img_banner: t.string,
    img_card: t.string,
    date_to: t.string,
    date_from: t.string,
    file: t.string,
    name_file: t.string,
    description: t.string,
    condition: t.string,
    //is_condition_visible: t.boolean,
    // is_request: t.boolean,
    status: t.string,
})

export interface bodyPostNews {
    id?: number,
    title: string,
    is_visible_title: boolean,
    is_featured: boolean,
    subtitle: string,
    is_visible_subtitle: boolean,
    category_id: number,
    img_banner: string,
    img_card: string,
    date_to: string,
    date_from: string,
    file: string,
    name_file: string,
    description: string,
    condition: string,
    //is_condition_visible: boolean,
    // is_request: boolean,
    status: string,
    country_id: string,
    groups: Groups[],
    links: typeLinks[]
}
//History
export const HistoryProps = t.type({
    full_name: t.string,
    created_at: t.string,
})

export type HistoryProp = t.TypeOf<typeof HistoryProps>

export const reponseGetNews = t.type({
    id: t.number,
    title: t.string,
    is_visible_title: t.boolean,
    is_featured: t.union([t.boolean, t.undefined]),
    subtitle: t.string,
    is_visible_subtitle: t.boolean,
    category_id: t.number,
    date_to: t.union([t.string, t.undefined]),
    date_from: t.union([t.string, t.undefined]),
    description: t.string,
    condition: t.string,
    status: t.union([t.string, t.undefined]),
    // country: t.string,
    country_id: t.string,
    created_by: t.union([t.string, t.undefined]),
    videos: t.array(t.type({
        id: t.number,
        url: t.string
    })),
    history: t.union([t.undefined, t.array(HistoryProps)]),
    total_likes: t.number,
    own_like: t.number,
    news_comments: t.array(t.type({
        id: t.number,
        comment: t.string,
        created_at: t.string
    })),
    created_at: t.string
})

export const fetchGetCommentNews = t.type({
    comments: t.array(t.type({
        id: t.number,
        comment: t.string,
        created_at: t.string
    })),
    valid: t.boolean
});

export type GetCommentNews = t.TypeOf<typeof fetchGetCommentNews>;

export const NewsComment = t.type({
    id: t.number,
    comment: t.string,
    created_at: t.string
})

export type NewsComments = t.TypeOf<typeof NewsComment>

export type NewsProps = t.TypeOf<typeof News>

export type ReponseGetNews = t.TypeOf<typeof reponseGetNews>


//News by ID
export const moduleReqType = t.type({
    id: t.number,
    component_id: t.number,
    title: t.string,
    component: t.type({
        url: t.string
    })
});

export const fetchGetNewsByIDCodec = t.type({
    response: t.type({
        news: reponseGetNews,
        groups: t.array(groupsArray),
        isFile: t.boolean,
    })
});

export type GetNewsByIDProps = t.TypeOf<typeof fetchGetNewsByIDCodec>;

//parameters filter

export const Parameters = t.type({
    country: t.string,
    countryDescr: t.string,
    categories: t.array(
        t.type({
            id: t.string,
            label: t.string,
            categories: t.array(dataSelects)
        })),
    countryFilters: t.array(dataSelects),
    groups: t.array(groupsArray),
})

export const ParamsNews = t.type({
    parameters: t.array(Parameters)
});

export const fetchGetParamsNewsCodec = t.type({
    response: ParamsNews
});

export type GetParamsNews = t.TypeOf<typeof fetchGetParamsNewsCodec>;

export type ParametersFilter = t.TypeOf<typeof Parameters>

//Banner

export const bannerProps = t.type({
    id: t.number,
    img_banner: t.string,
    title: t.string,
    is_visible_title: t.boolean,
    is_visible_subtitle: t.boolean,
})

export type BannerProps = t.TypeOf<typeof bannerProps>;

export const bannerHomeProps = t.type({
    id: t.number,
    img_card: t.string,
    title: t.string,
    subtitle: t.string
})

export type BannerHomeProps = t.TypeOf<typeof bannerHomeProps>;


export const fetchGetBannersCodec = t.type({
    response: t.type({
        banner: t.array(bannerProps)
    })
});

export type GetBanner = t.TypeOf<typeof fetchGetBannersCodec>;

export const fetchGetBannersCodecHome = t.type({
    response: t.type({
        banner: t.array(bannerHomeProps)
    })
});

export type GetBannerHome = t.TypeOf<typeof fetchGetBannersCodecHome>;

//News cards

export const Categories = t.type({
    id: t.number,
    title: t.string,
    icon: t.string
});

export type categories = t.TypeOf<typeof Categories>;

export const NewsCards = t.type({
    id: t.number,
    title: t.string,
    // subtitle: t.string,
    description: t.string,
    // is_visible_subtitle: t.boolean,
    img_card: t.union([t.string, t.undefined]),
    category: Categories,
    // img_banner: t.union([t.string, t.undefined, t.null])
});

export type newsListCards = t.TypeOf<typeof NewsCards>;

export const fetchGetNewsCardCodec = t.type({
    response: t.type({
        categories: t.array(Categories),
        newsList: t.array(NewsCards)
    })
});

export type GetNewsCard = t.TypeOf<typeof fetchGetNewsCardCodec>;

//photo news

export const photoNews = t.type({
    id: t.number,
    img_card: t.string,
    img_banner: t.union([t.string, t.undefined])
})

export type PhotoNewsProps = t.TypeOf<typeof photoNews>;

export const fetchGetPhotoNewsCodec = t.type({
    response: t.type({
        photo: t.array(photoNews)
    })
});

export type GetPhotoNews = t.TypeOf<typeof fetchGetPhotoNewsCodec>;

//file

export const fetchGetFileNewsCodec = t.type({
    response: t.type({
        file: t.string,
        name_file: t.string,
    })
});

export type GetFileNews = t.TypeOf<typeof fetchGetFileNewsCodec>;

//banner news by ID

export const bannerNews = t.type({
    id: t.number,
    img_banner: t.string,
    title: t.string,
})

export type BannerNewsProps = t.TypeOf<typeof bannerNews>;

export type {
    IndexProps,
    TableProps,
    SelectIndexProps,
    AdminCategoryProps,
    ContentNewsProps,
    NewNewsProps,
    ModalAsignCatProps,
    SelectGroupProps,
    Groups,
    FormNewsProps,
    TableCategoryProps,
    SearchBarProps,
    SearchProps,
    DynamicSelectProps,
    SelectLinkProps,
    typeLinks,
    typeFile,
    DataModalDinamicProps,
    modalPreview
};