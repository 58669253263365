import { createContext, useContext } from "react";
import { InsuranceContextType, InsuranceProviderProps } from "../hooks/InsuranceApi.types";

export const InsuranceDataContext = createContext<
InsuranceContextType | undefined
>(undefined);

export const InsuranceProvider = (props: InsuranceProviderProps) => {
    const { children } = props;


    
    const contextValue = {

      };

      return (
        <InsuranceDataContext.Provider value={contextValue}>
          {children}
        </InsuranceDataContext.Provider>
      );
    };
    
    export const useLunchScheduleProvider = () => {
      const insuranceDataContext = useContext(InsuranceDataContext);
    
      if (!insuranceDataContext) return {} as InsuranceContextType;
    
      return insuranceDataContext;
    };