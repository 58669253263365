import { FC, useContext, useEffect, useState } from "react";
import TableIndexOnb from "./components/table";
import SelectsOnb from "./components/selects";
import { scrollToTop } from "../../../../../services/utiles";
import { onboardingStore } from "../../../utilities/store";
import { IndexProps, OnboardingProps } from '../../../utilities/interfaces';
import { SpinerFullScreen } from "../../../../commonComponents/SpinerFullScreen";
import { Context } from "../../../../../context";

const IndexOnboarding: FC<IndexProps> = ({ getFiltredOnboardings, setShowOnboardingDetail }) => {
    const filters = onboardingStore(state => state.filters);
    const error = onboardingStore(state => state.error);
    const filterStatus = onboardingStore(state => state.filters.status);
    const filterCountry = onboardingStore(state => state.filters.country);
    const filterStartDate = onboardingStore(state => state.filters.startDate);
    const filterEndDate = onboardingStore(state => state.filters.endDate);
    const onboardings = onboardingStore(state => state.onboardings);
    const setSelectedOnboarding = onboardingStore(state => state.setSelectedOnboarding);
    const resetFilters = onboardingStore(state => state.resetFilters);
    const isLoading = onboardingStore(state => state.isLoading);
    const [filtredOnboardings, setFiltredOnboardings] = useState<OnboardingProps[]>([]);
    const { pais } = useContext(Context);
    
    useEffect(() => {
        resetFilters(pais);
        getFiltredOnboardings();
    }, []);

    useEffect(()=>{
        if (onboardings.length > 0) {
            let filters = onboardings.filter((onb) => {
                const fechaDesde = new Date(onb.start_date);
                const fechaHasta = new Date(onb.end_date);
                
                const country = 
                    filterCountry === '' || 
                    (filterCountry === 'regional' && onb.is_regional) ||
                    (filterCountry !== 'regional' && filterCountry === onb.region);
                
                const dateStart = !filterStartDate || fechaDesde >= new Date(filterStartDate);
                const dateEnd = !filterEndDate || fechaHasta <= new Date(filterEndDate);
                
                const status = !filterStatus || filterStatus === onb.status;
                
                const validDate = !filterStartDate || !filterEndDate || 
                    new Date(filterStartDate) <= new Date(filterEndDate);

                return country && dateStart && dateEnd && status && validDate;
            });
            setFiltredOnboardings(filters);
        }
    }, [onboardings, filters, filterStatus, filterCountry, filterStartDate, filterEndDate]);

return (
    <>
    <section className="gestion-de-onboarding">  
        <h1>Gestión de Onboarding</h1>
        <div className="box">
            <div className="tit-group d-md-flex justify-content-between">
                <h2 className="h4 tit-section"> Onboardings </h2>
                <div className="mb-3 mb-md-0">
                    <div className="d-grid d-md-flex gap-2">
                        <button 
                            type="button" 
                            className="btn btn-degradado ms-md-2"
                            onClick={()=>{
                                setShowOnboardingDetail(true)
                                setSelectedOnboarding(null);
                                scrollToTop();
                            }}  
                        >
                            Nuevo Onboarding
                            <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            {
                error ? 
                    <div className="text-center mt-5">
                        <h2>No se pudo obtener la información</h2>
                    </div>
                : isLoading ? 
                    <div className="text-center mt-5">
                        <SpinerFullScreen/>
                    </div>
                : onboardings.length === 0 ? 
                    <div className="text-center">
                        <p className="">No se ha encontrado información.</p>
                    </div>
                : 
                    <>
                        <SelectsOnb />
                        <TableIndexOnb 
                            getFiltredOnboardings={getFiltredOnboardings} 
                            filtredOnboardings={filtredOnboardings} 
                            setShowOnboardingDetail={setShowOnboardingDetail} 
                        />
                    </>
            }
            </div>
    </section>
    </>
);
}
export default IndexOnboarding;
