import { Children, FC } from "react";
import { useFormContext } from "react-hook-form";
import useWindowSize from "../../../elements/window-size-hook";
import { SurveyFormValues } from "../../interfaces";

interface NpsProps {
    id: string;
    titulo: string;
    tipo: "NPS";
    opcion: string[];
    likert: string[];
    handleChange: (params: any) => void;
}

const Nps: FC<NpsProps> = ({ 
    id, 
    titulo, 
    tipo, 
    opcion, 
    likert,
    handleChange 
}) => {
    const { width } = useWindowSize();
    const { register, formState: { errors } } = useFormContext<SurveyFormValues>();
    
    return (
        <div className="box mb-4">
            <h2 className="h3 tit-section">{titulo}</h2>
            <table className="table table-2 table-even table-borderless text-center table-w-100 d-none d-lg-table">
                <thead>
                    <tr>
                        {Children.toArray(opcion.map((op) => (
                            <th className="align-top">{op}</th>
                        )))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {Children.toArray(opcion.map((op) => (
                            <td>
                                {width > 991 && (
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        value={op}
                                        {...register(id, {
                                            required: "*Esta pregunta es obligatoria.",
                                            onChange: (e) => handleChange({ e, tipo, opcion })
                                        })}
                                    />
                                )}
                            </td>
                        )))}
                    </tr>
                </tbody>
            </table>
            <div className="form-check-group form-check-group-even d-lg-none">
                {Children.toArray(opcion.map((op) => (
                    <div className="form-check">
                        {width < 992 && (
                            <input
                                type="radio"
                                className="form-check-input"
                                value={op}
                                {...register(id, {
                                    required: "*Esta pregunta es obligatoria.",
                                    onChange: (e) => handleChange({ e, tipo, opcion })
                                })}
                            />
                        )}
                        <label className="form-check-label" htmlFor={id}>
                            {op}
                        </label>
                    </div>
                )))}
            </div>
            {errors[id]?.message && (
                <div className="invalid-feedback d-block">{errors[id]?.message || ""}</div>
            )}
        </div>
    );
};

export default Nps;