import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { SurveyFormValues } from "../../interfaces";
import { ERROR_MESSAGES, CSS_CLASSES } from '../../constants';

interface OpenProps {
    id: string;
    titulo: string;
    tipo: "OPEN";
    req: boolean;
    nomObj: string;
    otros: string;
    handleChange: (params: any) => void;
}

const Open: FC<OpenProps> = ({ 
    id, 
    titulo, 
    tipo, 
    req, 
    nomObj, 
    otros,
    handleChange 
}) => {
    const { register, watch, formState: { errors } } = useFormContext<SurveyFormValues>();
    const value = watch(nomObj);
    const isVisible = req || value === otros;
    
    return (
        <div className={`${CSS_CLASSES.BOX} ${CSS_CLASSES.MARGIN_BOTTOM}`} 
             style={{ display: isVisible ? "block" : "none" }}>
            <h2 className={CSS_CLASSES.TITLE_SECTION}>{titulo}</h2>
            <textarea
                className={CSS_CLASSES.FORM_CONTROL}
                id={id}
                {...register(id, {
                    required: isVisible ? ERROR_MESSAGES.REQUIRED_ANSWER : false,
                    onChange: (e) => handleChange({ e, otros, tipo })
                })}
            />
            {errors[id]?.message && (
                <div className={`${CSS_CLASSES.INVALID_FEEDBACK} d-block`}>
                    {errors[id]?.message || ""}
                </div>
            )}
        </div>
    );
};

export default Open;