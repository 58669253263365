import React, { useState } from "react";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import { Step } from "../../../components/step";
import warningMessage from "../../../components/SharedComponents/warning-message";
import confirmMessage from "../../../components/SharedComponents/confirm-message";

interface StepInsuranceProps {
  onBack?: () => void;
}

export const StepInsurance: React.FC<StepInsuranceProps> = ({ onBack }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isPercentageValid, setIsPercentageValid] = useState(false);

  const steps = [
    {
      label: "1",
      component: (
        <StepOne
          onPercentageChange={(isValid) => setIsPercentageValid(isValid)}
        />
      ),
    },
    { label: "2", component: <StepTwo /> },
    { label: "3", component: <StepThree /> },
  ];

  const handleSubmit = () => {
    if (isPercentageValid) {
      confirmMessage(() => {
        setCurrentStep((prev) => Math.min(prev + 1, steps.length));
      },
      "Verifique que sus datos personales y la informaciones de los beneficiarios sea correcta. Al continuar ya no podrá realizar modificaciones");
    } else {
      warningMessage(
        null,
        "La cantidad de beneficiarios existentes deben sumar 100%"
      );
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 5rem",
        }}
      >
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Step
              label={step.label}
              isActive={currentStep === index + 1}
              isCompleted={index + 1 < currentStep}
              onClick={() => setCurrentStep(index + 1)}
            />
            {index < steps.length - 1 && (
              <div
                style={{
                  flex: 1,
                  marginTop: "40px",
                  height: "2px",
                  backgroundColor:
                    index + 1 < currentStep ? "#28a745" : "#e9ecef",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="step-content">{steps[currentStep - 1].component}</div>

      {currentStep === 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <button onClick={onBack} className="btn btn-secondary">
            Volver
          </button>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};
