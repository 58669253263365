import { ReactNode, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import SurveyForm from "../../../../Survey/components/SurveyForm";
import useApiForm from "../../../../Survey/hooks/useApiForm";
import { urlsAws } from "../../../../../resources/foo/api-endpoints";
import PreviewBtn from "./modals/previewBtn";
import { toggleModal } from "../../../../commonComponents/Modal";
import { Context } from "../../../../../context";
import { ContextInterface } from "../../../../../context/utilities/interfaces";
import { SurveyFormValues } from "../../../../Survey/interfaces";

const NewsletterSurvey = ({ token }: { token: string }) => {
    const methods = useForm<SurveyFormValues>();
    const { signOut } = useContext<ContextInterface | null>(Context) as ContextInterface;

    const {
        initialData,
        initialError,
        submitData,
        submitError,
        loading,
        handleSubmit: handleSubmitCallback,
    } = useApiForm({ url: `${urlsAws.survey}?token=${token}`, session: undefined });

    const openSurveyStatusModal = ({ message }: { message: ReactNode }) => {
        toggleModal({
            variant: "default",
            content: <>
               {message}
                <div className="modal-footer">
                    <button type="button"
                        onClick={(e) => { signOut() }}
                        className="btn btn-primary px-5" data-bs-dismiss="modal">
                        Finalizar
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </>,
            onClose: () => {
                signOut();
            }
        });
    }

    const submitCallback = (resp: any) => {
        const sendWelcome = sessionStorage.getItem("sendWelcome");
        let message = "";
        if (sendWelcome === "true") {
            message = "Hemos recibido satisfactoriamente tus respuestas, las que serán compatidas con tus compañeros de equipo para que puedan conocerte mejor.";
        } else {
            message = "Hemos recibido satisfactoriamente tus respuestas";
        }
        openSurveyStatusModal({ 
            message: <>
                <i className="fa fa-check-circle-o fa-5x color-orange" aria-hidden="true" />
                <p>{message}</p>
            </>
        });
        return resp;
    }

    const surveyInitialErrorHandling = async () => {
        openSurveyStatusModal({ message: <>
            <div className="fs-5">
                <p>¡Muchas Gracias!</p>
                <p>
                    Hemos recibido satisfactoriamente tus opciones
                    de suscripción.<br />Puedes modificar tus preferencias
                    en cualquier momento en Mi Portal.
                </p>
            </div>
        </>
        });
        return null;
    }

    useEffect(() => {
        if (initialError) surveyInitialErrorHandling();
        if (submitError) {
            toggleModal({
                variant: "default",
                content: <>
                    <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                    <p>No hemos logrado completar la petición, vuelve a intentar porfavor.</p>
                </>
            });
        }
        if (submitData) submitCallback(submitData);
    }, [submitData, initialError, submitError]);

    useEffect(() => {
        if (loading) toggleModal({ variant: "loading" });
        else toggleModal();
    }, [loading]);

    return (
        <>
            {initialData && (
                <SurveyForm
                    survey={initialData}
                    handleSubmitCallback={handleSubmitCallback}
                    methods={methods}
                    SubmitButton={() => (
                        <PreviewBtn survey={initialData} getValues={methods.getValues} token={token} />
                    )}
                />
            )}
        </>
    );
}

export default NewsletterSurvey;
