import { ChangeEvent, FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import DynamicSelect from "../../../../../commons/DynamicSelect";
import { FormNewsProps, GetFileNews } from "../../../../../utilities/interfaces";
import SelectGroup from "./selectGroup/SelectGroup";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { HttpError } from "../../../../../../../services/apiServices/interfaces";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import { getFileByID } from "../../../../../utilities/apiServices/apiRequest";
import { httpClientEnv } from "../../../../../../../services/apiServices/httpClient";
import { downloadBase64, removeFileExt } from "../../../../../../../services/utiles";

const FormNews: FC<FormNewsProps> = ({
    register,
    control,
    paramFilter,
    setSelectCategory,
    imgCard,
    setImgCard,
    setFile,
    imgBanner,
    setImgBanner,
    errorSelectGroup,
    errors,
    arrayGroup,
    setArrayGroup,
    selectCountry,
    setSelectCountry,
    msjErrorDate,
    isEditNews,
    isErrorPhoto,
    pais,
    isFile,
    idNews,
    setError,
    clearErrors,
    setIsFile,
    file,
    setValue,
    watch,
    isSubtitleVisible
}) => {
    const [remoteDataFile, setRemoteDataFile] = useState<RemoteData<HttpError, GetFileNews>>(RD.initial);
    let isFeatured = watch('is_featured');

    const onChangeBanner = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];

        if (selectedFile) {
            if (selectedFile.size > 400 * 1024) {
                setError('img_banner', {
                    type: 'required',
                    message: 'La imagen es demasiado grande. Debe ser menor a 400 KB.'
                });
            } else {
                clearErrors('img_banner');
            }

            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);

            reader.onloadend = () => {
                setImgBanner(reader.result as string);
            };
        }
    };

    const onChangeCard = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 400 * 1024) {
                setError('img_card', {
                    type: 'required',
                    message: 'La imagen es demasiado grande. Debe ser menor a 400 KB.'
                });
            } else {
                clearErrors('img_card');
            }

            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);

            reader.onloadend = () => {
                setImgCard(reader.result as string);
            };
        }
    };

    const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
            const fileName = selectedFile.name;
            if (selectedFile.size > 10 * 1024 * 1024) {
                setError('file', {
                    type: 'required',
                    message: 'La archivo es demasiado grande. Debe ser menor a 10 MB.',
                });
            } else {
                clearErrors('file');
            }
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);

            reader.onloadend = () => {
                setFile({ file: reader.result as string, name_file: fileName });
            };
        }
        setIsFile(false);
    };

    const GetFileByID = async (idBenef: number) => {
        RTE.run(await getFileByID(idBenef), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataFile(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    downloadBase64(removeFileExt(data.value.response.name_file), data.value.response.file);
                    setFile({ file: data.value.response.file, name_file: data.value.response.name_file })
                    return setRemoteDataFile(RD.success(a));
                } else {
                    return setRemoteDataFile(RD.failure({ tag: 'httpRequestError', error: 'length GetNewsByID 0' }));
                }
            }))

    };

    return (
        <>
            <div className="box mb-4">
                <h2 className="h4 tit-section">{isEditNews ? 'Editar Noticia' : 'Crear Nueva Noticia'}</h2>
                <div className="border-md-bottom border-2 py-md-3">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <label className="data-list-box__title mb-2 mb-md-0">País *</label>
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                {paramFilter.length > 0 ?
                                    <DynamicSelect setStatus={setSelectCountry} arrayData={paramFilter[0].countryFilters} isLabel={false} status={selectCountry} pais={pais} />
                                    :
                                    <select className="form-select" disabled>
                                        <option selected disabled>Seleccione</option>
                                    </select>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-bottom border-2 py-3">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <label className="data-list-box__title mb-2 mb-md-0">Título *</label>
                        </div>
                        <div className="col-md-4">
                            <input className="form-control" type="text" placeholder="Ingresar texto"
                                {
                                ...register('title', {
                                    required: 'Ingresar título',
                                    validate: value => value.trim() !== '' || 'Ingresar título'
                                })
                                } />
                        </div>
                        <div className="col-md-6 mt-3 mt-md-0">
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox" value="" id="checkboxtitulo2"
                                    {
                                    ...register('is_featured', {
                                        required: false,
                                    })
                                    } />
                                <label className="form-check-label">Noticia Destacada</label>
                            </div>
                        </div>
                    </div>
                    {errors["title"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.title.message}</div>}
                </div>

                <div className="border-bottom border-2 py-3">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <label className="data-list-box__title mb-2 mb-md-0">Subtitulo / Bajada *</label>
                        </div>
                        <div className="col-md-4">
                            <input className="form-control" type="text" placeholder="Ingresar texto"
                                {
                                ...register('subtitle', {
                                    required: 'Ingresar subtítulo / bajada',
                                    validate: value => value.trim() !== '' || 'Ingresar subtítulo / bajada'
                                })
                                } />
                        </div>
                        <div className="col-md-6 mt-3 mt-md-0">
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox" value="" id="checkboxtitulo2"
                                    {
                                    ...register('is_visible_subtitle', {
                                        required: false,
                                    })
                                    } />
                                <label className="form-check-label">Subtítulo Banner Visible</label>
                            </div>
                        </div>
                    </div>
                    {errors["subtitle"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.subtitle.message}</div>}
                </div>

                <div className="border-bottom border-2 py-3">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <label className="data-list-box__title mb-2 mb-md-0">Categoría *</label>
                        </div>
                        <div className="col-md-4">
                            <div className="input-group">
                                {paramFilter.length > 0 && selectCountry !== '' ?
                                    <Controller
                                        name={'category_id'}
                                        control={control}
                                        rules={{ required: 'Este campo es requerido' }}
                                        render={({ field }) => (
                                            <select {...field} className="form-select" 
                                                onChange={(e) => {
                                                    field.onChange(e.target.value);
                                                    setSelectCategory(e.target.value)
                                            }}
                                            value={field.value || ""}>
                                                <option value="">Seleccione</option>
                                                {paramFilter[0].categories.map(category => (
                                                    category.categories.map((categ) => (
                                                        <option key={categ.id} value={categ.id}>
                                                            {categ.label}
                                                        </option>
                                                    ))
                                                ))}
                                            </select>
                                        )}
                                    />
                                    :
                                    <select className="form-select" disabled>
                                        <option selected disabled>Seleccione</option>
                                    </select>
                                }
                            </div>
                        </div>
                    </div>
                    {errors["category_id"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.category_id.message}</div>}
                </div>

                <div className="border-bottom border-2 py-3">
                    <div className="row">
                        <div className="col-md-2">
                            <label className="data-list-box__title mb-2 mb-md-0 mt-md-2">Imagen Noticia *</label>
                        </div>
                        <div className="col-md-4">
                            <input className="form-control" type="file" accept="image/*" id="selectImage2"
                                {
                                ...register('img_card', {
                                    required: imgCard ? false : true,
                                    onChange: (e) => { onChangeCard(e) }
                                })
                                } />
                            <small className="mt-2 d-block">Se recomienda una dimension de 500 x 200 pixeles</small>
                            {imgCard !== '' &&
                                <div className="align-items-start">
                                    <span className="data-list-box__title mb-2 mb-md-0"></span>
                                    <span className="data-list-box__text">
                                        <img
                                            src={imgCard}
                                            alt="Preview"
                                            style={{ maxHeight: '200px', borderRadius: '5px' }}
                                            className="img-fluid"
                                        />
                                    </span>
                                </div>
                            }
                            {errors["img_card"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.img_card.message}</div>}
                        </div>
                        {isFeatured && <>
                            <div className="col-md-2">
                                <label className="data-list-box__title mb-2 mb-md-0 mt-md-2">Imagen Banner *</label>
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                                <input className="form-control" type="file" accept="image/*" id="selectImage1"
                                    {
                                    ...register('img_banner', {
                                        required: imgBanner ? false : true,
                                        onChange: (e) => { onChangeBanner(e) }
                                    })
                                    } />
                                <small className="mt-2 d-block">Se recomienda una dimension de 1000 x 200 pixeles</small>
                                {imgBanner !== '' &&
                                    <div className="align-items-start">
                                        <span className="data-list-box__title mb-2 mb-md-0"></span>
                                        <span className="data-list-box__text">
                                            <img
                                                src={imgBanner}
                                                alt="Preview"
                                                style={{ maxHeight: '200px', borderRadius: '5px' }}
                                                className="img-fluid"
                                            />

                                        </span>
                                    </div>}
                                {errors["img_banner"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.img_banner.message}</div>}
                            </div>
                        </>}
                    </div>
                    {isErrorPhoto && <div style={{ display: "block" }} className="invalid-feedback">{'Ha ocurrido un error al obtener imagenes.'}</div>}

                </div>

                <div className="border-bottom border-2 py-3" id="divDates">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <label className="data-list-box__title mb-2 mb-md-0">Vigencia *</label>
                        </div>
                        <div className="col-md-4 mb-3 mb-md-0">
                            <div className="d-flex align-items-center">
                                <span>Desde</span>
                                <input className="form-control ms-3" type="date" placeholder="dd/mm/aaaa"
                                    {
                                    ...register('date_from', {
                                        required: 'Ingresar fecha desde',
                                    })
                                    } />
                            </div>
                            {errors["date_to"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.date_to.message}</div>}
                        </div>

                        <div className="col-md-4 mb-3 mb-md-0">
                            <div className="d-flex align-items-center">
                                <span>Hasta</span>
                                <input className="form-control ms-3" type="date" placeholder="dd/mm/aaaa"
                                    {
                                    ...register('date_to', {
                                        required: 'Ingresar fecha hasta',
                                    })
                                    } />
                            </div>
                            {errors["date_from"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.date_from.message}</div>}
                        </div>
                    </div>
                    {msjErrorDate !== '' && <div style={{ display: "block" }} className="invalid-feedback">{msjErrorDate}</div>}
                </div>

                <div className="border-bottom border-2 py-3">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <label className="data-list-box__title mb-2 mb-md-0">Archivo Adjunto</label>
                        </div>
                        <div className="col-md-6">
                            <input className="form-control" type="file"
                                accept=".pdf"
                                {
                                ...register('file', {
                                    required: false,
                                    onChange: (e) => { onChangeFile(e) }
                                })
                                } />
                        </div>
                        <div className="col-md-4 mt-2">
                            {isFile && isEditNews ?
                                <>
                                    <a className="text-decoration-none fw-bold btnLink" onClick={(e) => { GetFileByID(idNews) }}>
                                        Ver Archivo Adjunto
                                        <i className="fa fa-file-pdf-o fa-fw fa-lg ms-1" aria-hidden="true"></i>
                                    </a>
                                    <a
                                        id={"trash"}
                                        className="btnLink"
                                        onClick={(e) => {
                                            setIsFile(false);
                                            setFile({ file: '', name_file: '' });
                                            setValue('file', '');
                                        }}>
                                        <i className="fa fa-trash-o fa-fw fa-lg ms-4" title="Eliminar Archivo Adjunto" aria-hidden="true"></i>
                                    </a>
                                </>
                                : file.file &&
                                <>
                                    <a className="text-decoration-none fw-bold btnLink" onClick={(e) => { downloadBase64(removeFileExt(file.name_file), file.file); }}>
                                        Ver Archivo Adjunto
                                        <i className="fa fa-file-pdf-o fa-fw fa-lg ms-1" aria-hidden="true"></i>
                                    </a>
                                    <a
                                        id={"trash"}
                                        className="btnLink"
                                        onClick={(e) => {
                                            setIsFile(false);
                                            setFile({ file: '', name_file: '' });
                                            setValue('file', '');
                                        }}>
                                        <i className="fa fa-trash-o fa-fw fa-lg ms-4" title="Eliminar Archivo Adjunto" aria-hidden="true"></i>
                                    </a>
                                </>
                            }
                            {remoteDataFile._tag === 'RemoteFailure' && <div style={{ display: "block" }} className="invalid-feedback">{'Ha ocurrido un error al obtener documento.'}</div>}
                        </div>
                    </div>
                    {errors["file"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.file.message}</div>}
                </div>

                <SelectGroup arrayGroup={arrayGroup} group={paramFilter.length > 0 ? paramFilter[0].groups : []} setArrayGroup={setArrayGroup} />
                {errorSelectGroup !== '' && <div style={{ display: "block" }} className="invalid-feedback">{errorSelectGroup}</div>}

            </div>
        </>
    )
}

export default FormNews;