import { Table } from "../../../../components/table";
import { LayoutCardProps } from "./employeProfileSummary.types";

export const LayoutCard = (props: LayoutCardProps) => {
  const { title, columnsConfig,data, style } = props;
  return (
    <div>
      {title && <h2 className="h4 tit-section">{title}</h2>}

      <Table style={style} data={data} columnsConfig={columnsConfig} />
    </div>
  );
};
