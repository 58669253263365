import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const getHeader = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(async (auth) => {
        const header = {
          "Content-Type": "application/json",
          accesstoken: auth.getAccessToken().getJwtToken(),
        };
        resolve(header);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
};

const endpointsByCountry = {
  PE: "/api/datospersonales",
  CO: "/api/datospersonales",
  AR: "/api/Employee",
  UY: "/api/Employee",
};

const LoginService = {
  AddErrorLogin: async (body) => {
    try {
      const headers = await getHeader();
      const apiUrl = `${url}/api/login/error`;
      const response = await axios.post(apiUrl, body, { headers: headers });
      return response
    } catch (error) {
      console.error(error);
    }
  },

  getValidationLegajo: async (value) => {
    const endpoint = endpointsByCountry[value]
    try {
      const headers = await getHeader();
      const apiUrl = `${url}${endpoint}`;
      const response = await axios.get(apiUrl, { headers: headers });

      if (response.data.alternateEmployeeID) {
        return response.data.alternateEmployeeID
      } else {
        return response.data.CN_COL_DATPERS_GET.EMPLID
      }
    } catch (error) {
      console.error(error);
      return null
    }

  },

  getValidationDocumento: async () => {
    try {
      const headers = await getHeader();
      const apiUrl = `${url}/api/replacements/getreplacementprompt`;
      const response = await axios.get(apiUrl, { headers: headers });

      return response.status
    } catch (error) {
      console.error(error);
      return 500
    }
  }
}

export default LoginService;
