import * as Yup from 'yup';

export const MAX_IMAGE_SIZE = 1 * 1024 * 1024;

export const validationSchema = Yup.object().shape({
  title: Yup.string().required('El título es requerido'),
  text_content: Yup.string()
    .required('Este campo es requerido')
    .test('is-not-empty', 'El contenido no puede estar vacío', value => {
      const strippedValue = value.replace(/<[^>]+>/g, '').trim();
      return strippedValue.length > 0;
    }),
  status: Yup.string().required('Seleccione una opción'),
  start_date: Yup.date()
    .required('La fecha de inicio es requerida')
    .typeError('Fecha de inicio inválida'),
  end_date: Yup.date()
    .required('La fecha de fin es requerida')
    .typeError('Fecha de fin inválida')
    .min(
      Yup.ref('start_date'),
      'La fecha de fin debe ser mayor o igual a la fecha de inicio'
    ),
  is_regional: Yup.boolean(),
  region: Yup.string().when('is_regional', {
    is: false,
    then: (schema) => schema.required('El país es requerido'),
    otherwise: (schema) => schema.notRequired(),
  }),
  menuitem_id: Yup.string().when('is_regional', {
    is: false,
    then: (schema:any) => schema.required('El punto de menú es requerido'),
    otherwise: (schema:any) => schema.notRequired(),
  }),
  groups: Yup.array().when('is_regional', {
    is: false,
    then: (schema:any) => schema
      .min(1, 'Seleccione uno o más grupos.')
      .of(
        Yup.object().shape({
          id: Yup.string().required().notOneOf(['0'], 'Grupo inválido'),
          title: Yup.string().required(),
          region: Yup.string().required()
        })
      ),
    otherwise: (schema:any) => schema.notRequired(),
  }),
  image: Yup.string()
    .required('La imagen es requerida')
    .test('isValidImage', 'Debe ser una imagen válida', (value:any) => {
      return value && value.startsWith('data:image/');
    })
    .test('fileSize', 'El archivo es demasiado grande. El tamaño máximo es 1MB.', (value:string) => {
      if (!value || !value.startsWith('data:image/')) return true;
      const base64 = value.split(',')[1];
      const fileSize = base64 ? atob(base64).length : 0;
      return fileSize <= MAX_IMAGE_SIZE;
    }),
});
