import React, { FC, useEffect, useRef, useState } from "react";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { useForm } from "react-hook-form";
import { NewsProps, GetParamsNews, Groups, NewNewsProps, ParametersFilter, PostNews, typeLinks, bodyPostNews, GetNewsByIDProps, HistoryProp, typeFile, DataModalDinamicProps, GetPhotoNews } from "../../../utilities/interfaces";
import ContentNews from "./components/content/content";
import FormNews from "./components/form/form";
import { HttpError } from "../../../../../services/apiServices/interfaces";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import { httpClientEnv } from "../../../../../services/apiServices/httpClient";
import { crudNews, getNewsByID, getParamsNews, getRemotePhotosNewsList } from "../../../utilities/apiServices/apiRequest";
import ModalLoad from "../../../../commonComponents/ModalLoad";
import { scrollToTop } from "../../../../../services/utiles";
import ModalInfoDinamic from "../../../../commonComponents/ModalnfoDinamic";
import ModalConfirmAction from "../../../../commonComponents/ModalConfirmAction";
import ModalPreview from "./components/modalPrev/modalPreview";

const NewNews: FC<NewNewsProps> = ({
    setNewNews,
    setShowTable,
    isEditNews,
    setIdNews,
    idNews,
    setIsEditNews,
    refConfirmAction,
    refLoad,
    refStatus,
    refLoadClose,
    pais,
    isSubtitleVisible,
    setIsSubtitleVisible
}) => {
    const { register, handleSubmit, getValues, control, setValue, reset, watch, formState: { errors, isDirty, isValid }, trigger, clearErrors, setError } = useForm<NewsProps>({ mode: "onSubmit" });
    const [postDataNews, setPostDataNews] = useState<RemoteData<HttpError, PostNews>>(RD.initial);
    const [remoteDataNewsByID, setRemoteDataNewsByID] = useState<RemoteData<HttpError, GetNewsByIDProps>>(RD.initial);
    const [remoteDataParams, setRemoteDataParams] = useState<RemoteData<HttpError, GetParamsNews>>(RD.initial);
    const [remoteDataPhoto, setRemoteDataPhoto] = useState<RemoteData<HttpError, GetPhotoNews>>(RD.initial);
    const [textDescr, setTextDescr] = useState('');
    const [textCond, setTextCond] = useState('');
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [arrayGroup, setArrayGroup] = useState<Groups[]>([]);
    const [arrayLinks, setArrayLinks] = useState<typeLinks[]>([])
    const [selectCountry, setSelectCountry] = useState(pais || '');
    const [selectModule, setSelectModule] = useState("");
    const [selectCategory, setSelectCategory] = useState('');
    const [errorSelectGroup, setErrorSelectGroup] = useState('');
    const [errorUrl, setErrorUrl] = useState('');
    const [msjErrorDate, setMsjErrorDate] = useState('');
    const [imgCard, setImgCard] = useState('');
    const [imgBanner, setImgBanner] = useState('');
    const [file, setFile] = useState<typeFile>({ file: '', name_file: '' });
    const [isFile, setIsFile] = useState<boolean>(false);
    const [history, setHistory] = useState<HistoryProp[]>([])
    const [paramFilter, setParamFilter] = useState<ParametersFilter[]>([]);
    const [dataModal, setDataModal] = useState<DataModalDinamicProps>({
        callBack: () => null,
        icon: '',
        msg: ''
    })
    const watchedValues = watch();
    const refConfirmActionSubmit = useRef<HTMLButtonElement>(null);
    const refCloseStatus = useRef<HTMLButtonElement>(null);

    const createOrUpdateNews = async () => {
        refLoad && refLoad.current && refLoad.current.click();
        const newGroups = arrayGroup.filter((group) => !group.disabled)
        const uniqueIds = new Set<number | string>();
        const uniqueNewGroups = newGroups.filter((group) => {
            if (!uniqueIds.has(group.id)) {
                uniqueIds.add(group.id);
                return true;
            }
            return false;
        });

        let body: bodyPostNews = {
            title: getValues('title'),
            is_visible_title: getValues('is_visible_title') ? getValues('is_visible_title') : false,
            is_featured: getValues('is_featured'),
            subtitle: getValues('subtitle'),
            is_visible_subtitle: getValues('is_visible_subtitle') ? getValues('is_visible_subtitle') : false,
            category_id: getValues('category_id'),
            img_banner: imgBanner,
            img_card: imgCard,
            date_to: getValues('date_to'),
            date_from: getValues('date_from'),
            file: file.file,
            name_file: file.name_file,
            description: getValues('description').trim(),
            condition: getValues('condition').trim(),
            status: getValues('status'),
            country_id: selectCountry,
            groups: uniqueNewGroups,
            links: arrayLinks
        }

        if (isEditNews) {
            body.id = idNews
        }

        let method = isEditNews ? 'PUT' : 'POST';
        RTE.run(await crudNews(body, method), httpClientEnv)
            .then(E.fold(e => { return setPostDataNews(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.valid) {
                    refLoadClose && refLoadClose.current && refLoadClose.current.click();
                    refStatus && refStatus.current && refStatus.current.click();
                    setDataModal({
                        msg: getValues('status') === 'published' ? 'Noticia publicada correctamente' : 'Borrador guardado exitosamente',
                        icon: 'fa-check-circle-o',
                        callBack: () => {
                            refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                            setNewNews(false);
                            setShowTable(true);
                            setIsEditNews(false);
                        }
                    })

                    return setPostDataNews(RD.success(a))
                } else {
                    return setPostDataNews(RD.failure({ tag: 'httpRequestError', error: 'Valid createOrUpdateNews' }))
                }
            }))
    };

    const getParams = async () => {
        refLoad && refLoad.current && refLoad.current.click();
        setRemoteDataParams(RD.pending)
        RTE.run(await getParamsNews(), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataParams(RD.failure(e)) }, a => {
                let data = RD.success(a)

                if (data._tag === 'RemoteSuccess' && data.value.response.parameters.length > 0) {
                    setTimeout(() => {
                        refLoadClose && refLoadClose.current && refLoadClose.current.click();
                    }, 500)
                    return setRemoteDataParams(RD.success(a))
                } else {
                    return setRemoteDataParams(RD.failure({ tag: 'httpRequestError', error: 'length getParams' }))
                }
            }))
    }

    const GetNewsByID = async (idBenef: number) => {
        setRemoteDataNewsByID(RD.pending)
        RTE.run(await getNewsByID(idBenef), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataNewsByID(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    return setRemoteDataNewsByID(RD.success(a));
                } else {
                    return setRemoteDataNewsByID(RD.failure({ tag: 'httpRequestError', error: 'length GetNewsByID 0' }));
                }
            }))
    };

    const resetStatus = () => {
        setNewNews(false)
        setShowTable(true)
        reset();
        setRemoteDataNewsByID(RD.initial)
        setIdNews(0);
        scrollToTop();
        if (isEditNews) {
            setIsEditNews(false)
        }
        refCloseStatus && refCloseStatus.current && refCloseStatus.current.click()
    }

    const onSubmit = () => {
        let filterArrayGroup = arrayGroup.filter((gr) => gr.id === 0);
        let filterLinks = arrayLinks.filter((link) => link.url === '' || !link.url.includes("https://"));
        let dateIni = new Date(getValues('date_from'))
        let dateFin = new Date(getValues('date_to'));
        if (dateFin < dateIni) {
            setMsjErrorDate("La fecha debe ser mayor o igual a la fecha de inicio");
            const divScroll = document.getElementById('divDates');
            divScroll && divScroll.scrollIntoView();
        } else if (arrayGroup.length === 0 || filterArrayGroup.length > 0) {
            setErrorSelectGroup('Seleccione uno o más grupos.')
            const divScroll = document.getElementById('divGroups');
            divScroll && divScroll.scrollIntoView();
        } else if (filterLinks.length > 0) {
            setErrorUrl('Ingrese una url válida')
            const divScroll = document.getElementById('divVideos');
            divScroll && divScroll.scrollIntoView();
        } else {
            setMsjErrorDate('');
            setErrorSelectGroup('');
            setErrorUrl('');
            createOrUpdateNews();
        }
    }

    useEffect(() => {
        scrollToTop();
        getParams();
    }, [])

    useEffect(() => {
        if (postDataNews._tag === 'RemoteFailure') {
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            setDataModal({
                msg: 'Ha ocurrido un error al crear noticia, por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click()
                }
            })
            setTimeout(() => {
                refStatus && refStatus.current && refStatus.current.click();
            }, 500)
        }
    }, [postDataNews])

    useEffect(() => {
        if (remoteDataParams._tag === 'RemoteSuccess') {
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            let params = remoteDataParams.value.response.parameters;
            const filteredParams = params
                .filter(param => param.country === selectCountry)
                .map(param => ({
                    ...param,
                    categories: param.categories.flat(),
                    groups: param.groups.flat(),
                }));
            setParamFilter(selectCountry !== '' ? filteredParams : params);
            if (isLoadingData) {
                setIsLoadingData(false);
            } else {
                setValue('category_id', 0);
                trigger('category_id');
                setArrayGroup([]);
            }

        } else if (remoteDataParams._tag === 'RemoteFailure') {
            setTimeout(() => {
                refLoadClose && refLoadClose.current && refLoadClose.current.click();
            }, 500)
            setDataModal({
                msg: 'Ha ocurrido un error al conseguir información, por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    resetStatus();
                }
            })
            setTimeout(() => {
                refStatus && refStatus.current && refStatus.current.click();
            }, 1000);

        }
    }, [remoteDataParams, selectCountry])

    useEffect(() => {
        if (!isDirty || !isValid) {
            setDisabledBtn(true)
        } else {
            setDisabledBtn(false)
        }
    }, [isDirty, isValid])

    useEffect(() => {
        if (isEditNews && idNews > 0) {
            GetNewsByID(idNews)
        }
    }, [isEditNews, idNews])

    useEffect(() => {
        if (remoteDataNewsByID._tag === 'RemoteSuccess') {
            let params = remoteDataNewsByID.value.response.news;
            let groups = remoteDataNewsByID.value.response.groups;
            let videos = remoteDataNewsByID.value.response.news.videos;
            let history = remoteDataNewsByID.value.response.news.history;
            let idNews = remoteDataNewsByID.value.response.news.id;
            getRemotePhotosNewsList([idNews], setRemoteDataPhoto, setImgCard, true, setImgBanner)
            setIsLoadingData(true);
            setSelectCountry(params.country_id);
            setValue('title', params.title);
            setValue('subtitle', params.subtitle);
            setValue('is_visible_subtitle', params.is_visible_subtitle);
            setValue('category_id', params.category_id);
            setValue('date_to', params.date_to ? params.date_to : "");
            setValue('date_from', params.date_from ? params.date_from : "");
            setValue('description', params.description);
            setTextCond(params.condition);
            setValue('condition', params.condition);
            setTextDescr(params.description);
            setArrayLinks(videos);
            if (groups.length === 0) {
                refLoadClose && refLoadClose.current && refLoadClose.current.click();
                refStatus && refStatus.current && refStatus.current.click();
                setDataModal({
                    msg: 'Ha ocurrido un error al conseguir información de grupos, por favor, intente más tarde.',
                    icon: 'fa-exclamation-triangle',
                    callBack: () => {
                        resetStatus();
                    }
                })
            }
            setArrayGroup(groups);
            setIsFile(remoteDataNewsByID.value.response.isFile);
            if (history) {
                const orderHistory = [...history].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()); setHistory(history);
                setHistory(orderHistory);
            }
        }
    }, [remoteDataNewsByID])

    useEffect(() => {
        if (selectCategory !== '') {
            setValue('category_id', Number(selectCategory))
        }
    }, [selectCategory])

    return (
        <>
            <section className="gestion-de-noticias">
                <h1>Gestión de Noticias</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormNews
                        register={register}
                        arrayGroup={arrayGroup}
                        setArrayGroup={setArrayGroup}
                        selectCountry={selectCountry}
                        setSelectCountry={setSelectCountry}
                        errorSelectGroup={errorSelectGroup}
                        isSubtitleVisible={isSubtitleVisible}
                        setIsSubtitleVisible={setIsSubtitleVisible}
                        errors={errors}
                        control={control}
                        imgBanner={imgBanner}
                        setImgBanner={setImgBanner}
                        imgCard={imgCard}
                        setImgCard={setImgCard}
                        setFile={setFile}
                        msjErrorDate={msjErrorDate}
                        setSelectCategory={setSelectCategory}
                        isEditNews={isEditNews}
                        isErrorPhoto={remoteDataPhoto._tag === 'RemoteFailure'}
                        paramFilter={paramFilter.length > 0 ? paramFilter : []}
                        pais={pais}
                        isFile={isFile}
                        idNews={idNews}
                        clearErrors={clearErrors}
                        setError={setError}
                        file={file}
                        setIsFile={setIsFile}
                        setValue={setValue}
                        watch={watch}
                    />
                    <ContentNews
                        errors={errors}
                        errorUrl={errorUrl}
                        textDescr={textDescr}
                        arrayLinks={arrayLinks}
                        selectModule={selectModule}
                        history={history}
                        paramFilter={paramFilter.length > 0 ? paramFilter : []}
                        isEditBenef={isEditNews}
                        control={control}
                        register={register}
                        setValue={setValue}
                        setTextDescr={setTextDescr}
                        setArrayLinks={setArrayLinks}
                        setSelectModule={setSelectModule}
                        trigger={trigger}
                        setTextCond={setTextCond}
                        textCond={textCond}
                    />
                    <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap mt-4">
                        <div className="d-grid d-md-flex gap-2">
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#preview">Previsualizar</button>
                            <button type="button" onClick={() => {
                                refConfirmActionSubmit && refConfirmActionSubmit.current && refConfirmActionSubmit.current.click();
                            }} disabled={Object.keys(errors).length > 0 || disabledBtn || remoteDataParams._tag === 'RemoteFailure'} className="btn btn-degradado ms-md-2" data-bs-toggle="modal" data-bs-target="#seguro-publicar">Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                        </div>
                        <button type="button" className="btn btn-link order-md-first" data-bs-toggle="modal" data-bs-target="#back" onClick={() => {
                            refConfirmAction && refConfirmAction.current && refConfirmAction.current.click();
                        }}>Volver</button>
                    </div>
                </form>
            </section>

            <ModalInfoDinamic refOpen={refStatus} icon={dataModal.icon} text={dataModal.msg} subtext={dataModal.submsg} callBack={dataModal.callBack} refClose={refCloseStatus} />

            <ModalLoad text="Cargando información..." refOpen={refLoad} refClose={refLoadClose} data_target={'modalLoadNewNews'} />

            <ModalConfirmAction refOpen={refConfirmActionSubmit} data_target={'modalConfirmAction2'} text={watchedValues.status === 'published' ? `¿Estás seguro que deseas publicar el noticia?` : '¿Estás seguro que deseas guardar el borrador?'}
                callBack={() => { onSubmit() }} />

            <ModalConfirmAction refOpen={refConfirmAction} text={`¿Estás seguro que deseas volver al listado? Se perderán los datos ingresados.`}
                callBack={() => { resetStatus() }} />

            <ModalPreview arrayLinks={arrayLinks} imgCard={imgCard} watchedValues={watchedValues} isFile={isFile}/>
        </>
    )

}
export default NewNews;