import Auth from "@aws-amplify/auth";
import { CreateOnboard, EditOnboard, OnboardingProps, OnboardingUserProps, ResponseOnboardById } from "./interfaces";
import { urlsAws } from "../../../resources/foo/api-endpoints";
import queryClient from "../../../queryClient";

type FetchDataProps = {
    url: string;
    method?: string;
    body?: any;
}

const fetchData = async ({ url = "", method = "GET", body = undefined }: FetchDataProps) => {
    if (!url) throw new Error("URL is required");

    const auth = await Auth.currentSession();
    const requestOptions = {
        method,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            AccessToken: auth.getAccessToken().getJwtToken(),
            Authorization: auth.getIdToken().getJwtToken()
        },
        body: body ? JSON.stringify(body) : undefined,
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.status === 204 ? null : response.json();
};

export const getOnboardingByID = (id: number): Promise<ResponseOnboardById> => 
    queryClient.fetchQuery(['onboarding', id], () => fetchData({ url: `${urlsAws.onboarding}/${id}` }));

export const getOnboardingByUser = (app: string = ""): Promise<OnboardingUserProps[]> => 
    queryClient.fetchQuery(['onboardingByUser', app], () => 
        fetchData({ url: `${urlsAws.onboarding}/user${app ? `?app=${app}` : ""}` }));

export const getAllOnboarding = (): Promise<OnboardingProps[]> => 
    queryClient.fetchQuery('allOnboarding', () => fetchData({ url: urlsAws.onboarding }));

export const createOnboarding = async (body: CreateOnboard) => {
    await queryClient.fetchQuery('createOnboarding', () => 
        fetchData({ url: urlsAws.onboarding, method: "POST", body }));
    await queryClient.invalidateQueries('allOnboarding');
};

export const updateOnboarding = async (id: number, body: EditOnboard) => {
    await queryClient.fetchQuery(['updateOnboarding', id], () => 
        fetchData({ url: `${urlsAws.onboarding}/${id}`, method: "PUT", body }));
    await queryClient.invalidateQueries(['onboarding', id]);
    await queryClient.invalidateQueries('allOnboarding');
};

export const deleteOnboarding = async (id: number) => {
    await queryClient.fetchQuery(['deleteOnboarding', id], () => 
        fetchData({ url: `${urlsAws.onboarding}/${id}`, method: "DELETE" }));
    await queryClient.invalidateQueries('allOnboarding');
};

export const onboardingViewed = (id: number, app: string = "") => 
    queryClient.fetchQuery(['onboardingViewed', id, app], () => 
        fetchData({ url: `${urlsAws.onboarding}/${id}/view${app ? `?app=${app}` : ""}`, method: "PUT" }));

export const getParameters = () => 
    queryClient.fetchQuery('onboardingParameters', () => 
        fetchData({ url: `${urlsAws.onboarding}/parameters` }));
