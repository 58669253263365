import { FC, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../../context';
import { WidgetBanner, WidgetCarouselIcon, WidgetSlider, WidgetTable, WidgetTask } from '../../commonComponents/Widget';
import { getTask, getBannerBenefits, getBannerNews } from '../services/homeServices';
import { getDirectAccessIcons, getMyTeam, getNewsBanner, getNextBirthdays } from '../services/homeServicesCL';
import newsletterInicio from "../../../resources/images/banner-suscripcion-inicio.png";
import donationsHome from "../../../resources/images/donaciones-banner.png";
import Box from '../../commonComponents/Box';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { FEATURE_FLAGS } from '../../../utils/enum/featureFlags.enum';

const HomeCL : FC = () => {
    const { setShowNewsletter, setShowDonations, showDonationButton, appState } = useContext<any>(Context);
    const navigate = useNavigate();
    const { data, isLoading } = useFeatureFlag(FEATURE_FLAGS.NEWS_CL);

    useEffect(() => {
        if (localStorage.getItem("redirect")) {
            if (appState.some((item: { url: "" }) => localStorage.getItem("redirect")?.includes(item.url))) {
                navigate(String(localStorage.getItem("redirect")));
            }
            localStorage.removeItem("redirect");
        }
    }, []);

    return( 
        <>
            <WidgetCarouselIcon
                id="accesos-directos"
                width="3/3"
                box={false}
                data={() => getDirectAccessIcons()}
            />

            {showDonationButton && <Box width="3/3" box={false}>
                <div className="pointer w-100" onClick={(e) => {
                    e.preventDefault();
                    setShowDonations(true);
                    navigate('/noticias?module=donations');
                }}>
                    <img src={donationsHome} className="img-fluid p-0 w-100 rounded-4" alt="logo" />
                </div>
            </Box>}

            {/* Se activa/desactiva widget de noticias CHL/regional según requerimiento */}
            {isLoading ? (
                <></>
            ) : data?.isEnabled ? (
                <WidgetBanner
                    id="noticias-destacadas"
                    width="2/3"
                    box={false}
                    data={() => getBannerNews()}
                />
            ) : (
                <WidgetBanner
                    id="noticias-destacadas"
                    width="2/3"
                    box={false}
                    data={() => getNewsBanner({ isMobile: true })}
                />
            )}


            <WidgetTask 
                id="tareas-pendientes" 
                data={() => getTask()} 
                width="1/3" 
                box={true}
            />

            <WidgetSlider 
                id="beneficios-destacados" 
                data={() => getBannerBenefits()}
                width="3/3" 
                box={false}
            />

            <Box width="3/3" box={false}>
                <div className="pointer w-100" onClick={(e) => {
                    e.preventDefault();
                    setShowNewsletter(true);
                    navigate('/noticias?module=newsletter');
                }}>
                    <img src={newsletterInicio} className="img-fluid p-0 w-100 rounded-4" alt="logo" />
                </div>
            </Box >

            <WidgetTable
                id="mi-equipo"
                title="Mi Equipo"
                data={() => getMyTeam()}
                width="2/3"
                box={true}
            />

            <WidgetTable
                id="proximos-cumpleanos"
                title="Próximos Cumpleaños"
                data={() => getNextBirthdays()}
                width="1/3"
                box={true}
            />
        </>
    );
}

export default HomeCL;