import React, { FC, useState, useEffect, Children } from "react";
import { ModalProps } from "../../utilities/interfaces";
import { onboardingViewed } from "../../utilities/apiRequest";
import { scrollToTop } from "../../../../services/utiles";
import { toggleModal } from "../../../commonComponents/Modal";
import Navbar from "../../../elements/navbar";

interface HighlightConfig {
  element: HTMLElement;
  isSubItem?: boolean;
  parentFolder?: HTMLElement;
}

const ModalOnboardingContent: FC<ModalProps> = ({ onboarding, preview }) => {
    const [currentModal, setCurrentModal] = useState<number>(0);

    const nextModal = () => {
        if (currentModal < onboarding.length - 1) {
            setCurrentModal(currentModal + 1);
        }
    };

    const resetMenuStyles = () => {
        const menuList = document.getElementById("menu-items-ul");
        if (!menuList) return;

        menuList.querySelectorAll('li').forEach(item => {
            item.className = item.classList.contains('parent') ? 'nav-item-regional parent' : 'nav-item-regional';
            item.style.pointerEvents = 'auto';
            item.classList.remove('modal-content', 'bg-orange-destacado');
            item.style.zIndex = '';
        });

        const navFolder = document.getElementsByClassName("nav-folder-cont");
        Array.from(navFolder).forEach(folder => {
            folder.className = "collapse nav-folder-cont";
        });
    };

    const highlightMenuItem = (menuItemId: string) => {
        if (!menuItemId || menuItemId === "-1") return;

        const mainItem = document.getElementById(`nav-id-${menuItemId}`);
        if (mainItem) {
            mainItem.classList.add('modal-content', 'bg-orange-destacado');
            mainItem.style.zIndex = '2055';
            mainItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }

        const subItem = document.getElementById(`nav-sub-id-${menuItemId}`);
        if (subItem) {
            const parentFolder = subItem.closest('.nav-folder-cont');
            if (parentFolder) {
                parentFolder.className = 'nav-folder-cont collapse show';
            }

            subItem.classList.add('modal-content', 'bg-orange-destacado');
            subItem.style.zIndex = '2055';
            subItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    useEffect(() => {
        if (onboarding.length > 0) {
            const currentItem = onboarding[currentModal];
            
            const sidebar = document.getElementById("sidebar");
            if (sidebar) {
                sidebar.style.zIndex = 'initial';
            }

            resetMenuStyles();

            if (currentItem.menuitem_id) {
                highlightMenuItem(currentItem.menuitem_id);
            }

            if (currentItem.id > 0 && !currentItem.viewed && !preview) {
                onboardingViewed(currentItem.id);
            }
        }

        return () => {
            resetMenuStyles();
            const sidebar = document.getElementById("sidebar");
            if (sidebar) {
                sidebar.style.zIndex = '998';
            }
        };
    }, [currentModal, onboarding, preview]);

    const renderHTML = (rawHTML: any) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
  });

    const handleFinish = () => {
        const idUl = document.getElementById("menu-items-ul");
        idUl && idUl.querySelectorAll('li').forEach((item) => {
            item.className = item.classList.contains('parent') ? 'nav-item-regional parent' : 'nav-item-regional';
            item.style.pointerEvents = 'auto';
        });

        const collapses = document.querySelectorAll('.nav-folder-cont');
        collapses.forEach(collapse => {
            collapse.classList.remove('show');
        });

        const navBar = document.getElementById("sidebar");
        if (navBar) {
            navBar.style.zIndex = '';
            navBar.style.pointerEvents = '';
        }
    };

    return (
        <>
            <div className="fade show modal" style={{ display: 'block' }} aria-modal="true" role="dialog">
                <div className={`modal-dialog`} style={{ maxWidth: '100%', margin: '0' }}>
                    <header className="header p-3 border-bottom" style={{ opacity: 0 }}></header>
                    <main id="main" className="wrapper d-flex" >
                        <div style={{ opacity: 0, overflow: 'hidden', height: '20px', visibility: 'hidden' }}>
                            <Navbar />
                        </div>
                        <div id="content">
                            <div className="modal-content">
                                <div className="modal-header border-0 pb-0">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            handleFinish();
                                            toggleModal();
                                            scrollToTop();
                                        }}
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="justify-content-between mx-4 px-0">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                        <span className="me-3">
                                            <a className="pointer" onClick={() => { setCurrentModal(currentModal > 0 ? currentModal - 1 : 0); }}>
                                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                            </a>
                                        </span>
                                        {Children.toArray(onboarding.map((data, i) => {
                                            return (
                                                <span className="mx-2">
                                                    <a onClick={() => { setCurrentModal(i) }} className="pointer">
                                                        <i className={`fa fa-circle ${i > currentModal ? 'color-gray' : 'color-primary-strong'} `} aria-hidden="true"></i>
                                                    </a>
                                                </span>
                                            )
                                        }))}
                                        <span className="ms-3">
                                            <a className="pointer" onClick={() => { setCurrentModal(currentModal < onboarding.length - 1 ? currentModal + 1 : onboarding.length - 1); }}>
                                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                {Children.toArray(onboarding.map((data, i) => 
                                    currentModal === i && (
                                        <div className="modal-body">
                                            <div className="box">
                                                <h2 className="h4 tit-section" style={{ justifySelf: 'start' }}>{data.title}</h2>
                                                <div className="row">
                                                    <div className="col-md-4 d-flex justify-content-start align-items-start">
                                                        <img
                                                            style={{
                                                                width: "100%",
                                                                height: "auto",
                                                                objectFit: "contain",
                                                            }}
                                                            src={data.image}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-md-8">
                                                        {renderHTML(data.text_content)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))}
                                <div className="modal-footer justify-content-between mx-4 px-0">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span className="me-3">
                                            <a className="pointer" onClick={() => { setCurrentModal(currentModal > 0 ? currentModal - 1 : 0); }}>
                                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                            </a>
                                        </span>
                                        {Children.toArray(onboarding.map((data, i) => {
                                            return (
                                                <span className="mx-2">
                                                    <a onClick={() => { setCurrentModal(i) }} className="pointer">
                                                        <i className={`fa fa-circle ${i > currentModal ? 'color-gray' : 'color-primary-strong'} `} aria-hidden="true"></i>
                                                    </a>
                                                </span>
                                            )
                                        }))}
                                        <span className="ms-3">
                                            <a className="pointer" onClick={() => { setCurrentModal(currentModal < onboarding.length - 1 ? currentModal + 1 : onboarding.length - 1); }}>
                                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                            </a>
                                        </span>
                                    </div>
                                    <button 
                                        type="submit" 
                                        className="btn btn-degradado" 
                                        onClick={() => {
                                            if (currentModal === onboarding.length - 1) {
                                                handleFinish();
                                                toggleModal();
                                                scrollToTop();
                                            } else {
                                                nextModal();
                                            }
                                        }}
                                    >
                                        {currentModal === onboarding.length - 1 ? 'Finalizar' : 'Siguiente'} 
                                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>  
                    </main>
                </div>
            </div>            
        </>
    );
}

export default ModalOnboardingContent;
