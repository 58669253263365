import { ChangeEvent, FC, Children, useEffect, useState } from "react";
import { onboardingStore } from "../../../../utilities/store";
import { useFormContext } from "react-hook-form";
import { MAX_IMAGE_SIZE } from "./schema";

const FormOnb: FC = () => {
  const { register, watch, formState: { errors }, setValue, trigger } = useFormContext();
  const parameters = onboardingStore((state) => state.parameters);
  const selectedOnboarding = onboardingStore((state) => state.selectedOnboarding);
  const isFailedValue = parameters.menuitems.length === 0;
  const is_regional = watch("is_regional");
  const region = watch("region");
  const image = watch("image");
  
  const onChangePicture = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_IMAGE_SIZE) {
        setValue("image", "");
        trigger("image");
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target?.result as string;
        setValue("image", base64String);
        trigger("image");
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <>
      <div className="box mb-4">
        <h2 className="h4 tit-section">
          {selectedOnboarding ? "Editar Onboarding" : "Crear Nuevo Onboarding"}
        </h2>
        <div className="border-md-bottom border-2 py-md-3">
          <div className="row align-items-center">
            <div className="ps-4 col-md-12">
            <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="is_regional"
                  {...register("is_regional")}
                />
                <label className="form-check-label" htmlFor="is_regional">
                  ¿Es regional? (Otras regiones podrán ver y modificar este recurso)
                </label>
            </div>
            </div>
          </div>
        </div>
        {!is_regional && (
          <div className="border-md-bottom border-2 py-md-3">
            <div className="row align-items-center">
              <div className="col-md-2">
                <label className="data-list-box__title mb-2 mb-md-0">País *</label>
              </div>
              <div className="col-md-4">
                <div className="input-group">
                  <select
                    className="form-select"
                    id="region"
                    {...register("region", {
                      onChange: () => {
                        setValue('menuitem_id', '');
                        setValue('groups', []);
                        trigger('menuitem_id');
                        trigger('groups');
                      }
                    })}
                  >
                    <option value=""> Seleccione opción </option>
                    {parameters &&
                      Children.toArray(
                        parameters.countries.map((o, i) => (
                          <option key={i} value={o.country_id}>
                            {o.country_name}
                          </option>
                        ))
                      )}
                  </select>
                </div>
                {errors["region"] !== undefined && (
                  <div style={{ display: "block" }} className="invalid-feedback">
                    {errors.region.message as string}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="border-bottom border-2 py-3">
          <div className="row align-items-center">
            <div className="col-md-2">
              <label className="data-list-box__title mb-2 mb-md-0">Título *</label>
            </div>
            <div className="col-md-4">
              <input
                id="titulo"
                className="form-control"
                type="text"
                placeholder="Ingrese título"
                {...register("title")}
              />
            </div>
          </div>
          {errors["title"] !== undefined && (
            <div style={{ display: "block" }} className="invalid-feedback">
              {errors.title.message as string}
            </div>
          )}
        </div>

        <div className="border-md-bottom border-2 py-md-3">
          <div className="row align-items-center">
            <div className="col-md-2">
              <label className="data-list-box__title mb-2 mb-md-0">Comportamiento </label>
          </div>
        <div className="col-md-6 pt-2">
              <div className="form-check p-0">
                <input
                  className="mx-1 pt-2 form-check-input"
                  type="checkbox"
                  id="destacada"
                  {...register("only_first_login" as never, {})}
                />
                <label className="form-check-label">Solo debe mostrarse una vez</label>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom border-2 py-3">
          <div className="row">
            <div className="col-md-2">
              <label className="data-list-box__title mb-2 mb-md-0 mt-md-2">Imagen *</label>
            </div>
            <div className="col-md-4">
              <input
                accept="image/*"
                className="form-control"
                type="file"
                id="selectImage1"
                onChange={onChangePicture}
              />
              <small className="mt-2 d-block">Se recomienda una dimensión de 350 x 400 pixeles. Tamaño máximo: 1MB.</small>
              {image && (
                <div className="align-items-start mt-2">
                  <img
                    src={image}
                    alt="Vista previa"
                    style={{ maxHeight: "200px", borderRadius: "5px" }}
                    className="img-fluid"
                  />
                </div>
              )}
              {errors.image && (
                <div style={{ display: "block" }} className="invalid-feedback">
                  {errors.image.message as string}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border-bottom border-2 py-3" id="divDates">
          <div className="row align-items-center">
            <div className="col-md-2">
              <label className="data-list-box__title mb-2 mb-md-0">Vigencia *</label>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="d-flex align-items-center">
                <span>Desde</span>
                <input
                  className="form-control ms-3"
                  type="date"
                  placeholder="dd/mm/aaaa"
                  {...register("start_date")}
                />
              </div>
              {errors["start_date"] !== undefined && (
                <div style={{ display: "block" }} className="invalid-feedback">
                  {errors.start_date.message as string}
                </div>
              )}
            </div>
            
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="d-flex align-items-center">
                <span>Hasta</span>
                <input
                  className="form-control ms-3"
                  type="date"
                  placeholder="dd/mm/aaaa"
                  {...register("end_date")}
                />
              </div>
              {errors["end_date"] !== undefined && (
                <div style={{ display: "block" }} className="invalid-feedback">
                  {errors.end_date.message as string}
                </div>
              )}
            </div>
          </div>
        </div>
        {
          !is_regional && region !== '' && parameters.menuitems.length > 0 ? (
            <div className="border-bottom border-2 py-3">
            <div className="row align-items-center">
              <div className="col-md-2">
                <label className="data-list-box__title mb-2 mb-md-0">Punto de Menú *</label>
              </div>
              <div className="col-md-4">
                <select
                  className="form-select"
                  id="idMenu"
                  {...register("menuitem_id")}
                >
                  <option value=""> Seleccione opción </option>
                  {!is_regional && <option value="-1">Sin Punto de Menú</option>}
                  {parameters.menuitems &&
                    Children.toArray(
                      parameters.menuitems
                      .map((o, i) => {
                        if (region === 'CL') {
                          if (o.url?.includes('/ficha-ingreso') || o.url?.includes('-dt')) {
                            return null;
                          }
                          if (o.region && o.region !== 'CL') {
                            return null;
                          }
                        } else {
                          if (o.region && o.region !== region) {
                            return null;
                          }
                        }

                        return (
                          <option key={i} value={o.id.toString()}>
                            {o.label}
                          </option>
                        );
                      })
                      .filter(Boolean)
                    )}
                </select>
                {errors["menuitem_id"] !== undefined && (
                  <div style={{ display: "block" }} className="invalid-feedback">
                    {errors.menuitem_id.message as string}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : 
          isFailedValue && region !== '' &&
            <div className="border-bottom border-2 py-3">
              <div className="row align-items-center">
                <div className="col-md-2">
                  <label className="data-list-box__title mb-2 mb-md-0">Punto de Menú *</label>
                </div>
                <div className="col-md-4">
                  <div style={{ display: "block" }} className="invalid-feedback">
                    {"Ha ocurrido un problema al obtener puntos de menú."}
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default FormOnb;
