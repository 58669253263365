/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
import React from "react";
import portalogo from "../../resources/images/mi-portal.svg";
import { useState, useEffect, useContext, useLayoutEffect } from "react";
import styles from "../../resources/css/style.module.css"
import { getLogoByEnvironment } from "../../utils/getLogo"; 

import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context";
import { decrypt, pwd } from "../../services/utiles";
import defaultImg from "../../resources/images/usuario-sin-foto-Encabezado.svg";
import photoCamera from "../../resources/images/camera_avatar.svg";
import MenuService from '../../services/permissions/access-service';
import DatosLaboralesService from "../../services/datos laborales/datos-laborales-service";
import ModalAvatar from './modal-avatar';
import Notifications from "../../notifications-arg/notifications";

import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import Auth from '@aws-amplify/auth';

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

const GlobalHeader = (props) => {
    const { active, setActive, shrink, setShrink } = useContext(Context);
    const [expDate, setExpDate] = useState(null);
    // const diffDays = parseInt(sessionStorage.getItem("expDate"));
    const [diffDays, setDiffDays] = useState(0);
    const [totalNotfPush, setTotalNotfPush] = useState(0);
    const [tituloNotfPush, settituloNotfPush] = useState([]);
    const [notfTitleRead, setnotfTitleRead] = useState("");
    const [notfDescripRead, setnotfDescripRead] = useState("");
    const [menuItems, setMenuItems] = useState([]);
    const [profilePicture, setProfilePicture] = useState();
    const emplid = localStorage.getItem("emplid");
    const almNotPush = localStorage.getItem("notfPush");
    let navigate = useNavigate();
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullname, setFullname] = useState("");
    const [showModalAvatar, setShowModalAvatar] = useState(false);
    const closeModalAvatar = () => setShowModalAvatar(false);

    const [showUploadImg, setShowUploadImg] = useState(false);

    const logo = getLogoByEnvironment()

    // eslint-disable-next-line no-undef
    const isTestEnv = process.env.REACT_APP_ENV != "production";


    const AvatarImg = ({ showUpload }) => {
        console.log("Show upload: ", showUpload);

        return (
            <>
                <img src={profilePicture || defaultImg}
                    className={styles["rounded-circle"]}
                    // style={{ opacity: 0.5, position: "relative", right: 40 }}
                    width="40"
                    height="40"
                    //onClick={() => setShowModalAvatar(true)}
                    onMouseEnter={() => setShowUploadImg(true)}
                    onMouseLeave={() => setShowUploadImg(false)}
                />

                <img src={photoCamera}
                    className={styles["rounded-circle"]}
                    style={{ zIndex: 9999, opacity: 0.5, position: "relative", right: 40, cursor: "pointer", visibility: showUpload ? "visible" : "hidden" }}
                    width="40"
                    height="40"
                    onClick={() => setShowModalAvatar(true)}
                    onMouseLeave={() => setShowUploadImg(false)}
                />
            </>
        );
    };

    const avatarActions = [
        {
            label: 'Actualizar Foto',
            value: 'update'
        }
    ];

    const handleAvatarActions = (action) => {
        switch (action) {
            case 'update':
                setShowModalAvatar(true);
                break;
        }
    }

    const removeItemFromArr = (arr, item) => {
        let i = arr.indexOf(item);
        if (i !== -1) {
            arr.splice(i, 1);
        }
    };

    const cargarListadoPush = () => {
        localStorage.setItem("notfPush", "");
        let desNotfPush = "";
        for (let i = 0; i < tituloNotfPush.length; i++) {
            let desNotfPush = desNotfPush + tituloNotfPush[i].titulo + "-" + tituloNotfPush[i].descrip + "-" + tituloNotfPush[i].lFecha + "-" + tituloNotfPush[i].descripShort + "#";
        }
        localStorage.setItem("notfPush", desNotfPush);
    };

    const getSearcherOptions = async () => {
        try {
            const response = await MenuService.getSearcherOptions();
            const options = response.data;
            const aux = [];
            options.forEach((o) => {
                aux.push(o);
                if (o.haschildrens) {
                    o.subitems.forEach((sub) => {
                        aux.push(sub);
                    });
                }
            });
            const searchOptions = aux.filter(o => !o.publico && o.url);
            setMenuItems(searchOptions);
        } catch (e) {
            console.log(e);
        }
    }

    const getAvatar = async () => {
        try {
            const response = await DatosLaboralesService.getAvatarRegional();
            setProfilePicture(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {

        Auth.currentUserInfo().then(user => {
            setFullname(user.attributes.given_name + " " + user.attributes.family_name);
            setName(user.attributes.given_name);
            setLastName(user.attributes.family_name);
        }).catch(err => console.log("no user"));

    }, [fullname, name, lastName]);

    useEffect(() => {
        if (shrink) {
            document.body.classList.add("shrink");
        } else {
            document.body.classList.remove("shrink");
        }
    }, [shrink]);

    useEffect(() => {
        if (diffDays !== null && diffDays !== "") {
            setExpDate(35 - diffDays);
        }
    }, [diffDays]);

    useEffect(() => {
        try {
            if (totalNotfPush === 0) {
                let arrayNotfPush = almNotPush.split("#");
                setTotalNotfPush(arrayNotfPush.length - 1);
            }
        } catch { }
    }, [emplid, tituloNotfPush.length, notfTitleRead]);

    useEffect(() => {
        getSearcherOptions()
        Auth.currentAuthenticatedUser().then((auth) => {
            if (auth.signInUserSession.idToken.payload["locale"] !== undefined) {
                const pwdDate = pwd(auth.signInUserSession.idToken.payload["locale"]);
                setDiffDays(parseInt(pwdDate));
            }
        });
        getAvatar();
    }, []);

    if (almNotPush) {
        let arrayNotfPush = almNotPush.split("#");
        removeItemFromArr(arrayNotfPush, "");
        if (totalNotfPush === arrayNotfPush.length && tituloNotfPush.length === 0) {
            for (let i = 0; i < arrayNotfPush.length; i++) {
                let splitarrayNotfPush = arrayNotfPush[i].split("-");
                let notifyPush = {
                    titulo: splitarrayNotfPush[0],
                    descrip: splitarrayNotfPush[1],
                    lFecha: splitarrayNotfPush[2],
                    descripShort: splitarrayNotfPush[3],
                };
                tituloNotfPush.push(notifyPush);
            }
        }
    }

    const readNotification = (titleNotfPush, descripNotfPush) => {
        for (let i = 0; i < tituloNotfPush.length; i++) {
            if (tituloNotfPush[i].titulo === titleNotfPush) {
                tituloNotfPush.splice(i, 1);
                setTotalNotfPush(totalNotfPush - 1);
            }
        }
        setnotfTitleRead(titleNotfPush);
        setnotfDescripRead(descripNotfPush);
        cargarListadoPush();
    };

    function ocultarFiltro(e) {
        if (e == null) {
            // Si hac click un elemento, lo leemos
        } else {
            // Si ha hecho click sobre un destino, lo leemos
            if (e.target.id != "inputSearch") {
                if (box_search != null) {
                    box_search.style.display = "none";
                }
                if (document.getElementById("inputSearch") != null) {
                    document.getElementById("inputSearch").value = "";
                }
                if (document.getElementById("inputSearch2") != null) {
                    document.getElementById("inputSearch2").value = "";
                }
            }
        }
    }


    let newImg = sessionStorage.getItem("newImg");

    let inputSearch = document.getElementById("inputSearch");
    let box_search = document.getElementById("box-search");

    if (inputSearch) {
        inputSearch.addEventListener("keyup", buscador_interno);
    }

    function buscador_interno() {
        let filter = inputSearch.value.toUpperCase();
        let li = box_search.getElementsByTagName("li");

        //Recorriendo elementos a filtrar mediante los "li"
        for (let i = 0; i < li.length; i++) {
            let a = li[i].getElementsByTagName("a")[0];
            let textValue = a.textContent || a.innerText;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
                box_search.style.display = "block";
                if (inputSearch.value === "") {
                    box_search.style.display = "none";
                }
            } else {
                li[i].style.display = "none";
            }
        }
    }

    document.onclick = ocultarFiltro;

    const Buscador = () => {
        const redirectToRoute = (item) => {
            if (!item.component)
                window.open(item.url, '_blank');
            else
                navigate(item.url);
            box_search.style.display = "none";
            inputSearch.value = "";
        };

        return (
            <ul className="list-group" style={{ padding: "inherit" }}>
                {menuItems.map((item) => (
                    <li key={item.id} type="button" onClick={() => redirectToRoute(item)} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            {item.label} <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                ))}
            </ul>
        )
    };

    return (
        <>

            <>
                <ModalAvatar openModal={showModalAvatar} closeModal={closeModalAvatar} updateAvatarHeader={setProfilePicture} />
                <header className={`${isTestEnv ? styles.headerTest : styles.header} p-1 border-bottom`} id="header">
                    <div className={styles.container}>
                        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                            {/* <!-- boton para mobile, despliega menú --> */}
                            <a className={`${styles.btn} ${isTestEnv ? 'btn-primary-test' : 'btn-primary'} d-inline-block d-lg-none me-3 navbar-btn`} id="sidebarCollapse" role="button" data-bs-target="#sidebar" onClick={() => setActive(!active)}>
                                <i className={`${styles.fa} fa-bars ${styles["fa-lg"]}`} aria-hidden="true"></i>
                            </a>

                            {/* <!-- boton para desktop, esconde texto --> */}
                            <a className={`btn ${isTestEnv ? 'btn-primary-test' : 'btn-primary'} d-none d-lg-inline-block me-3`} onClick={() => {
                                const openMenus = Array.from(document.getElementsByClassName('show'));

                                openMenus.forEach(box => {
                                    box.classList.remove('show');
                                })


                                setShrink(!shrink);
                            }}>
                                <span className="shrink-btn">
                                    <i className={`${styles.fa} fa-bars ${styles["fa-lg"]}`} aria-hidden="true"></i>
                                </span>
                            </a>

                            {/* <!-- logo cencosud --> */}
                            <a className={`${styles.logo_cencosud} ${styles.me_3}`} href="inicio"><img src={logo} width="100" alt="Ir al inicio" />
                            </a>
                            
                            {isTestEnv && (
                                <p className="color-white my-0 mx-3 text-center">ESTE NO ES UN AMBIENTE PRODUCTIVO</p>
                            )}
                            
                            <div className="menu-h-1 d-flex col-lg-auto ms-auto mb-2 mb-md-0 me-3">

                                <div className="notification collapse mb-3" id="box-search" style={{ zIndex: "4", width: "50%", padding: "inherit", fontSize: "17px" }}>
                                    <div className="notification-group">
                                        <Buscador />
                                    </div>
                                </div>
                                <div className={`${styles["input-group"]} d-none d-lg-flex`}>
                                    <div className="form-outline">
                                        <input type="search" onChange={() => { buscador_interno(); }} id="inputSearch" className={`${styles["form-control"]} ${styles.globalsearcher}`} placeholder="Buscar..." aria-label="Buscar"></input>
                                    </div>
                                    <span className={`${styles.btn} ${styles["btn-primary"]}`}>
                                        <i className="fa fa-search"></i>
                                    </span>
                                </div>

                            </div>
                            <div className="menu-h-2 d-flex text-end align-items-center">
                                {/* <!-- Clave--> */}
                                {expDate === undefined || expDate === null ? null : (
                                    <small className="color-white me-3 me-lg-4 d-none d-xl-inline-block">
                                        <i className="fa fa-exclamation-triangle fa-lg me-1" aria-hidden="true"></i>
                                        {expDate === 0 ? "Su clave caduca hoy" : `Su clave caduca en ${expDate} días`}
                                    </small>
                                )}
                                <div className="notification collapse" id="notification">
                                    <span className="notification-title h5">Notificaciones</span>
                                    <div className="notification-group">
                                        {tituloNotfPush.map((data) => {
                                            return (
                                                <a
                                                    key={data.id}
                                                    className="notification-item text-decoration-none d-block"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#notificacion-push"
                                                    onClick={(e) => {
                                                        readNotification(data.titulo, data.descrip);
                                                    }}
                                                >
                                                    <ul className="list-unstyled m-0">
                                                        <li>
                                                            <strong>{data.titulo}</strong>
                                                        </li>
                                                        <li>{data.descripShort.replace(/<(.|\n)*?>/g, "")}</li>
                                                        <li>{data.lFecha}</li>
                                                    </ul>
                                                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                </a>
                                            );
                                        })}
                                        <a className="d-block text-center text-decoration-none p-2">Ver todo</a>
                                    </div>
                                </div>
                                <div>
                                    <Notifications />
                                </div>
                                {/* <!-- Perfil --> */}
                                {/* <button type="button" className="btn btn-primary" onClick={() => setShowModalAvatar(true)}>Upload</button> */}
                                <div className={`${styles.profile} ${styles["m-2"]} ${styles["me-lg-4"]}`}>
                                    <AvatarImg showUpload={showUploadImg} />
                                    {/* <a className="text-decoration-none">
                                        <UncontrolledDropdown
                                            className={styles["avatar-container"]}
                                        >
                                            <DropdownToggle
                                                className={`${styles["toggle-margin"]} check-one-toggle`}
                                                size="sm"
                                                split
                                                color="transparent"
                                            >
                                                <p>
                                                    <img src={profilePicture || defaultImg} className={styles["rounded-circle"]} width="40" height="40" />
                                                    <div className={`${styles["d-lg-inline-block"]} ${styles["align-middle"]} ${styles["collapse"]}`}>
                                                        <span className={`${styles["profile-name"]} ${styles["text-uppercase"]} ${styles["ms-2"]} ${styles["d-lg-block"]}`}>
                                                            <strong>{name}</strong>
                                                        </span>
                                                        <span className={`${styles["profile-name"]} ${styles["text-uppercase"]} ${styles["ms-2"]} ${styles["d-lg-block"]}`}>
                                                            <strong>{lastName}</strong>
                                                        </span>
                                                    </div>
                                                </p>
                                            </DropdownToggle>
                                            <DropdownMenu
                                                className={styles["avatar-action"]}
                                            >
                                                {avatarActions.map((option, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={index}
                                                            onClick={() => handleAvatarActions(option.value)}
                                                            id={option.value}
                                                            name={option.value}
                                                            size="sm"
                                                        >
                                                            {option.label}
                                                        </DropdownItem>

                                                    )
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </a> */}
                                </div>

                                {/* <!-- Botón salir --> */}
                                <a
                                    className={`${styles.btn} btn-sign-out`}
                                    onClick={() => {
                                        props.signOut();
                                        // logout()
                                    }}
                                >
                                    <i className="fa fa-sign-out fa-lg" aria-hidden="true"></i>
                                </a>
                            </div>


                        </div>
                    </div>
                </header>
                <>
                    <div className="modal fade" id="notificacion-push" tabIndex="-1" aria-labelledby="notificacion-push" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-0 pb-0">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h1 className="h5">{notfTitleRead}</h1>
                                    <div className="rounded-3 border border-primary p-4">
                                        <div className="img-profile me-4 mb-4 text-center">
                                            <p dangerouslySetInnerHTML={{ __html: notfDescripRead }} style={{ wordWrap: "break-word" }}></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pt-0">
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                        Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </>


        </>
    );
};
export default GlobalHeader;
