import Modal from 'react-bootstrap/Modal';
import { ModalDeleteItemProps } from './crudGenerator.types';
import { FaChevronRight } from 'react-icons/fa';

export const ModalDeleteItem = (props: ModalDeleteItemProps) => {
    const { idItem, handleClose, onDelete, titleModalDelete } = props;

    const handleDelete = () => {
        onDelete(idItem);
        handleClose();
    }
    return (
        <Modal show={!!idItem} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {titleModalDelete ? titleModalDelete : "¿Está seguro/a qué desea eliminar?"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-primary px-5"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                >
                    No
                </button>
                <button
                    type="button"
                    className="btn btn-degradado px-5 d-flex gap-1 align-items-center"
                    data-bs-dismiss="modal"
                    onClick={handleDelete}
                >
                    Sí
                    <FaChevronRight />
                </button>
            </Modal.Footer>
        </Modal>
    )
}
