import React, { FC, useContext, useEffect, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  NewOnboardingProps,
  OnboardingDetail,
  CreateOnboard,
  HistoryOnboarding,
  ResponseOnboardById,
  MenuItemsProps,
  OnboardingUserProps,
} from "../../../utilities/interfaces";
import FormOnb from "./components/form";
import SelectGroups from "./components/selectGroup";
import ContentOnboarding from "./components/content";
import { scrollToTop } from "../../../../../services/utiles";
import {
  createOnboarding,
  getOnboardingByID,
  updateOnboarding,
} from "../../../utilities/apiRequest";
import { Context } from "../../../../../context";
import { useForm, FormProvider } from "react-hook-form";
import { onboardingStore } from "../../../utilities/store";
import { toggleModal } from "../../../../commonComponents/Modal";
import { validationSchema } from "./components/schema";
import ModalOnboardingContent from "../../../components/modalOnboarding/modalContent";

const NewOnboarding: FC<NewOnboardingProps> = ({ getFiltredOnboardings, resetOnboarding }) => {
  const selectedOnboarding = onboardingStore((state) => state.selectedOnboarding);
  const [selectedOnboardingDetail, setSelectedOnboardingDetail] = useState<ResponseOnboardById | null>(null);
  const context = useContext(Context);
  const emplid = context?.emplid !== undefined ? context?.emplid : "";

  const methods = useForm<CreateOnboard>({
    mode: "onChange",
    resolver: yupResolver(validationSchema as any),
    defaultValues: {
      title: "",
      image: "",
      text_content: "",
      start_date: "",
      end_date: "",
      status: "",
      created_by: "",
      only_first_login: false,
      menuitem_id: "",
      is_regional: false,
      region: "",
      groups: [],
    }
  });

  const { handleSubmit, watch, formState: { isValid }, getValues, reset, setValue } = methods;
  const title = watch("title");
  const region = watch("region");
  const is_regional = watch("is_regional");
  const image = watch("image");
  const menuitem_id = watch("menuitem_id");

  const GetDetailOnb = async (id: number) => {
    try {
      toggleModal({ variant: 'loading', text: "Cargando información..." });
      const response = await getOnboardingByID(id);
      let onboarding = response.onboarding;
      reset(onboarding);
      setSelectedOnboardingDetail(response);
      toggleModal();
    } catch (error) {
      toggleModal({
        variant: 'status',
        text: "Ha ocurrido un error al conseguir información, por favor, intente más tarde.",
        status: "error",
        onAccept: () => {},
      });
    }
  };

  const onSubmit = async (data: CreateOnboard) => {
    try {
      if (data.menuitem_id === "-1") {
        data.menuitem_id = "";
      }
      toggleModal({ variant: 'loading', text: selectedOnboarding ? "Modificando onboarding..." : "Creando onboarding..." });
      
      if (selectedOnboarding) {
        await updateOnboarding(selectedOnboarding.id, {
         ...data,
         groups: data.groups ? data.groups : []
        });
      } else {
        await createOnboarding({
          ...data,
          created_by: emplid,
          groups: data.groups ? data.groups : []
        });
      }

      toggleModal({
        variant: 'status',
        text: selectedOnboarding ? "Su onboarding ha sido modificado correctamente." : "Su onboarding ha sido creado correctamente.",
        status: "ok",
        onAccept: () => {
          resetOnboarding();
          scrollToTop();
          getFiltredOnboardings();
        },
      });
    } catch (error) {
      toggleModal({
        variant: 'status',
        text: `Ha ocurrido un error al ${selectedOnboarding ? 'modificar' : 'crear'} Onboarding, por favor, intente más tarde.`,
        status: "error",
        onAccept: () => {},
      });
    }
  };

  useEffect(() => {
    if (selectedOnboarding) {
      GetDetailOnb(selectedOnboarding.id);
    }
  }, [selectedOnboarding]);

  const renderHTML = (rawHTML: any) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
  });

  const handlePreview = () => {
    const currentData = getValues();
    const previewData = [{
      id: 0,
      title: currentData.title,
      image: currentData.image,
      text_content: currentData.text_content,
      menuitem_id: currentData.menuitem_id,
      viewed: false
    }];

    toggleModal({
      variant: 'custom',
      content: <ModalOnboardingContent onboarding={previewData as any} preview={true} />,
    });
  };

  return (
    <>
      <section className="gestion-de-onboarding">
        <h1>Gestión de Onboarding</h1>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormOnb />
            {(!is_regional && region) && 
              <SelectGroups
                onboardingDetail={selectedOnboardingDetail?.onboarding as OnboardingDetail}
                region={region}
              />
            }

            <ContentOnboarding
              onboardingDetail={selectedOnboardingDetail?.onboarding as OnboardingDetail}
              history={selectedOnboardingDetail?.history as HistoryOnboarding[]}
            />

            <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap mt-4">
              <div className="d-grid d-md-flex gap-2">
                <button
                  type="button"
                  disabled={!isValid}
                  className="btn btn-primary"
                  onClick={handlePreview}
                >
                  Previsualizar
                </button>
                <button
                  type="submit"
                  disabled={!isValid}
                  className="btn btn-degradado ms-md-2"
                >
                  Guardar
                  <i
                    className="fa fa-chevron-right fa-fw fa-xs"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <button
                type="button"
                data-bs-toggle="modal"
                onClick={() => {
                  toggleModal({
                    variant: 'confirm',
                    text: "¿Está seguro que desea volver? Los cambios no guardados se perderán.",
                    onConfirm: () => {
                      resetOnboarding();
                      getFiltredOnboardings();
                      scrollToTop();
                    },
                  });
                }}
                className="btn btn-link order-md-first"
              >
                Volver
              </button>
            </div>
          </form>
        </FormProvider>
      </section>
    </>
  );
};

export default NewOnboarding;
