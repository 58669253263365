import { Base64 } from "js-base64";
import React, { useState, FunctionComponent, useEffect } from "react";
import { UseFormSetError, UseFormSetValue } from "react-hook-form";
import { DocumentData, DocumentItem, PensionData } from "../../../utilities/interfaces";

interface Health {
    health?: string;
}

interface HandleUpload {
    file: File;
    setUploadedDocument: React.Dispatch<React.SetStateAction<File | null>>;
    setBase64File?: React.Dispatch<React.SetStateAction<string | null>>;
    setValues?: UseFormSetValue<any>;
    target?: string;
    setError?: UseFormSetError<any>;
}

interface HandleFiles {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    setUploadedFile?: React.Dispatch<React.SetStateAction<string | null>>;
    setValues?: UseFormSetValue<any>;
    target?: string;
    setDocumentItems?: React.Dispatch<React.SetStateAction<Array<DocumentItem | undefined>>>;
    uploadedDocument?: File | null;
}

const resizeFile = (file: File, type: string) =>
    new Promise(async (resolve) => {
        type !== "pdf" ?
        resolve(Base64.fromUint8Array(new Uint8Array(await file.arrayBuffer())))
        :
        resolve(Base64.fromUint8Array(new Uint8Array(await file.arrayBuffer())));
    });

const handleUploadDocument = ({ file, setUploadedDocument, setBase64File, setValues, target, setError }: HandleUpload) => {
    if (file.size / 1024 ** 2 <= 1) {
        setUploadedDocument(file);
    } else {
        setUploadedDocument(null);
        if (setError && target) {
            setError(target, {
                type: 'custom',
                message: 'El archivo no puede superar 1MB'
            });
        }
    }
};

const handleFiles = async ({ e, setSuccess, setUploadedFile , setValues, target, setDocumentItems, uploadedDocument }: HandleFiles) => {
    e.preventDefault();
    if (uploadedDocument !== undefined && uploadedDocument !== null) {
        let type = uploadedDocument.name.split(".")[uploadedDocument.name.split(".").length - 1];
        const fileToUpload = await resizeFile(uploadedDocument, type);
        if (setUploadedFile !== undefined) {
            setUploadedFile(`data:image/${type};base64,${fileToUpload}` as string);
        }
        if (setValues !== undefined && target !== undefined) {
            const correctedFile =  typeof fileToUpload === "string" && fileToUpload.includes("base64,") ?
                fileToUpload.split("base64,")[1]
            :
                typeof fileToUpload === "string" ? fileToUpload : "";
            setValues(target, `${correctedFile}|${type}`);
            setDocumentItems !== undefined &&
                setDocumentItems((prevState) => 
                    prevState.map((doc) => 
                        doc !== undefined ?
                            doc.target === target ?
                                {
                                    ...doc,
                                    value: `${correctedFile}|${type}`,
                                }
                            :
                                { ... doc }
                    : undefined)
                );
        }
        setSuccess(true);
    } else {
        if (setUploadedFile !== undefined) {
            setUploadedFile("");
        }
        if (setValues !== undefined && target !== undefined) {
            setValues(target, "");
        }
        setSuccess(false);
    }
};

interface Props {
    setPhoto?: React.Dispatch<React.SetStateAction<string | null>>;
    fileName: string;
    modalId: string;
    permitedTypes: string[];
    target: string;
    setValues: UseFormSetValue<any>;
    setDocumentItems: React.Dispatch<React.SetStateAction<Array<DocumentItem | undefined>>>;
    targetValue: string;
    setError: UseFormSetError<any>;
}

const Upload: FunctionComponent<Props> = ({ 
    setPhoto, 
    fileName, 
    modalId, 
    permitedTypes, 
    target, 
    setValues, 
    setDocumentItems, 
    targetValue,
    setError 
}) => {
    const [success, setSuccess] = useState(false);
    const [uploadedDocument, setUploadedDocument] = useState<File | null>(null);
    const [permited, setPermited] = useState(false);
    const [error, setLocalError] = useState<string>("");
    const [fileError, setFileError] = useState<string>("");

    useEffect(() => {
        if (targetValue !== undefined) {
            if (targetValue === "") {
                setSuccess(false);
            } 
        }
    }, [targetValue]);

    useEffect(() => {
        if (uploadedDocument !== null) {
            if (permitedTypes.includes(uploadedDocument.name.split(".")[uploadedDocument.name.split(".").length - 1])) {
                setPermited(true);
            } else {
                setPermited(false);
            }
        }
    }, [uploadedDocument]);

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        setFileError("");
        
        // Validar tamaño
        if (file.size > 1048576) {
            const errorMessage = `*El archivo ${fileName} supera el tamaño máximo permitido de 1MB`;
            setFileError(errorMessage);
            return;
        }

        // Validar tipo
        const fileType = file.name.split(".").pop()?.toLowerCase();
        if (!permitedTypes.includes(fileType || '')) {
            setFileError(`El archivo debe ser formato ${permitedTypes.join(', ').toUpperCase()}`);
            return;
        }

        setUploadedDocument(file);
    };

    return (
        <div className="modal fade" id={modalId} tabIndex={-1} aria-labelledby={modalId} aria-hidden="true">
            {!success ? (
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body px-4">
                            <h2 className="h5 mb-4" children={"Adjuntar " + fileName} />
                            <label
                                className="form-label"
                                children={
                                    <>
                                        <strong>{"Archivo"}</strong>
                                    </>
                                }
                            />
                            <input id="uploadedDocument" className="form-control" type="file" aria-label="Seleccionar fotografía" 
                            onChange={handleFileUpload} 
                            />
                            {fileError && (
                                <div className="invalid-feedback d-block mt-2 mb-3">
                                    {fileError}
                                </div>
                            )}
                            {uploadedDocument !== null ? (
                                uploadedDocument.size / 1024 ** 2 > 1 ? (
                                    <div className="invalid-feedback d-block" children={"El archivo no puede superar 1MB"} />
                                ) : !permited ? (
                                    <div className="invalid-feedback d-block" children={"El archivo debe ser formato JPEG, JPG, PNG"} />
                                ) : null
                            ) : null}
                            {error && (
                                <div className="invalid-feedback d-block mb-3">
                                    {error}
                                </div>
                            )}
                        </div>
                        <div className="modal-footer border-0 pe-4 pb-4">
                            <button type="button" className="btn btn-primary col-12 col-md-auto" data-bs-dismiss="modal">
                                {"Cancelar "}
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-degradado col-12 col-md-auto" 
                                onClick={(e) => handleFiles({ 
                                    e, 
                                    setSuccess, 
                                    setUploadedFile: setPhoto, 
                                    setValues, 
                                    target, 
                                    setDocumentItems, 
                                    uploadedDocument 
                                })} 
                                disabled={!uploadedDocument || fileError !== "" || uploadedDocument.size > 1048576 || !permited}
                            >
                                {"Confirmar "}
                                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>
                            <h2 className="h5">{`${fileName} agregado exitosamente`}</h2>
                        </div>
                    </div>
                </div>
            )}
        </div>
  )
};

export default Upload;