import { FieldErrors, UseFormRegister, UseFormWatch, UseFormSetValue } from "react-hook-form";
import { FORM_CONSTANTS } from './constants';

export const QUESTION_TYPES = {
  OPEN: 'OPEN',
  SELECT: 'SELC',
  LIKERT: 'LIKR', 
  NPS: 'NPS',
  MULTIPLE: 'MULT'
} as const;

// Definir primero los tipos básicos
export type QuestionType = typeof QUESTION_TYPES[keyof typeof QUESTION_TYPES];

// Definir los tipos de ID
export type QuestionId = `${typeof FORM_CONSTANTS.QUESTION_PREFIX}${string}`;
export type RadioId = `${QuestionId}${typeof FORM_CONSTANTS.RADIO_SUFFIX}${number}`;
export type OthersId = `${QuestionId}${number}${typeof FORM_CONSTANTS.OTHERS_SUFFIX}`;

// Luego el FormFields
export interface FormFields {
  [key: QuestionId | RadioId | OthersId]: string | boolean;
}

// Y finalmente SurveyFormValues
export interface SurveyFormValues extends FormFields {
  [key: string]: string | string[] | boolean;
}

export interface BaseQuestion {
    id: string;
    titulo: string;
    tipo: QuestionType;
}

export interface OpenQuestion extends BaseQuestion {
    tipo: "OPEN";
    req?: boolean;
    otros?: string;
}

export interface SelectQuestion extends BaseQuestion {
    tipo: "SELC";
    opcion: string[];
    otros: string;
}

export interface LikertQuestion extends BaseQuestion {
    tipo: "LIKR";
    opcion: string[];
    likert: string[];
}

export interface NpsQuestion extends BaseQuestion {
    tipo: "NPS";
    opcion: string[];
    likert: string[];
}

export interface MultipleQuestion extends BaseQuestion {
    tipo: "MULT";
    opcion: string[];
    otros: string;
    icono: string;
}

export type Question = OpenQuestion | SelectQuestion | LikertQuestion | NpsQuestion | MultipleQuestion;

export interface ISurvey {
    id: string;
    title: string;
    descr: string;
    rut?: string;
    preguntas: Question[];
}

export interface IHandleChange {
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    tipo: QuestionType;
    otros?: string;
    opcion?: string[];
}

export interface ISurveyForm {
    survey: ISurvey;
    hiddenSubmitBtn?: boolean;
    handleSubmitCallback: (request: SurveyResponse) => void;
    SubmitButton?: React.FC;
}

export interface SurveyResponse {
    id: string;
    titulo: string;
    descrip: string;
    notificar: boolean;
    respuestas: Array<{
        id: string;
        titulo: string;
        tipo: QuestionType;
        items: Array<{
            nombre: string;
            valor: string;
        }>;
        likert?: string[];
    }>;
}

export interface IQuestionFactoryProps {
    pregunta: Question;
    survey: ISurvey;
    handleChange: (params: IHandleChange) => void;
}

