export const FORM_CONSTANTS = {
  QUESTION_PREFIX: 'pregunta',
  RADIO_SUFFIX: 'radio',
  OTHERS_SUFFIX: 'otros',
  OTHERS_VALUE: 'Y',
  OTHERS_NO: 'N',
  OTHERS_LABEL: 'Otros',
  QUESTION_SEPARATOR: '?',
  DEFAULT_VALUE: '',
  TRUE_VALUE: '1',
  FALSE_VALUE: '0'
} as const;

export const CSS_CLASSES = {
  BOX: 'box',
  FORM_CONTROL: 'form-control',
  INVALID_FEEDBACK: 'invalid-feedback',
  FORM_CHECK: 'form-check',
  FORM_CHECK_INPUT: 'form-check-input',
  FORM_CHECK_LABEL: 'form-check-label',
  TITLE_SECTION: 'h3 tit-section',
  MARGIN_BOTTOM: 'mb-4'
} as const;

export const ERROR_MESSAGES = {
  REQUIRED: '*Esta pregunta es obligatoria.',
  REQUIRED_ANSWER: '*Esta respuesta es requerida'
} as const;

export const FORM_IDS = {
  SURVEY_FORM: 'encuesta'
} as const; 