import { create } from "zustand";
import { validateDocumentTrust } from "./services";
import { DocumentTrustState } from "./interfaces";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { toggleModal } from "../commonComponents/Modal";
import ReCAPTCHA from "react-google-recaptcha";

const useDocumentTrustStore = create<DocumentTrustState>((set) => ({
  token: "",
  documentData: null,
  errorMessage: "",
  captchaValidated: "",
  isButtonDisabled: false,
  setIsButtonDisabled: (value: boolean) => set({ isButtonDisabled: value }),
  setToken: (token: string) => set({ token }),
  setDocumentData: (data: DocumentTrustState['documentData']) => set({ documentData: data }),
  setErrorMessage: (message: string) => set({ errorMessage: message }),
  setCaptchaValidated: (validated: string) => set({ 
    captchaValidated: validated,
    errorMessage: validated ? "" : "Debe presionar el captcha antes de validar."
  }),
  verifyRecaptcha: async (
    value: string,
    token: string,
  ) => {
    if (!value || !token) {
      set({ errorMessage: "Captcha no válido o token vacío" });
      return;
    }

    try {
      toggleModal({ variant: 'loading', text: "Validando documento..." });
      const data = await validateDocumentTrust({
        token,
        captcha: value || "",
      });
      set({ documentData: data, errorMessage: "" });
    } catch (error: any) {
      set({
        errorMessage: "Ha ocurrido un error al validar el documento. Por favor, intente nuevamente.",
        documentData: null,
      });
    } finally {
      toggleModal();
    }
  },
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => {
    set(() => ({
      token: e.target.value,
      errorMessage: "",
    }));
  },
  handleClear: (recaptchaRef:any) => {
    set(() => ({
      token: "",
      documentData: null,
      errorMessage: "",
      captchaValidated: "",
      isButtonDisabled: false
    }))
    recaptchaRef.current?.reset();
  }
}));

export const useDocumentTrust = () => {
  const token = useDocumentTrustStore((state) => state.token);
  const isButtonDisabled = useDocumentTrustStore((state) => state.isButtonDisabled);
  const documentData = useDocumentTrustStore((state) => state.documentData);
  const errorMessage = useDocumentTrustStore((state) => state.errorMessage);
  const captchaValidated = useDocumentTrustStore((state) => state.captchaValidated);
  const setToken = useDocumentTrustStore((state) => state.setToken);
  const setIsButtonDisabled = useDocumentTrustStore((state) => state.setIsButtonDisabled);
  const setCaptchaValidated = useDocumentTrustStore((state) => state.setCaptchaValidated);
  const setErrorMessage = useDocumentTrustStore((state) => state.setErrorMessage);
  const verifyRecaptcha = useDocumentTrustStore((state) => state.verifyRecaptcha);
  const handleInputChange = useDocumentTrustStore((state) => state.handleInputChange);
  const handleClear = useDocumentTrustStore((state) => state.handleClear);

  const { search } = useLocation();
  const parameters = new URLSearchParams(search).get("token") || "";
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || "";
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (parameters) {
      setToken(parameters);
    }
  }, [parameters]);

  const handleValidateButtonPress = () => {
    if (!captchaValidated) {
      setErrorMessage("Debe presionar el captcha antes de validar.");
      return;
    }
    verifyRecaptcha(captchaValidated, token);
  };

  const handleModifiedInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(captchaValidated){
      recaptchaRef.current?.reset();
    }
    setCaptchaValidated("");
    handleInputChange(e);
    setIsButtonDisabled(false);
  };

  return {
    token,
    documentData,
    errorMessage,
    captchaValidated,
    setToken,
    setCaptchaValidated,
    verifyRecaptcha,
    handleInputChange: handleModifiedInput,
    handleValidateButtonPress,
    handleClear,
    recaptchaKey,
    isButtonDisabled,
    recaptchaRef,
  };
};

