import { Children, FC, useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import { ContentOnboardingProps } from "../../../../utilities/interfaces";
import { CalculateTimeByDate } from "../../../../../../services/utiles";
import { Controller, useFormContext } from "react-hook-form";
import { onboardingStore } from "../../../../utilities/store";

const ContentOnboarding: FC<ContentOnboardingProps> = ({ 
  onboardingDetail, 
  history, 
}) => {
  const selectedOnboarding = onboardingStore(state => state.selectedOnboarding);
  const { control, setValue, formState: { errors }, register, watch } = useFormContext();

  const modulos = useMemo(() => ({
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  }), []);

  const formatos = useMemo(() => [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ], []);

  useEffect(() => {
    if (onboardingDetail) {
      setValue('status', onboardingDetail.status);
      setValue('text_content', onboardingDetail.text_content || '');
    }
  }, [onboardingDetail, setValue]);

  const currentContent = watch('text_content');

  const memoizedReactQuill = useMemo(() => (
    <Controller
      name="text_content"
      control={control}
      rules={{ 
        validate: value => {
          const strippedValue = value.replace(/<[^>]+>/g, '').trim();
          return strippedValue.length > 0 || 'El contenido no puede estar vacío';
        }
      }}
      render={({ field }) => (
        <ReactQuill
          theme="snow"
          value={field.value || ''}
          onChange={field.onChange}
          modules={modulos}
          formats={formatos}
        />
      )}
    />
  ), [control, modulos, formatos]);

  return (
    <>
      <div className="box mb-4">
        <h2 className="h4 tit-section">Contenido</h2>
        <div className="row">
          <div className={`col-12 ${selectedOnboarding ? 'col-md-10' : 'col-md-12'}`}>
            <span style={{ width: "100%" }} className="data-list-box__text">
              {memoizedReactQuill}
              {errors.text_content && <div className="invalid-feedback d-block">{errors.text_content.message as string}</div>}
            </span>
            <div className="row justify-content-end mt-3">
              <div className="col-auto">
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    value="published"
                    id="flexRadioDefault1" 
                    {...register('status')}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Publicado
                  </label>
                </div>
              </div>
              <div className="col-auto">
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    value="draft" 
                    id="flexRadioDefault2"
                    {...register('status')}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Borrador
                  </label>
                </div>
              </div>
            </div>
            {errors.status && <div className="invalid-feedback d-block">{errors.status.message as string}</div>}
          </div>
          {selectedOnboarding && (
            <div className="col-12 col-md-2">
              <h3 className="h5 py-2 border-top border-bottom border-light fw-bold">Historial</h3>
              <div className="box-scroll">
                <ul className="history list-unstyled mt-4">
                  {history && history.length > 0 ? Children.toArray(history.map((hist)=>{
                    if (hist.id > 0) {
                      return(
                        <li><span>Actualizado</span> Por {hist.modified_by}, hace {CalculateTimeByDate(hist.updated_at)}</li>
                      )
                    } else {
                      return(
                        <li><span>Sin cambios.</span></li>
                      )
                    }
                  })): <li><span>Sin cambios.</span></li>}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ContentOnboarding;
