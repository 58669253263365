import React, { Children, FC } from "react";
import { useFormContext } from "react-hook-form";
import useWindowSize from "../../../elements/window-size-hook";
import { SurveyFormValues } from "../../interfaces";

interface LikertProps {
    id: string;
    titulo: string;
    tipo: "LIKR";
    opcion: string[];
    likert: string[];
    handleChange: (params: any) => void;
}

const Likert: FC<LikertProps> = ({ 
    id, 
    titulo, 
    tipo, 
    opcion, 
    likert,
    handleChange 
}) => {
    const { width } = useWindowSize();
    const { register, formState: { errors } } = useFormContext<SurveyFormValues>();
    
    return (
        <div className="box mb-4">
            <h2 className="h3 tit-section">{titulo}</h2>
            <table className="table table-2 table-even align-middle table-borderless d-none d-lg-table">
                <thead>
                    <tr>
                        <th>Pregunta</th>
                        {Children.toArray(likert.map((likertOption) => (
                            <th className="text-center col-likr">{likertOption}</th>
                        )))}
                    </tr>
                </thead>
                <tbody>
                    {Children.toArray(opcion.map((op, index) => {
                        const radioId = `${id}radio${index}`;
                        return (
                            <tr>
                                <td>
                                    {op}
                                    {errors[radioId]?.message && (
                                        <div className="invalid-feedback d-block">
                                            {String(errors[radioId]?.message || "")}
                                        </div>
                                    )}
                                </td>
                                {likert.map((_, likertIndex) => (
                                    <td key={likertIndex} className="text-center">
                                        {width > 991 && (
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                value={likertIndex + 1}
                                                {...register(radioId, {
                                                    required: "*Esta pregunta es obligatoria.",
                                                    onChange: (e) => handleChange({ e, tipo, opcion })
                                                })}
                                            />
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    }))}
                </tbody>
            </table>
            <div className="d-lg-none">
                {Children.toArray(opcion.map((op, index) => {
                    const radioId = `${id}radio${index}`;
                    return (
                        <div className="form-check-group form-check-group-even">
                            <div className="form-check__title">{op}</div>
                            {errors[radioId]?.message && (
                                <div className="invalid-feedback d-block">
                                    {String(errors[radioId]?.message || "")}
                                </div>
                            )}
                            {likert.map((likertOption, likertIndex) => (
                                <div key={likertIndex} className="form-check">
                                    {width < 992 && (
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            value={likertIndex + 1}
                                            {...register(radioId, {
                                                required: "*Esta pregunta es obligatoria.",
                                                onChange: (e) => handleChange({ e, tipo, opcion })
                                            })}
                                        />
                                    )}
                                    <label className="form-check-label">
                                        {likertOption}
                                    </label>
                                </div>
                            ))}
                        </div>
                    );
                }))}
            </div>
        </div>
    );
};

export default Likert;