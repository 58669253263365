import { useState, useCallback, useEffect } from "react";
import { ISurvey } from "../interfaces";
import { CognitoUserSession } from "amazon-cognito-identity-js";

type IRequestOptions = {
  method: 'GET' | 'POST';
  headers: { [key: string]: string };
  body?: string;
};

type IValidationFunc = (jsonData: any) => Promise<{ error: string } | undefined>;

type IFetchOptions = {
  url: string;
  session: CognitoUserSession | undefined;
};

type IApiForm = {
  initialData: any;
  initialError: null | string;
  submitData: any;
  loading: boolean;
  submitError: null | string;
  handleSubmit: (body: any) => void;
};

type IMakeApiRequest = {
  url: string;
  method: 'GET' | 'POST';
  body?: any;
  handleErrors?: IValidationFunc;
  callback?: (jsonData: any) => any;
};

const useApiForm = ({ url, session }: IFetchOptions): IApiForm => {
  const [initialData, setInitialData] = useState<ISurvey | null>(null);
  const [initialError, setInitialError] = useState<null | string>(null);
  const [submitData, setSubmitData] = useState<any>(null);
  const [submitError, setSubmitError] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const makeApiRequest = async ({ url, method, body }: IMakeApiRequest) => {
    try {
      let authHeaders: { [key: string]: string } = {};
      if (session) {
        authHeaders = {
          Authorization: session.getIdToken().getJwtToken(),
          AccessToken: session.getAccessToken().getJwtToken(),
        };
      }
      const requestOptions: IRequestOptions = {
        method,
        headers: {
          "Content-Type": "application/json",
          ...authHeaders,
        },
        body: body ? JSON.stringify(body) : undefined,
      };

      const response = await fetch(url, requestOptions);
      const jsonData = await response.json();
      if (!response.ok) {
        throw new Error(jsonData.message || "Hemos encontrado un error. Por favor intente más tarde.");
      }

      return { data: jsonData };
    } catch (error: any) {
      let errorMessage = error.message || "Hemos encontrado un error. Por favor intente más tarde.";
      return { error: errorMessage };
    }
  };

  const fetchData = useCallback(async () => {
    setInitialError(null);
    setLoading(true);
    const { data, error } = await makeApiRequest({
      url,
      method: "GET",
    });
    setLoading(false);
    if (error) {
      setInitialError(error);
    } else {
      setInitialData(data);
    }
  }, [url]);

  const handleSubmit = useCallback(async (body: any) => {
    setSubmitError(null);
    setLoading(true);
    const { data, error } = await makeApiRequest({
      url,
      method: "POST",
      body,
    });
    setLoading(false);
    if (error) {
      setSubmitError(error);
    } else {
      setSubmitData(data);
    }
  }, [url]);
  
  useEffect(() => {
    if (url) fetchData();
  }, [fetchData, url]);

  return {
    initialData,
    initialError,
    submitData,
    submitError,
    loading,
    handleSubmit,
  };
};

export default useApiForm;
