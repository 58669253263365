import { useState } from "react";
import { ColumnsConfig } from "../../../components/table";
import { LayoutCard } from "../../teamDashboard/_components/employeeProfileSummary/LayoutCard";
import { StepInsurance } from "./StepsInsurance";


export const CompulsoryLifeInsurance = () => {
  const [currentView, setCurrentView] = useState<"main" | "step">("main");
  const columnsConfigDays: ColumnsConfig[] = [
    {
      accessor: "name",
      header: "Nombre Contacto",
      className: "text-center",
    },
    {
      accessor: "vinculo",
      header: "Vínculo",
      className: "text-center",
    },
    {
      accessor: "date",
      header: "Fecha Nacimiento",
      className: "text-center",
    },
    {
      accessor: "percentage",
      header: "Porcentaje",
      className: "text-center",
    },
    {
      accessor: "dni",
      header: "DNI/LC/LE/CI",
      className: "text-center",
    },
  ];

  const data = [
    {
      name: "Mateo Fernández",
      vinculo: "Hijo/a",
      date: "2024-01-10",
      percentage: "50%",
      dni: "40500900",
    },
    {
      name: "Sofia Gómez",
      vinculo: "Hijo/a",
      date: "2024-01-10",
      percentage: "20%",
      dni: "40500900",
    },
    {
      name: "Lucas Martinez",
      vinculo: "Hijo/a",
      date: "2024-01-10",
      percentage: "20%",
      dni: "40500900",
    },
    {
      name: "Camila Rodriguez",
      vinculo: "Hijo/a",
      date: "2024-01-10",
      percentage: "10%",
      dni: "40500900",
    },
  ];

  return (
    <>
      {currentView === "main" && (
        <>
          {/* <div className="d-flex justify-content-end m-5">
              <button
                onClick={() => setCurrentView("step")}
                className="btn btn-degradado"
                type="button"
              >
                Dar Alta
              </button>
            </div>
            <h3 style={{ textAlign: "center" }}>
              No hay beneficiarios asociados
            </h3> */}
          <div className="box w-100 mt-5">
            <h3>Beneficiarios</h3>
            <div className="d-flex justify-content-end m-3">
              <button
                onClick={() => setCurrentView("step")}
                className="btn btn-degradado"
                type="button"
              >
                Agregar Nuevo
              </button>
            </div>
            <LayoutCard columnsConfig={columnsConfigDays} data={data} />
          </div>
        </>
      )}

      {currentView === "step" && (
        <StepInsurance onBack={() => setCurrentView("main")} />
      )}
    </>
  );
};
