import { FORM_CONSTANTS } from './constants';
import { QuestionId, RadioId, OthersId } from './interfaces';

export const generateQuestionId = (id: string): QuestionId => 
  `${FORM_CONSTANTS.QUESTION_PREFIX}${id}`;

export const generateRadioId = (questionId: QuestionId, index: number): RadioId =>
  `${questionId}${FORM_CONSTANTS.RADIO_SUFFIX}${index}`;

export const generateOthersId = (questionId: QuestionId, index: number): OthersId =>
  `${questionId}${index}${FORM_CONSTANTS.OTHERS_SUFFIX}`; 