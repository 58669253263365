import { useState, useEffect } from "react";
import { CrudGenerator } from "../../../components/crudGenerator";
import type {
  Column,
  FormField,
} from "../../../components/crudGenerator/crudGenerator.types";

interface User {
  id: number;
  name: string;
  vinculo: string;
  direction: string;
  percentage: number;
  dni: number;
}

interface StepOneProps {
  onPercentageChange: (isValid: boolean) => void;
}

export const StepOne: React.FC<StepOneProps> = ({ onPercentageChange }) => {
  const [data, setData] = useState<User[]>([
    {
      id: 1,
      name: "Mateo Fernández",
      vinculo: "Hijo/a",
      direction: "Av. Maipu 555",
      percentage: 50,
      dni: 40500900,
    },
  ]);

  useEffect(() => {
    const totalPercentage = data.reduce(
      (sum, item) => sum + Number(item.percentage),
      0
    );
    onPercentageChange(totalPercentage === 100);
  }, [data, onPercentageChange]);

  const onCreateITem = (newItem: Omit<User, "id">) => {
    const newId = data.length ? Math.max(...data.map((item) => item.id)) + 1 : 1;
    const newUser = { ...newItem, id: newId };
    setData((prev) => {
      const updatedData = [...prev, newUser];
      return updatedData;
    });
  };

  const onUpdate = (updateItem: User) => {
    setData((prev) => {
      const updatedData = prev.map((item) =>
        item.id === updateItem.id ? updateItem : item
      );
      return updatedData;
    });
  };

  const onDelete = (id: string | number) => {
    setData((prev) => {
      const updatedData = prev.filter((item) => item.id !== id);
      return updatedData;
    });
  };

  const formFields: FormField[] = [
    {
      id: "name",
      label: "Nombre",
      required: true,
      type: "text",
      errorMsg: "Nombre es requerido",
    },
    {
      id: "vinculo",
      label: "Vínculo",
      required: true,
      type: "select",
      errorMsg: "Vínculo es requerido",
      options: [
        { value: "Hijo/a", label: "Hijo/a" },
        { value: "Padres", label: "Padres" },
        { value: "Hermano/a", label: "Hermano/a" },
        { value: "Cónyuge", label: "Cónyuge" },
        { value: "Otros", label: "Otros" },
      ],
    },
    {
      id: "direction",
      label: "Dirección",
      required: true,
      type: "text",
      errorMsg: "Dirección es requerida",
    },
    {
      id: "percentage",
      label: "Porcentaje",
      required: true,
      type: "number",
      errorMsg: "Porcentaje es requerido",
      min: 0,
      max: 100,
    },
    {
      id: "dni",
      label: "DNI/LC/LE/CI",
      required: true,
      type: "number",
      errorMsg: "DNI es requerido",
    },
  ];

  const columns: Column<User>[] = [
    {
      accessor: "name",
      header: "Nombre Contacto",
      className: "text-center",
    },
    {
      accessor: "vinculo",
      header: "Vínculo",
      className: "text-center",
    },
    {
      accessor: "direction",
      header: "Dirección",
      className: "text-center",
    },
    {
      accessor: "percentage",
      header: "Porcentaje",
      className: "text-center",
    },
    {
      accessor: "dni",
      header: "DNI/LC/LE/CI",
      className: "text-center",
    },
  ];

  return (
    <div className="box w-100">
      <h3>Beneficiarios</h3>
      <p>
        - La suma de los porcentajes distribuidos entre los beneficiarios debe
        sumar 100%.
      </p>
      <p>
        - Es importante que verifiques tus datos personales vigentes en la
        sección de Mi Información del Portal y en el caso de no ser correctos
        por favor modifícalos antes de continuar al siguiente paso.
      </p>
      <p>
        - Recuerda verificar que la dirección de correo personal contenida en la
        sección de Mi información del portal sea la correcta, ya que te
        enviaremos un correo a esa dirección cuando el formulario este listo
        para firmar.
      </p>
      <CrudGenerator<User>
        columns={columns}
        dataTable={data}
        formFields={formFields}
        onCreate={onCreateITem}
        onEdit={onUpdate}
        onDelete={onDelete}
        titleModalCreateEdit="Usuario"
      />
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "16px" }}
      >
        <button
          onClick={() => console.log("boton")}
          className="btn btn-primary"
          type="button"
        >
          Descargar PDF
        </button>
      </div>
    </div>
  );
};
