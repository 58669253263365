import { createContext, useContext, useEffect, useState } from "react";
import {
  CalendarMonth,
  LunchDay,
  LunchScheduleContextType,
  LunchScheduleProviderProps,
  ModalData,
} from "../hooks/LunchScheduleApi.types";
import { useLunchScheduleFetch } from "../hooks";
import { isEqual } from "lodash";

export const LunchScheduleDataContext = createContext<
  LunchScheduleContextType | undefined
>(undefined);

export const LunchScheduleProvider = (props: LunchScheduleProviderProps) => {
  const { children } = props;

  const {
    dataLunchSchedule,
    dataPlacesToReserve,
    handleDeleteReserve,
    getMonths,
    isLoading,
    fetchLunchSchedule,
    handleUpdateReserve,
    handleReserve,
    status,
  } = useLunchScheduleFetch();

  const [monthDays, setMonthDays] = useState<CalendarMonth[]>([]);
  const [modalData, setModalData] = useState<ModalData[]>([]);
  const [selectedDays, setSelectedDays] = useState<
  { date: string; reservationPlace: string | null; id: number }[]
  >([]);
  const [showModal, setShowModal] = useState(false);
  const months = getMonths();
  const [selectedMonth, setSelectedMonth] = useState<string>(
    months.length > 0 ? months[0].month : ""
  );

  useEffect(() => {
    monthDays.length === 0 && setMonthDays(dataLunchSchedule.calendarData);
  }, [dataLunchSchedule.calendarData]);

  useEffect(() => {
    if (!isEqual(monthDays, dataLunchSchedule.calendarData) && !!status) {
      setMonthDays(dataLunchSchedule.calendarData);
    }
  }, [dataLunchSchedule.calendarData, monthDays, status]);

  const handleDayClick = (lunchDay: LunchDay) => {
    if (lunchDay.reservationPlace) {
      setModalData([
        {
          id: lunchDay.id,
          date: lunchDay.date,
          reservationPlace: lunchDay.reservationPlace,
          disabled: lunchDay.disabled ?? true,
        },
      ]);
      setShowModal(true);
    } else {
      setSelectedDays((prev) => {
        const foundDay = prev.find((d) => d.date === lunchDay.date);
        return foundDay
          ? prev.filter((d) => d.date !== lunchDay.date)
          : [
              ...prev,
              {
                date: lunchDay.date,
                reservationPlace: lunchDay.reservationPlace,
                id: lunchDay.id
              },
            ];
      });
    }
  };

  const handleLoadData = () => {
    const loadedDays = monthDays
      .flatMap((month) =>
        month.monthDays.flatMap((week) =>
          week.filter((day) => day && day.reservationPlace)
        )
      )
      .map((day) => ({
        id: day.id,
        date: day.date,
        reservationPlace: day.reservationPlace,
        disabled: day.disabled ?? true,
      }));

    const filteredSelectedDays = selectedDays.filter(
      (selected) => !loadedDays.some((loaded) => loaded.date === selected.date)
    );

    const combinedData = [
      ...filteredSelectedDays.map((selected) => ({
        ...selected,
        disabled: false,
        id: selected.id,
      })),
    ];

    setModalData(combinedData);
    setShowModal(combinedData.length > 0);
  };

  const handleSelectChange = (date: string, value: string) => {
    setModalData((prev) =>
      prev.map((item) =>
        item.date === date
          ? {
            ...item,
            reservationPlace: value,
            id: item.id
          }
          : item
      )
    );
  };

  useEffect(() => {
    if (modalData.length === 0) {
      setShowModal(false);
    }
  }, [modalData]);

  const handleDelete = (id:number) => {
    handleDeleteReserve(id);
    setModalData((prev) => prev.filter((item) => item.id !== id));
    setSelectedDays((prev) => prev.filter((item) => item.id !== id));
    fetchLunchSchedule();
  };

  const handleUpdate = (updatedData: {
    date?: string;
    reservationPlace?: string;
    id?: number;}) => {
    handleUpdateReserve(updatedData);
    setModalData((prev) =>
      prev.map((item) =>
        item.id === updatedData.id ? { ...item, ...updatedData } : item
      )
    );
    setSelectedDays((prev) =>
      prev.map((item) => (item.id === updatedData.id ? { ...item, ...updatedData } : item))
    );
    fetchLunchSchedule();
  };

  const handleSave = () => {
    const updatedDays = modalData.map((item) => ({
      date: item.date,
      reservationPlace:
        item.reservationPlace ?? dataPlacesToReserve[0]?.placeName,
    }));
    handleReserve(updatedDays);
    setSelectedDays([]);
    fetchLunchSchedule();
    setShowModal(false);
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedMonth = event.target.value;
    setSelectedMonth(newSelectedMonth);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (months.length > 0 && selectedMonth === "") {
      setSelectedMonth(months[0].month);
    }
  }, [months]);

  const contextValue = {
    monthDays,
    selectedDays,
    handleDayClick,
    handleLoadData,
    modalData,
    showModal,
    handleSave,
    handleDelete,
    handleSelectChange,
    dataPlacesToReserve,
    getMonths,
    selectedMonth,
    handleMonthChange,
    months,
    isLoading,
    handleCloseModal,
    handleUpdate,
  };

  return (
    <LunchScheduleDataContext.Provider value={contextValue}>
      {children}
    </LunchScheduleDataContext.Provider>
  );
};

export const useLunchScheduleProvider = () => {
  const lunchScheduleDataContext = useContext(LunchScheduleDataContext);

  if (!lunchScheduleDataContext) return {} as LunchScheduleContextType;

  return lunchScheduleDataContext;
};
