import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
import { Context } from "../../context";

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};
const Navbar = () => {
    const { active, setActive, setShrink, appState, menuItems, step, listStep } = useContext(Context);
    const [stateSideBar, setStateSideBar] = useState("");
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const location = useLocation();

    useEffect(() => {
        if (windowDimensions.width > 991) {
            setActive(false);
        } else {
            setShrink(false);
        }
    }, [windowDimensions.width, setActive, setShrink]);
    useEffect(() => {
        if (active) {
            setStateSideBar(" active");
        } else {
            setStateSideBar("");
        }
    }, [active]);
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const itemSelected = (item, index) => {

        const idUl = document.getElementById("menu-items-ul");
        idUl.querySelectorAll('li').forEach((item, i) => {
            if (i === index) {
                item.className = "nav-item-regional active";
            } else {
                item.className = "nav-item-regional";
            }
        })

        if (windowDimensions.width < 990) {
            if (item !== 69 && item !== 420) {
                document.getElementById("sidebarCollapse").click();
            }
        }
    };
    const Styles = {
        gray: { color: "gray" },
        blue: { color: "#0169b5" },
    };

    return appState &&
        <>
            {location.pathname === "/ficha-ingreso" && (step !== 0 && step < 10) ?
                <nav id="sidebar" className="mainmenu">
                    <div className="pasos-box">
                        <div className="pasos-box__title">{"Registro Empleado"}</div>
                        <ul className="pasos-box__items list-unstyled">
                            {
                                listStep.map((value, index) =>
                                    (value < step) ?
                                        <li key={index} className="active active-line">{"Paso " + value}</li>
                                        :
                                        ((value === step) ? <li key={index} className="active current">{"Paso " + value}</li>
                                            :
                                            <li>{"Paso " + value}</li>
                                        )
                                )}
                        </ul>
                    </div>
                </nav>
                : location.pathname === "/ficha-ingreso" && (step === 0 || step > 9) ? null :
                    <nav id="sidebar" className={`mainmenu mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar ${stateSideBar}`}>
                        <ul className="list-unstyled" id="menu-items-ul">
                            {location.pathname !== "/admin/panel-administracion" && location.pathname !== "/admin/panel-administracion/roles" ?
                                <>
                                    {menuItems && menuItems.map((item, index) => {
                                        if (!item.publico && !item.hidden) {
                                            if (!item.haschildrens) {
                                                return (
                                                    <li key={index} onClick={() => itemSelected('', index)} className="nav-item-regional" id={`nav-id-${item.id}`}>
                                                        {item.component !== "" ? (
                                                            <Link className="nav-link-regional" to={item.url} data-bs-toggle="tooltip" title={item.label} >
                                                                <i className={item.class} style={Styles.blue} aria-hidden="true"> </i>
                                                                <span className="nav-link__hide">{item.label}</span>
                                                            </Link>
                                                        ) :
                                                            (<a className="nav-link-regional" href={item.url} data-bs-toggle="tooltip" title={item.label} target="_blank" rel="noopener noreferrer">
                                                                <i className={item.class} style={Styles.blue} aria-hidden="true"> </i>
                                                                <span className="nav-link__hide"  > {item.label}</span>
                                                            </a>
                                                            )}
                                                    </li>
                                                )
                                            } else if (item.haschildrens && item.subitems.length > 0) {
                                                return <li key={index} onClick={() => itemSelected(420, index)} className="nav-item-regional parent">
                                                    <a className="nav-link-regional" data-bs-toggle="collapse" href={"/#" + item.title.replace(/ /g, "")} role="button" aria-expanded="false" title={item.label}>
                                                        <i className={item.class} aria-hidden="true"></i>
                                                        <span className="nav-link__hide">{item.label}</span>
                                                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    </a>
                                                    <div className="collapse nav-folder-cont" id={item.title.replace(/ /g, "")}>
                                                        <ul className={`list-unstyled nav-child`}>
                                                            {item.subitems.map((subitem, index) => {
                                                                return <li key={index} onClick={windowDimensions.width < 990 ? () => document.getElementById("sidebarCollapse").click() : null} className="nav-item-regional" id={`nav-sub-id-${subitem.id}`}>
                                                                    {subitem.component !== "" ? <Link className="nav-link-regional" to={subitem.url}>{subitem.label}</Link> : <a className="nav-link-regional" href={subitem.url} target="_blank" rel="noopener noreferrer">{subitem.label}</a>} </li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </li>
                                            }
                                        }
                                    })}
                                </>
                                :
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/admin/panel-administracion" data-bs-toggle="tooltip" title="Administrador Autoservicio">
                                            <i className="fa fa-window-maximize fa-fw" aria-hidden="true"></i>
                                            <span className="nav-link__hide">Administrador Autoservicio</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/admin/panel-administracion/roles" data-bs-toggle="tooltip" title="Noticias">
                                            <i className="fa fa-window-restore fa-fw" aria-hidden="true"></i>
                                            <span className="nav-link__hide">Rol Administrador</span>
                                        </Link>
                                    </li>

                                </>
                            }
                        </ul>
                    </nav>
            }
        </>
};

Navbar.propTypes = {
    appState: PropTypes.array,
    firstLoginData: PropTypes.object,
    dataTiempo: PropTypes.string,
    beneficiosCantidadVistas: PropTypes.number,
    showAdminBtn: PropTypes.bool,
    itemSelected: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    staticContext: PropTypes.object,
    showMenu: PropTypes.bool,
    setShowMenu: PropTypes.func,
    showMenuMobile: PropTypes.bool,
    setShowMenuMobile: PropTypes.func,
    successNoticias: PropTypes.bool,
    idNoticias: PropTypes.number,
    successBeneficios: PropTypes.bool,
    idBeneficios: PropTypes.number,
    volverInicio: PropTypes.bool,
};


export default Navbar;
