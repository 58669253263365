import React, { useState, useEffect, FunctionComponent } from "react";
import UnorderedList from "../../commons/forms";
import { FormList, Props, PensionData, DocumentData, DocumentItem, GetSheet } from "../../utilities/interfaces";
import { stepFourPension } from "../../utilities/formValues";
import { useForm, SubmitHandler } from "react-hook-form";
import OfflineModal from "../../commons/plain/modals/offline";
import DocumentList from "./documentList";
import fichaContratacionService from "../../../../services/ficha-contratacion-service";
import { trasnformFile } from "../../utilities/functions";
import { scrollToTop } from "../../../../services/utiles";

interface Props1 extends Props {
    prevStep(): void;
    pensionData: PensionData;
    setPensionData: React.Dispatch<React.SetStateAction<PensionData>>;
    documentData: DocumentData;
    setDocumentData: React.Dispatch<React.SetStateAction<DocumentData>>;
    postValues: any;
    setPostValues: React.Dispatch<React.SetStateAction<any>>;
    contractDate: string;
    retired: string;
}

interface Health {
    health?: string;
}

const FourthStep: FunctionComponent<Props1> = ({ options, nextStep, prevStep, pensionData, setPensionData, documentData, setDocumentData, postValues, setPostValues, contractDate, retired }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        setError,
    } = useForm<PensionData | DocumentData | Health>({
        defaultValues: {
            ...pensionData,
            ...documentData,
            health: "",
            cn_cot_salud_mnt: pensionData.cn_cot_salud_mnt !== undefined ? pensionData.cn_cot_salud_mnt.replace(".", ",") : "",
        }
    });

    const [documentItems, setDocumentItems] = useState<Array<DocumentItem | undefined>>(Object.keys(documentData).map((doc) => {
        if (doc !== undefined && documentData[doc as keyof DocumentData] !== undefined) {
            if (doc === "cedula_front") {
                return {
                    name: "Cédula frontal" as string,
                    modalId: "cedula-frontal" as string,
                    value: documentData[doc] as string,
                    target: doc as keyof DocumentData,
                }
            }
            if (doc === "cedula_back") {
                return {
                    name: "Cédula trasera" as string,
                    modalId: "cedula-trasera" as string,
                    value: documentData[doc] as string,
                    target: doc as keyof DocumentData,
                }
            }
            if (doc === "afp_file") {
                return {
                    name: "Certificado AFP" as string,
                    modalId: "certificado-afp" as string,
                    value: documentData[doc] as string,
                    target: doc as keyof DocumentData,
                }
            }
            if (doc === "isapre_file") {
                return {
                    name: pensionData.cn_isapre_cd !== "900" ? "Certificado Isapre" : "Certificado Fonasa" as string,
                    modalId: "certificado-isapre" as string,
                    value: documentData[doc] as string,
                    target: doc as keyof DocumentData,
                }
            }
            if (doc === "aut_menor") {
                return {
                    name: "Autorización trabajo menor de edad" as string,
                    modalId: "autorizacion-menor" as string,
                    value: documentData[doc] as string,
                    target: doc as keyof DocumentData,
                }
            }
            if (doc === "segacc_file") {
                return {
                    name: "Seguro accidentes" as string,
                    modalId: "seguro-accidentes" as string,
                    value: documentData[doc] as string,
                    target: doc as keyof DocumentData,
                }
            }
        }
    }));

    const healthInsurance = watch("cn_isapre_cd");
    const pensionFund = watch("cn_afp_cd");
    const [pension, setPension] = useState(stepFourPension.map((pen) => {
            return { ...pen, readOnly: 
                (pen.id === "cn_fec_afp" && (pensionData.cn_afp_cd === "899" || retired === "X" || retired === "x"))
            ||
                (pen.id === "cn_afp_cd" && (retired === "X" || retired === "x"))
            ||
                (pen.id === "health")
            }
        }
    ));
    const [postStatus, setPostStatus] = useState<any>(false);
    const [disableButton, setDisableButton] = useState(false);
    const [isTotalSizeTooLarge, setIsTotalSizeTooLarge] = useState(false);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        const base64FileSize = (base64String : string) => {
            let padding, inBytes, base64StringLength;
            if (base64String.endsWith("==")) padding = 2;
            else if (base64String.endsWith("=")) padding = 1;
            else padding = 0;
    
            base64StringLength = base64String.length;
            inBytes = (base64StringLength / 4) * 3 - padding;
            return inBytes;
        };

        const validateTotalSize = () => {
            let totalSize = 0;
            documentItems.forEach((docItem: DocumentItem | undefined) => {
                if (docItem && docItem.value) {
                    totalSize += base64FileSize(docItem.value);
                }
            });
    
            const totalSizeInMB = totalSize / (1024 * 1024);
            if (totalSizeInMB > 7.5) {
                setIsTotalSizeTooLarge(true);
            } else {
                setIsTotalSizeTooLarge(false);
            }
        };
    
        validateTotalSize();
    }, [documentItems]);


    useEffect(() => {
        setPension((prevAccount) => {
            return prevAccount.map((pen) => {
                return { ...pen, readOnly: 
                    (pen.id === "cn_fec_afp" && (pensionFund === "899" || retired === "X" || retired === "x"))
                ||
                    (pen.id === "cn_afp_cd" && (retired === "X" || retired === "x"))
                ||
                    (pen.id === "health")
                }
            })
        });
        if (pensionFund === "899") {
            setValue("cn_fec_afp", contractDate);
            setDocumentItems((prevDocumentData) => {
                return prevDocumentData.filter((doc) => {
                    return doc?.target !== "afp_file" ;
                });
            });
        } else {
            setValue("cn_fec_afp", "");
            let afp_file = Object.keys(documentData).filter((doc) => {
                return doc === "afp_file";
            }).map((doc) => {
                if (doc !== undefined) {
                    if (doc === "afp_file") {
                        return {
                            name: "Certificado AFP" as string,
                            modalId: "certificado-afp" as string,
                            value: documentData[doc] as string,
                            target: doc as keyof DocumentData,
                        }
                    }
                }
            });
            if (afp_file[0] !== undefined) {
                setDocumentItems((prevDocumentItems) => Array.from(new Map([...prevDocumentItems, ...afp_file].map((doc) => doc !== undefined ? [doc["target"], doc] : [doc, doc])).values()));
            }
        }
    }, [pensionFund]);

    useEffect(() => {
        if (healthInsurance === "900") {
            let ogPension = stepFourPension.map((pen) => {
                if (pen.id === "cn_fec_afp") {
                    return { ...pen, readOnly: pensionData.cn_afp_cd === "899" || retired === "X" || retired === "x" };
                } else if (pen.id === "cn_afp_cd") {
                    return { ...pen, readOnly: retired === "X" || retired === "x" };
                } else {
                    return { ...pen };
                }
            });
            setValue("health", "Fonasa");
            setPension(ogPension.slice(0, ogPension.length - 2));
            setValue("cn_cot_salud_cd", "Q");
            setValue("cn_cot_salud_mnt", "7");
        } else {
            let ogPension = stepFourPension.map((pen) => {
                if (pen.id === "cn_fec_afp") {
                    return { ...pen, readOnly: pensionData.cn_afp_cd === "899"|| retired === "X" || retired === "x" };
                } else if (pen.id === "cn_afp_cd") {
                    return { ...pen, readOnly: retired === "X" || retired === "x" };
                } else {
                    return { ...pen };
                }
            });
            setValue("health", "Isapre");
            setPension(ogPension);
        }
    }, [healthInsurance]);

    const formSubmitHandler: SubmitHandler<PensionData | DocumentData | Health> = (data: PensionData | DocumentData | Health) => {
        if (navigator.onLine) {
            let newData = { ...data };
            setDisableButton(true);
            const front = "cedula_front" in newData ? trasnformFile(newData.cedula_front) : "";
            const back = "cedula_back" in newData ? trasnformFile(newData.cedula_back) : "";
            const afp = "afp_file" in newData && pensionFund !== "899" && newData.afp_file !== undefined ? trasnformFile(newData.afp_file) : undefined;
            const isp = "isapre_file" in newData && newData.isapre_file !== undefined ? trasnformFile(newData.isapre_file) : undefined;
            const min = "aut_menor" in newData && newData.aut_menor !== undefined ? trasnformFile(newData.aut_menor) : undefined;
            const sec = "segacc_file" in newData && newData.segacc_file !== undefined ? trasnformFile(newData.segacc_file) : undefined;
            if ("health" in newData) {
                delete newData["health"];
            }
            if ("cn_afp_cd" in newData && "cn_fec_afp" in newData && "cn_isapre_cd" in newData && "cn_cot_salud_cd" in newData && !("cn_cot_salud_mnt" in newData)) {
                const pensionCode = newData.cn_afp_cd === "899" && retired !== "X" && retired !== "x" ? "35" : newData.cn_afp_cd;
                setPensionData({ cn_afp_cd: pensionCode, cn_fec_afp: newData.cn_fec_afp, cn_isapre_cd: newData.cn_isapre_cd, cn_cot_salud_cd: newData.cn_cot_salud_cd, cn_cot_salud_mnt: "" });
                document.getElementById("sendingInfo")?.click();
                fichaContratacionService.postMethods.setFicha(postValues, { ...newData, cn_afp_cd: pensionCode, afp_file: afp !== undefined ? afp : "" }, setPostValues, false, setPostStatus, setDisableButton);
            }
            if ("cn_afp_cd" in newData && "cn_fec_afp" in newData && "cn_isapre_cd" in newData && "cn_cot_salud_cd" in newData && "cn_cot_salud_mnt" in newData) {
                if (newData.cn_cot_salud_mnt === "0") {
                    setDisableButton(false);
                    setError("cn_cot_salud_mnt", { type: "pattern", message: "El valor debe ser superior a 0" });
                } else {
                    const pensionCode = newData.cn_afp_cd === "899" && retired !== "X" && retired !== "x" ? "35" : newData.cn_afp_cd;
                    setPensionData({ cn_afp_cd: pensionCode, cn_fec_afp: newData.cn_fec_afp, cn_isapre_cd: newData.cn_isapre_cd, cn_cot_salud_cd: newData.cn_cot_salud_cd, cn_cot_salud_mnt: newData.cn_cot_salud_mnt });
                    document.getElementById("sendingInfo")?.click();
                    fichaContratacionService.postMethods.setFicha(postValues, { ...newData, cn_afp_cd: pensionCode, afp_file: afp !== undefined ? afp : "", cn_cot_salud_mnt: newData.cn_cot_salud_mnt !== undefined ? newData.cn_cot_salud_mnt.replace(",", ".") : "" }, setPostValues, false, setPostStatus, setDisableButton);
                }
            }
            setDocumentData({ cedula_front: front, cedula_back: back, afp_file: afp, isapre_file: isp, aut_menor: min, segacc_file: sec });
        } else {
            document.getElementById("offline")?.click();
        }
    };

    useEffect(() => {
        if (postStatus) {
            document.getElementById("closeSendingInfo")?.click();
        }
        const timer = setTimeout(() => {
            setDisableButton(false);
            if (postStatus.status === "0") {
                nextStep();
            }
        }, 519);
        return () => clearTimeout(timer);
    }, [postStatus]);

    return (
        <>
            <form className="needs-validation" onSubmit={handleSubmit(formSubmitHandler)}>
                <div id={"pension"} className="box mb-4">
                    <h2 className="h4 tit-section" children={"Información Previsional"} />
                    <div className="box-description mb-3 color-primary">{"Si usted no está afiliado a ninguna AFP marque la opción sin AFP."}</div>
                    <UnorderedList formList={pension as Array<FormList>} options={options} register={register} errors={errors} contractDate={contractDate} />
                </div>
                <DocumentList documentItems={documentItems} setDocumentItems={setDocumentItems} register={register} setValues={setValue} errors={errors} watch={watch} isTotalSizeTooLarge={isTotalSizeTooLarge} setError={setError} />
                
                <div className="d-grid d-md-flex justify-content-md-between gap-2">
                    <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); prevStep() }}>
                        <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                        {"Anterior"}
                    </button>
                    <button type="submit" className="btn btn-degradado" disabled={isTotalSizeTooLarge || disableButton}>
                        {"Siguiente"}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </form>
            <OfflineModal />
        </>
    );
};

export default FourthStep;
