import { Children, FC, useEffect, useState } from "react";
import { OnboardingProps, TableProps } from '../../../../utilities/interfaces'
import { formatDate, scrollToTop } from "../../../../../../services/utiles";
import ReactPaginate from "react-paginate";
import { deleteOnboarding } from "../../../../utilities/apiRequest";
import { toggleModal } from '../../../../../commonComponents/Modal'
import { onboardingStore } from "../../../../utilities/store";

const TableIndexOnb: FC<TableProps> = ({ filtredOnboardings, setShowOnboardingDetail, getFiltredOnboardings }) => {
    const setSelectedOnboarding = onboardingStore(state => state.setSelectedOnboarding);
    const parameters = onboardingStore(state => state.parameters);
    let header = [
        {
            label:"ID",
            value:"id"
        },
        {
            label:"Region",
            value:"region"
        },
        {
            label:"Vigencia",
            value:"vigency"
        },
        {
            label:"Título",
            value:"title"
        },
        {
            label:"Autor",
            value:"created_by"
        },
        {
            label:"Estado",
            value:"status"
        },
    ]
    
    let pageItems: OnboardingProps[][] = filtredOnboardings
    .reduce<OnboardingProps[][]>((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/10);
        
        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const handlePageClick = (selectedItem: { selected: number }) => {
        setCurrentPage(selectedItem.selected);
    };

    const deleteOnboardingById = async (id: number) => {
        try {
            toggleModal({ variant: 'loading', text: 'Eliminando onboarding...' });
            await deleteOnboarding(id);
            toggleModal({
                variant: 'status',
                text: 'Onboarding eliminado correctamente.',
                status: 'ok',
                onAccept: () => {
                    getFiltredOnboardings();
                }
            });
        } catch (error) {
            toggleModal({
                variant: 'status',
                text: 'Ha ocurrido un error al eliminar onboarding, por favor, intente más tarde.',
                status: 'error',
                onAccept: () => {}
            });
        }
    }

    useEffect(() => {
        setCurrentPage(0);
    }, [filtredOnboardings]);

    return <>
        <div className="table-responsive">
            <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                <thead>
                    <tr>
                        {Children.toArray(header.map((head,i)=><td>{head.label}</td>))}
                        <td className="text-center">Acciones</td>
                    </tr>
                </thead>
                <tbody>
                    {pageItems[currentPage] && Children.toArray(pageItems[currentPage].map((r) => {
                        const titleText = r.onboardingGroups?.map((group) => group.title).join("\n") || '';
                        return(
                            <>
                                <tr>
                                    <td>{r.id}</td> 
                                    <td>{r.is_regional ? 'Regional' : parameters.countries.find(c => c.country_id === r.region)?.country_name}</td>
                                    <td>{formatDate(r.start_date) +' al '+ formatDate(r.end_date)}</td>
                                    <td>{r.title}</td>
                                    <td>{r.created_by}</td>
                                    <td>{r.status === 'draft' ? 'Borrador' : r.status === 'published' ? 'Publicado': r.status === 'unpublished' ? 'Despublicado' : ''}</td>
                                    <td className="text-center text-nowrap">
                                        <i className="fa fa-users fa-fw fa-lg color-orange" title={titleText} aria-hidden="true" ></i>
                                        <span className="mx-1">{r.onboardingGroups?.length || 0}</span>
                                        <i className="fa btn fa-ellipsis-v" style={{color: '#6c757d'}} data-bs-toggle="dropdown"></i>
                                        <ul className="dropdown-menu border-orange dropdown-menu-hover-normal w-auto">
                                            <a className="dropdown-item" onClick={()=>{
                                                        setSelectedOnboarding(r);
                                                        setShowOnboardingDetail(true);
                                                        scrollToTop();
                                                }}>
                                                <i className="fa fa-pencil fa-fw me-2 fa-lg" aria-hidden="true"></i>Editar</a>
                                            <a className="dropdown-item" onClick={()=>{
                                                toggleModal({
                                                    variant: 'confirm',
                                                    text: '¿Está seguro que desea eliminar este onboarding?',
                                                    onConfirm: () => {
                                                        deleteOnboardingById(r.id);
                                                    }
                                                });
                                            } }>
                                                <i className="fa fa-trash-o fa-fw fa-lg" aria-hidden="true"></i> Eliminar
                                            </a>
                                        </ul>
                                    </td>
                                </tr>
                            </>
                            )
                    }))}
                </tbody>
            </table>

            <div className="accordion accordion-flush accordion-light accordion-table d-block d-md-none">
                {pageItems[currentPage] && Children.toArray(pageItems[currentPage].map((r, indexR) => (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id={`accordion-${indexR}`}>
                            <button
                            className={`accordion-button${
                                indexR !== 0 ? " collapsed" : ""
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#accordion-item-${indexR}`}
                            aria-expanded={`${indexR === 0}`}
                            aria-controls={`accordion-item-${indexR}`}
                            >
                            <strong>Vigencia</strong>
                            {formatDate(r.start_date) +' al '+ formatDate(r.end_date)}
                            </button>
                        </h2>
                        <div
                            id={`accordion-item-${indexR}`}
                            className={`accordion-collapse collapse${
                            indexR === 0 ? " show" : ""
                            }`}
                            aria-labelledby={`accordion-${indexR}`}>
                            <div className="accordion-body p-0">
                                <div className="data-list-box data-list-box-even">
                                    <ul className="list-column-1 list-unstyled">
                                        <li>
                                            <span className="data-list-box__title">
                                                {'ID'}
                                            </span>
                                            <span className="data-list-box__text">
                                                {r.id}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">
                                                Region
                                            </span>
                                            <span className="data-list-box__text">
                                                {r.is_regional ? 'Regional' : parameters.countries.find(c => c.country_id === r.region)?.country_name}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">
                                                {'Título'}
                                            </span>
                                            <span className="data-list-box__text">
                                                {r.title}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">
                                                {'Autor'}
                                            </span>
                                            <span className="data-list-box__text">
                                                {r.created_by}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">
                                                {'Estado'}
                                            </span>
                                            <span className="data-list-box__text">
                                            {r.status === 'draft' ? 'Borrador' : r.status === 'published' ? 'Publicado' : r.status === 'unpublished' ? 'Eliminado' : ''}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">
                                                {'Acciones'}
                                            </span>
                                            <i className="fa btn fa-ellipsis-v" style={{color: '#6c757d'}} data-bs-toggle="dropdown"></i>
                                            <ul className="dropdown-menu border-orange dropdown-menu-hover-normal w-auto">
                                                <a className="dropdown-item" onClick={()=>{
                                                            setSelectedOnboarding(r);
                                                            scrollToTop();
                                                    }}>
                                                    <i className="fa fa-pencil fa-fw me-2 fa-lg" aria-hidden="true"></i>Editar</a>
                                                <a className="dropdown-item" onClick={()=>{ toggleModal({ variant: 'confirm', text: '¿Está seguro que desea eliminar este onboarding?', onConfirm: () => { deleteOnboardingById(r.id); } }); }}>
                                                    <i className="fa fa-trash-o fa-fw fa-lg" aria-hidden="true"></i> Eliminar
                                                </a>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )))}
            </div>
        </div>
        <nav className="mt-5" aria-label="Paginación">
            <ReactPaginate
                className="pagination justify-content-center align-items-center"
                breakLabel=" ... "
                nextLabel=" > "
                onPageChange={handlePageClick}
                pageRangeDisplayed={0}
                marginPagesDisplayed={1}
                pageCount={pageItems.length}
                previousLabel=" < "
                renderOnZeroPageCount={() => null}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                pageLinkClassName={"page-link"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                activeClassName={"page-item active"}
                activeLinkClassName={"page-link active"}
            />
        </nav>
    </>;
}

export default TableIndexOnb;