import { FC, Children } from "react";
import { useForm, FormProvider, UseFormReturn } from "react-hook-form";
import { 
  ISurveyForm, 
  IHandleChange, 
  Question, 
  SurveyResponse, 
  SurveyFormValues,
  QUESTION_TYPES 
} from "../interfaces";
import { FORM_CONSTANTS, FORM_IDS } from '../constants';
import QuestionFactory from "./QuestionFactory";
import { generateQuestionId } from '../utils';

interface SurveyFormProps extends ISurveyForm {
  methods?: UseFormReturn<SurveyFormValues>;
}

const SurveyForm: FC<SurveyFormProps> = ({ 
  survey, 
  handleSubmitCallback, 
  SubmitButton,
  methods: externalMethods 
}) => {
  const methods = externalMethods || useForm<SurveyFormValues>();
  
  const handleChange = ({ e, tipo, opcion, otros }: IHandleChange) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;

    if (tipo === "SELC" && otros === "Y" && opcion) {
      const isLastOption = targetValue === opcion[opcion.length - 1];
      const nextQuestionId = targetName.replace(
        targetName[targetName.length - 1],
        (parseInt(targetName[targetName.length - 1]) + 1).toString()
      );

      const nextQuestionElement = document.getElementById(nextQuestionId) as HTMLInputElement;
      if (nextQuestionElement) {
        if (isLastOption) {
          nextQuestionElement.required = true;
        } else {
          nextQuestionElement.value = "";
          nextQuestionElement.required = false;
        }
      }
    }

    if (tipo === "MULT" && otros === "Y") {
      methods.setValue(targetName, targetValue);
    }
  };

  const processQuestionResponse = (question: Question, formData: SurveyFormValues) => {
    const response: SurveyResponse['respuestas'][0] = {
      id: question.id,
      titulo: question.titulo,
      tipo: question.tipo,
      items: []
    };

    const questionId = generateQuestionId(question.id);

    switch (question.tipo) {
      case QUESTION_TYPES.NPS:
      case QUESTION_TYPES.OPEN:
        response.items.push({
          nombre: question.titulo,
          valor: (formData[questionId] as string) || ""
        });
        break;

      case QUESTION_TYPES.SELECT:
        question.opcion.forEach(option => {
          response.items.push({
            nombre: option,
            valor: formData[questionId] === option ? "1" : "0"
          });
        });
        break;

      case QUESTION_TYPES.LIKERT:
        response.likert = question.likert;
        question.opcion.forEach((option, index) => {
          const radioValue = formData[`${questionId}${FORM_CONSTANTS.RADIO_SUFFIX}${index}`] as string;
          response.items.push({
            nombre: option,
            valor: radioValue || ""
          });
        });
        break;

      case QUESTION_TYPES.MULTIPLE:
        question.opcion.forEach((option, index) => {
          const isChecked = formData[`${questionId}${index}`] === option;
          response.items.push({
            nombre: option,
            valor: isChecked ? "1" : "0"
          });
        });

        if (question.otros === FORM_CONSTANTS.OTHERS_VALUE) {
          const otherAnswer = formData[`${questionId}${question.opcion.length}${FORM_CONSTANTS.OTHERS_SUFFIX}`] as string;
          if (otherAnswer !== undefined) {
            const cleanAnswer = otherAnswer.replace(
              new RegExp('\\' + FORM_CONSTANTS.QUESTION_SEPARATOR, 'g'), 
              FORM_CONSTANTS.DEFAULT_VALUE
            ).trim();
            response.items.push({
              nombre: FORM_CONSTANTS.OTHERS_LABEL,
              valor: cleanAnswer ? `1?${cleanAnswer}` : "0?"
            });
          }
        }
        break;
    }

    return response;
  };

  const onSubmit = (formData: SurveyFormValues) => {
    const request: SurveyResponse = {
      id: survey.id,
      titulo: survey.title,
      descrip: survey.descr,
      notificar: true,
      respuestas: []
    };

    const preguntas = Array.isArray(survey.preguntas) ? survey.preguntas : [survey.preguntas];
    request.respuestas = preguntas.map(pregunta => processQuestionResponse(pregunta, formData));

    if (request.respuestas.length > 0) {
      handleSubmitCallback(request);
    }
  };

  if (!survey) return null;

  return (
    <FormProvider {...methods}>
      <div>
        <h1>{survey.title}</h1>
        <p>{survey.descr}</p>
        <form 
          id={FORM_IDS.SURVEY_FORM} 
          className={`needs-validation`} 
          noValidate={true} 
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {survey.preguntas && Children.toArray(
            survey.preguntas.map((pregunta) => (
              <QuestionFactory
                pregunta={pregunta}
                survey={survey}
                handleChange={handleChange}
              />
            ))
          )}
          <div className="text-center my-5">
            {SubmitButton ? <SubmitButton /> : (
              <button type="submit" className="btn btn-degradado">
                Enviar<i className="fa fa-chevron-right ml-2" />
              </button>
            )}
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default SurveyForm;
