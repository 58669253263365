import Swal from "sweetalert2";

const confirmMessage = (callBack, customText = "¿Desea continuar la operación?") => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-degradado m-2",
      cancelButton: "btn btn-primary m-2",
    },
    buttonsStyling: false,
  });

  swalWithBootstrapButtons
    .fire({
      title: "Confirmación",
      text: customText,
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
      showCloseButton: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (typeof callBack === "function") {
          callBack();
        }
      }
    });
};

export default confirmMessage;
