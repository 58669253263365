import { FC } from "react";
import Likert from "./questions/Likert";
import Multiple from "./questions/Multiple";
import Nps from "./questions/Nps";
import Open from "./questions/Open";
import Select from "./questions/Select";
import { 
  IQuestionFactoryProps, 
  Question, 
  SelectQuestion, 
  LikertQuestion, 
  MultipleQuestion, 
  NpsQuestion,
  QUESTION_TYPES 
} from "../interfaces";
import { FORM_CONSTANTS } from '../constants';
import { generateQuestionId } from '../utils';

const QuestionFactory: FC<IQuestionFactoryProps> = ({ pregunta, survey, handleChange }) => {
  const questionId = generateQuestionId(pregunta.id);

  const isSelectQuestion = (q: Question): q is SelectQuestion => 
    q.tipo === QUESTION_TYPES.SELECT;
  const isLikertQuestion = (q: Question): q is LikertQuestion => 
    q.tipo === QUESTION_TYPES.LIKERT;
  const isNpsQuestion = (q: Question): q is NpsQuestion => 
    q.tipo === QUESTION_TYPES.NPS;
  const isMultipleQuestion = (q: Question): q is MultipleQuestion => 
    q.tipo === QUESTION_TYPES.MULTIPLE;

  const getOpenQuestionProps = () => {
    let req = false;
    let nomObj = "";
    let OpenOtros = "";

    if (!Array.isArray(survey.preguntas)) {
      return { req: true, nomObj: "", OpenOtros: "" };
    }

    const prevQuestionIndex = survey.preguntas.findIndex(q => q.id === pregunta.id) - 1;
    
    if (prevQuestionIndex < 0) {
      return { req: true, nomObj: "", OpenOtros: "" };
    }

    const prevQuestion = survey.preguntas[prevQuestionIndex];
    
    if (!isSelectQuestion(prevQuestion) && !isMultipleQuestion(prevQuestion)) {
      return { req: true, nomObj: "", OpenOtros: "" };
    }

    if (prevQuestion.otros === FORM_CONSTANTS.OTHERS_NO) {
      req = true;
    } else {
      OpenOtros = prevQuestion.opcion[prevQuestion.opcion.length - 1];
      nomObj = questionId.replace(
        questionId[questionId.length - 1], 
        (parseInt(questionId[questionId.length - 1]) - 1).toString()
      );
    }

    return { req, nomObj, OpenOtros };
  };

  switch (pregunta.tipo) {
    case QUESTION_TYPES.OPEN: {
      const { req, nomObj, OpenOtros } = getOpenQuestionProps();
      return (
        <Open
          id={questionId}
          titulo={pregunta.titulo}
          tipo={pregunta.tipo}
          req={req}
          nomObj={nomObj}
          otros={OpenOtros}
          handleChange={handleChange}
        />
      );
    }
    case QUESTION_TYPES.SELECT:
      if (isSelectQuestion(pregunta)) {
        return (
          <Select
            id={questionId}
            titulo={pregunta.titulo}
            tipo={pregunta.tipo}
            opcion={pregunta.opcion}
            otros={pregunta.otros}
            handleChange={handleChange}
          />
        );
      }
      break;

    case QUESTION_TYPES.LIKERT:
      if (isLikertQuestion(pregunta)) {
        return (
          <Likert
            id={questionId}
            titulo={pregunta.titulo}
            tipo={pregunta.tipo}
            opcion={pregunta.opcion}
            likert={pregunta.likert}
            handleChange={handleChange}
          />
        );
      }
      break;

    case QUESTION_TYPES.NPS:
      if (isNpsQuestion(pregunta)) {
        return (
          <Nps
            id={questionId}
            titulo={pregunta.titulo}
            tipo={pregunta.tipo}
            opcion={pregunta.opcion}
            likert={pregunta.likert}
            handleChange={handleChange}
          />
        );
      }
      break;

    case QUESTION_TYPES.MULTIPLE:
      if (isMultipleQuestion(pregunta)) {
        return (
          <Multiple
            id={questionId}
            titulo={pregunta.titulo}
            tipo={pregunta.tipo}
            opcion={pregunta.opcion}
            otros={pregunta.otros}
            icono={pregunta.icono}
            handleChange={handleChange}
          />
        );
      }
      break;
  }

  return null;
};

export default QuestionFactory;
