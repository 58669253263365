/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import VacacionesService from "../../services/solicitud-vacaciones-uy/vacaciones-uy-service";
import styles from "../../resources/css/style-COL.module.css";
import Swal from 'sweetalert2';
import Tippy from '@tippyjs/react';
import { RiErrorWarningLine } from 'react-icons/ri';
import moment from 'moment';
import { constTrue } from 'fp-ts/lib/function';
import { useForm } from 'react-hook-form';
import errorMessage from "../SharedComponents/error-message";
import successMessage from "../SharedComponents/success-message";
import warningMessage from "../SharedComponents/warning-message"
import ReplacementsService from '../../services/asignacion-reemplazo/replacements-service';
import ReplacementExportableComponent from '../asignacion-reemplazo/replacement-exportable';


const EditCardVacaciones = () => {
    const navigate = useNavigate();
    const [dataApprover, setDataApprover] = useState({});
    const [fetched, setFetched] = useState(false); 
    const [disabled, setDisabled] = useState(null);

    const [appSent, setAppSent] = useState(false);
    const [errorFlag, setErrorFlag] = useState(false);

    const [loadingSave, setLoadingSave] = useState(false);

    const [sinAprobador, setSinAprobador] = useState(null);
    const [loadingCard, setLoadingCard] = useState(true); 
    const [ausenciaFlag, setAusenciaFlag] = useState(null);

    const [fiFlag, setFiFlag] = useState(false);
    const [ffFlag, setFfFlag] = useState(false);

    const [duration, setDuration] = useState(0);
    const [loadingDuration, setLoadingDuration] = useState(false);

    const [dates, setDates] = useState({
        start_date: null,
        end_date: null,
    });

    const [startDatesNotValidated, setStartDatesNotValidated] = useState(false);
    const [endDatesNotValidated, setEndDatesNotValidated] = useState(false);
    const [startDateNotValidated, setStartDateNotValidated] = useState(false);
    const [durationError, setDurationError] = useState(null);
  const [currentReplacement, setCurrentReplacement] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);
  const [replacements, setReplacements] = useState([]);
  const [replacementsActive, setReplacementsActive] = useState(true);
  const [dataNotFetched, setDataNotFetched] = useState(false);
  const [isApprover, setIsApprover] = useState(true);
  const [loadingApproverStatus, setLoadingApproverStatus] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);


    const {
        setValue,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        const fetchUserReplacements = async () => {
          setLoadingTable(true);
          try {
            const response = await ReplacementsService.getUserReplacements(setLoadingTable, setReplacements, setDataNotFetched);
            setReplacements(response.data);
          } catch (error) {
            setDataNotFetched(true);
          } finally {
            setLoadingTable(false);
          }
        };
        fetchUserReplacements();
      }, []);

      useEffect(() => {
        const fetchFeatureFlag = async () => {
          try {
            setLoadingApproverStatus(true);
            const response = await ReplacementsService.getReplacementsFeatureFlag();
            setIsEnabled(response.data.isEnabled);
          } catch (error) {
            console.error(error);
          }finally {
            setLoadingApproverStatus(false);
          }
        };
      
        fetchFeatureFlag();
      }, []);
    
      useEffect(() => {
        doesReplacementExist();
      }, []);
    
      const doesReplacementExist = async () => {
        try {
          await ReplacementsService.getCurrentReplacement(
            setLoadingTable,
            setCurrentReplacement,
            setDataNotFetched
          );
        } catch (error) {
          console.error("Error while setting values:", error);
        }
      };

    const getApprover = async () => {
        try {
        setLoadingCard(true);
    
          const response = await VacacionesService.getApprover();
    
          if (
            response !== null &&
            response !== undefined &&
            (response?.statusCode === 200 || response?.status === 200)
          ) {
            setDataApprover(response?.data);
            setFetched(true)
          } else {
            setFetched(false)
          }
    
          setLoadingCard(false);
        } catch (error) {
            setLoadingCard(false);
            setFetched(false)
        }
      };

      useEffect(() => {
        getApprover();
      },[])


      useEffect(() => {
        hasSubordinates();
        }, []);
        
      const hasSubordinates = async () => {
        try {
          await ReplacementsService.hasSubordinates(
            setIsApprover,
            setLoadingApproverStatus
          );
        } catch (error) {
          console.error("Error while setting values:", error);
        }
      };
    const onInputChange = (e) => {
        e.preventDefault();
        setDates({
            ...dates,
            [e.target.name]: e.target.value,
        });
        setDuration(0);
        setDurationError(null);
        setStartDateNotValidated(false);
        setStartDatesNotValidated(false);
        setEndDatesNotValidated(false);
    };

    const generateDays = async (e) => {
        e.preventDefault();
        setStartDatesNotValidated(false);
        setEndDatesNotValidated(false);

        if (dates.start_date == undefined || dates.start_date == "" || dates.start_date == null) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> Complete las fechas de la solicitud para continuar </div>',
                confirmButtonColor: '#0169b5',
            })
            return;
        }
        if (dates.end_date == undefined || dates.end_date == "" || dates.end_date == null) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> Complete las fechas de la solicitud para continuar </div>',
                confirmButtonColor: '#0169b5',
            })
            return;
        }
        if (dates.start_date > dates.end_date) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> La fecha inicial no puede ser mayor a la fecha final. </div>',
                confirmButtonColor: '#0169b5',
            });
            // setDatesValidated(false)
            setDuration(0);
            return;
        }
        setStartDateNotValidated(false);
        setLoadingDuration(true);

        try {        
              const response = await VacacionesService.getDuration(dates.start_date, dates.end_date);
              if (
                response !== null &&
                response !== undefined &&
                (response?.statusCode === 200 || response?.status === 200)
              ) {
                setDuration(response?.data.duracion);
                setLoadingDuration(false)
              } else {
                setLoadingDuration(false)
              }
        
              setLoadingCard(false);
            } catch (error) {
                errorMessage(null, error?.response?.data);
                setLoadingDuration(false);
            }
    };


    const resetDates = () => {
        setValue("terms",false);
        setDates({
            start_date: "",
            end_date: "",
        });
        setDuration(0);
    }

    const handleRD = () => {
        setFfFlag(!dates.end_date ? true : false);
    }
    const handleBD = () => {
        setFiFlag(!dates.start_date ? true : false);
    }

    const onSubmit = async (data) => {
        setLoadingSave(true)
        setDurationError(null);
        if (dataApprover?.nombre == "" || dataApprover?.nombre == undefined || dataApprover?.nombre == null) {
            Swal.fire({
              allowOutsideClick: false,
              position: "center",
              title:
                '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
              html: '<div style="text-align: center; font-size: 15px"> Usted no tiene aprobador, contacta a tu BP o Jefe de Recursos Humanos. </div>',
              confirmButtonColor: "#0169b5",
            });
            setLoadingSave(false)
            return;
          }
        if (duration === 0) {
          Swal.fire({ title: "¡Atencion!", text: "Antes de solicitar, se requiere calcular la duración del período ingresado. Presione el botón cálculo de días.", icon: "error" })
          setLoadingSave(false)
          return;
        }
        
        if (!data.terms) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> Debe aceptar las condiciones para poder enviar la solicitud </div>',
                confirmButtonColor: '#0169b5',
            })
            setLoadingSave(false)
            return false;
        }

        const submit = {
            idJefe: dataApprover?.emplid,
            aceptacionterminos: true,
            inicio: dates.start_date,
            fin: dates.end_date,
            duracion: duration

        }

        const response = await VacacionesService.postSolicitud(submit)
        if (response == null) {
            errorMessage(resetDates,response?.data.message);
            setLoadingSave(false);
        } else if(response.message === "No tienes días de vacaciones disponibles."){
          warningMessage(resetDates,response?.message);
          setLoadingSave(false);
          setAppSent(false);
        }else {
            const status = response?.statusCode || response?.status;
            switch (status) {
            case 200:
                setLoadingSave(false);
                successMessage(navigate("solicitudes-vacaciones-uy"));
                setAppSent(true);
            break;

          case 400:
            errorMessage(resetDates,response?.message);
            setLoadingSave(false);
            setAppSent(false);
            break;

          case 404:
            errorMessage(resetDates,response?.message);
            setLoadingSave(false);
            setAppSent(false);
            break;

          case 500:
            errorMessage(resetDates,response?.message);
            setLoadingSave(false);
            setAppSent(false);
            break;

          default:
            errorMessage(resetDates,response?.message);
            setLoadingSave(false);
            setAppSent(false);
            break;
        }
    }
    }


    return (
        <>
            <div className="mx-auto">
                <div className="card-box holiday-card">
                    {!loadingCard && fetched ?
                        <div id="form" className='need-validation' noValidate >
                            <div className="btn-container">
                                <div className='button-history-container'>
                                    <button disabled={disabled} className='button history'><Link to='solicitudes-vacaciones-uy'>Ver Historico <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></Link></button>
                                </div>
                                <div className="holiday-btn">
                                    <button className='button cancel' type="reset" onClick={resetDates}>Cancelar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    <button disabled={loadingSave} className='button save' onClick={handleSubmit(onSubmit)}>Enviar{loadingSave ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}</button>
                                </div>
                            </div>
                            <div className='sections mt-6'>
                                {/*GENERALES*/}
                                <div className='section'>
                                    <div className='row'>
                                        <div className='col-12 mt-4'>
                                            <div className='fields'>
                                                <div className='approver'>
                                                    <div className='title'>
                                                        Aprobador de vacaciones : <span className='approver-name'>{dataApprover?.nombre}</span>
                                                    </div>
                                                    <div className='subtitle'>
                                                        Si tu aprobador no es el correcto contactá a tu BP.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-3 p-1 mt-4'>
                                            <div className={fiFlag ? 'alert-fields' : 'fields'}>
                                                <div className='label fw-bold'><div style={{ color: "red" }}>*</div>Fecha Inicio <Tippy content="La fecha de inicio debe ser un día hábil."><div><i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i></div></Tippy></div>
                                                <input
                                                    onBlur={handleBD}
                                                    className="form-control"
                                                    type="date"
                                                    placeholder="dd/mm/aaaa"
                                                    name="start_date"
                                                    value={dates.start_date}
                                                    onChange={(e) => onInputChange(e)}
                                                />
                                                <small className="form-error-message">
                                                    {startDatesNotValidated
                                                        ? "* Campo obligatorio"
                                                        : null}
                                                </small>
                                            </div>
                                        </div>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-3 p-1 mt-4'>
                                            <div className={ffFlag ? 'alert-fields' : 'fields'}>
                                                <div className='label fw-bold'><div style={{ color: "red" }}>*</div>Fecha Final <Tippy content="Para establecer la fecha fin considerá que los días deben ser corridos e incluir los fines de semana."><div><i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i></div></Tippy></div>
                                                <input
                                                    onBlur={handleRD}
                                                    className="form-control"
                                                    type="date"
                                                    placeholder="dd/mm/aaaa"
                                                    name="end_date"
                                                    value={dates.end_date}
                                                    onChange={(e) => onInputChange(e)}
                                                />
                                                <small className="form-error-message">
                                                    {endDatesNotValidated
                                                        ? "* Campo obligatorio"
                                                        : null}
                                                </small>
                                            </div>
                                        </div>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4 p-1 mt-4'>
                                            <button
                                                type="button"
                                                disabled={loadingDuration}
                                                onClick={(e) => generateDays(e)}
                                                className={`btn btn-degradado w-100 mt-4`}
                                            >
                                                Calcular{" "}
                                                {loadingDuration ? (
                                                    <div className="spinner-border spinner-border-sm"></div>
                                                ) : (
                                                    <i
                                                        className={`fa fa-chevron-right fa-fw fa-xs`}
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </button>
                                        </div>
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-2 p-1 mt-4'>
                                            <div className={'fields'}>
                                                <div className='label fw-bold'>Duración</div>
                                                {loadingDuration ? (
                                                    <div className="spinner-border spinner-border-sm"></div>
                                                ) : (
                                                    <input
                                                        className="form-control mt-1"
                                                        type="text"
                                                        value={`${duration} días`}
                                                        disabled
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loadingApproverStatus ? (
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border text-primary"></div>
                        </div>
                      ) : (
                        <div className="mt-3">
                          {isApprover && isEnabled ? (
                            <>
                              <div className={styles.fields}>
                                <div className={`${styles.label} fw-bold mb-2`}>
                                  Asignar Reemplazo
                                  <div
                                    onClick={() =>
                                      setReplacementsActive(!replacementsActive)
                                    }
                                  >
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  !replacementsActive
                                    ? styles.replacements
                                    : styles["replacements-active"]
                                }
                              >
                                <ReplacementExportableComponent
                                initDate={dates.start_date}
                                endDate={dates.end_date}
                                current={currentReplacement}
                                replacements={replacements}
                                setReplacements={setReplacements}
                                hideSendButton={true}
                                isSubmitForm={appSent}
                              />
                              </div>
                            </>
                          ) : null}
                        </div>
                      )}
                            {/*DETALLE*/}
                            <div className='section mt-4'>
                                <div className='terms-legals'>
                                    <div className='title fw-bold'><p style={{ color: "red" }}>*</p>Términos y legales</div>
                                    <div className='text'>Estoy en conocimiento del saldo de días disponibles posterior a la solicitud de vacaciones, declarando mi conformidad con la información proporcionada por el sistema. Certifico que he delegado los permisos de acceso a los sistemas
                                        correspondientes, de manera de garantizar la continuidad normal del flujo de los procesos/negocios de la Compañía.
                                    </div>
                                    <div className='accept'>
                                        <input type="checkbox" className='check form-check-input' value={true} {...register("terms", {
                                            required:
                                                "Debe leer y aceptar los terminos legales antes de continuar",
                                        })}></input>
                                        <label className='form-check-label' htmlFor="invalidCheck">
                                            Acepto Términos y legales
                                        </label>
                                    </div>
                                    <small className="form-error-message">
                                        {errors.terms?.message}
                                    </small>
                                </div>
                            </div>
                        </div>
                        : !loadingCard ? <div className="error-container-lb text-def"><RiErrorWarningLine className="color-primary error-icon fs-5" /><div>Se ha producido un error. Vuelva a intentarlo</div></div> : <div className="full-spinner"><div className="spinner-border text-primary"></div></div>}
                </div>
            </div>
        </>

    )
}

export default EditCardVacaciones
