import React, { Children, FC } from "react";
import { useFormContext } from "react-hook-form";
import { SurveyFormValues } from "../../interfaces";

interface MultipleProps {
    id: string;
    titulo: string;
    tipo: "MULT";
    opcion: string[];
    otros: string;
    icono: string;
    handleChange: (params: any) => void;
}

const Multiple: FC<MultipleProps> = ({ 
    id, 
    titulo, 
    tipo, 
    opcion, 
    otros, 
    icono,
    handleChange 
}) => {
    const { register, watch } = useFormContext<SurveyFormValues>();
    
    return (
        <div className="box position-relative mb-5">
            <h2 className="h3 tit-section">{titulo}</h2>
            <div>
                {Children.toArray(opcion.map((op, index) => (
                    <div className="form-check mb-2 ms-1 mb-3">
                        <input
                            className="form-check-input h5"
                            type="checkbox"
                            value={op}
                            {...register(`${id}${index}`)}
                        />
                        <label 
                            className="form-check-label fw-normal color-primary ms-2" 
                            htmlFor={`${id}${index}`}
                        >
                            {op}
                        </label>
                    </div>
                )))}
                {otros === "Y" && (
                    <div className="d-flex align-items-center mb-2 mb-3 justify-content-start">
                        <div className="form-check ms-1 my-1 me-3">
                            <input
                                className="form-check-input h5"
                                type="checkbox"
                                defaultChecked={false}
                                {...register(`${id}${opcion.length}`)}
                            />
                            <label 
                                className="form-check-label fw-normal color-primary ms-2" 
                                htmlFor={`${id}${opcion.length}`}
                            >
                                Otros
                            </label>
                        </div>
                        {watch(`${id}${opcion.length}`) && (
                            <div>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    placeholder="Tu respuesta"
                                    name={`${id}${opcion.length}otros`}
                                    onChange={(e) => handleChange({e, tipo, opcion, otros})}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {icono && (
                <i 
                    className={`fa ${icono} color-orange fa-5x encuesta-icon`} 
                    aria-hidden="true" 
                />
            )}
        </div>
    );
};

export default Multiple;