import NewOnboarding from "./newOnboarding/newOnboarding";
import { FC, useEffect, useState } from "react";
import { onboardingStore } from "../../utilities/store";
import IndexOnboarding from "./indexOnboarding/indexOnboarding";
import { getAllOnboarding, getParameters } from "../../utilities/apiRequest";
import { BreadCrumbsDinamic } from "../../../commonComponents/BreadCrumbsDinamic";
import { toggleModal } from "../../../commonComponents/Modal";
import { scrollToTop } from "../../../../services/utiles";


const OnboardingManagerPage: FC = () => {
  const parameters = onboardingStore(state => state.parameters);
  const setParameters = onboardingStore(state => state.setParameters);
  const setOnboardings = onboardingStore(state => state.setOnboardings);
  const [showOnboardingDetail, setShowOnboardingDetail] = useState(false);
  const selectedOnboarding = onboardingStore(state => state.selectedOnboarding);
  const setSelectedOnboarding = onboardingStore(state => state.setSelectedOnboarding);
  const setError = onboardingStore(state => state.setError);
  const setIsLoading = onboardingStore(state => state.setIsLoading);
  
  useEffect(() => {
    getParametersData();
  }, []);

  const getParametersData = async () => {
    try {
      const response = await getParameters();
      setParameters(response);
    } catch (error) {
      toggleModal({
        variant: 'status',
        text: 'Error al obtener los parámetros',
        status: 'error',
        onAccept: () => {}
      });
    }	  
  }

  const getFiltredOnboardings = async () => {
    setIsLoading(true);
    try{
        const data = await getAllOnboarding();
        setOnboardings(data);
        setError(false);
    } catch (error) {
        toggleModal({
            variant: 'status',
            text: 'Error al obtener los onboarding publicados',
            status: 'error',
            onAccept: () => {
              setError(true);
            }
        });
    } finally {
      setIsLoading(false);
    }
}

  const resetOnboarding = () => {
    setSelectedOnboarding(null);
    setShowOnboardingDetail(false);
  }

  const paths = showOnboardingDetail ? [
    { label: 'Gestión de Onboarding', url: '/onboarding-manager', onClick: () => {
      toggleModal({
        variant: 'confirm',
        text: "¿Está seguro que desea volver? Los cambios no guardados se perderán.",
        onConfirm: () => {
          resetOnboarding();
          scrollToTop();
        },
      });
    } },
    { label: `${selectedOnboarding ? 'Editar' : 'Nuevo'} Onboarding`, url: '' }
  ] : [
    { label: 'Gestión de Onboarding', url: '/onboarding-manager', onClick: resetOnboarding }
  ];  

  return (
    <main className="wrapper d-flex">
      <div className="w-100 p-0" id="content">
          <BreadCrumbsDinamic paths={paths} />
          { !showOnboardingDetail && parameters.countries.length > 0 && <IndexOnboarding getFiltredOnboardings={getFiltredOnboardings} setShowOnboardingDetail={setShowOnboardingDetail} /> }
          { showOnboardingDetail && parameters.countries.length > 0 && <NewOnboarding getFiltredOnboardings={getFiltredOnboardings} resetOnboarding={resetOnboarding} /> }
      </div>
    </main>
  )
}
export default OnboardingManagerPage;