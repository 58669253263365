import { Children, FC } from "react";
import { useFormContext } from "react-hook-form";
import { SurveyFormValues } from "../../interfaces";

interface SelectProps {
    id: string;
    titulo: string;
    tipo: "SELC";
    opcion: string[];
    otros: string;
    handleChange: (params: any) => void;
}

const Select: FC<SelectProps> = ({ 
    id, 
    titulo, 
    tipo, 
    opcion, 
    otros,
    handleChange 
}) => {
    const { register, formState: { errors } } = useFormContext<SurveyFormValues>();

    return (
        <div className="box mb-4">
            <h2 className="h3 tit-section">{titulo}</h2>
            <div className="form-check-group form-check-group-even">
                {Children.toArray(opcion.map((op) => (
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            value={op}
                            {...register(id, {
                                required: "*Esta pregunta es obligatoria.",
                                onChange: (e) => handleChange({ e, opcion, tipo })
                            })}
                        />
                        <label className="form-check-label" htmlFor={`${id}radio`}>
                            {op}
                        </label>
                    </div>
                )))}
            </div>
            {errors[id]?.message && (
                <div className="invalid-feedback d-block">{errors[id]?.message || ""}</div>
            )}
        </div>
    );
};

export default Select;