import "../../resources/css/style-COL.module.css";
import { LunchScheduleContent } from "./LunchScheduleContent";
import { LunchScheduleProvider } from "./context/LunchScheduleContext";

export const LunchSchedulePage = () => {
  return (
    <LunchScheduleProvider>
      <LunchScheduleContent />
    </LunchScheduleProvider>
  );
};
