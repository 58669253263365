import { create } from 'zustand';
import { ParametersProps, Groups, Filters, OnboardingProps } from './interfaces';

interface OnboardingStore {
    parameters: ParametersProps;
    setParameters: (parameters: ParametersProps) => void;
    selectedOnboarding: OnboardingProps | null;
    setSelectedOnboarding: (onboarding: OnboardingProps | null) => void;
    onboardings: OnboardingProps[];
    setOnboardings: (onboardings: OnboardingProps[]) => void;
    filters: Filters;
    setFilters: (filters: Filters) => void;
    resetFilters: (pais:string) => void;
    buttonDisabled: boolean;
    setButtonDisabled: (buttonDisabled: boolean) => void;
    error: boolean;
    setError: (error: boolean) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
}

export const onboardingStore = create<OnboardingStore>((set) => ({
    onboardings: [],
    setOnboardings: (onboardings: OnboardingProps[]) => set({ onboardings }),
    error: false,
    setError: (error: boolean) => set({ error }),
    filters: {
        status: "",
        startDate: "",
        endDate: "",
        country: ""
    },
    setFilters: (filters: Filters) => set({ filters }),
    resetFilters: (pais:string) => set({ 
        filters: { 
            status: "", 
            startDate: "", 
            endDate: "", 
            country: pais 
        } 
    }),
    parameters: {
        countries: [],
        menuitems: []
    },
    setParameters: (parameters: ParametersProps) => set({ parameters }),
    selectedOnboarding: null,
    setSelectedOnboarding: (onboarding: OnboardingProps | null) => set({ selectedOnboarding: onboarding }),
    buttonDisabled: false,
    setButtonDisabled: (buttonDisabled: boolean) => set({ buttonDisabled }),
    isLoading: false,
    setIsLoading: (isLoading: boolean) => set({ isLoading })
}));
