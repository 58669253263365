import { create } from "zustand";

export type ModalProps = {
    size?: 'sm' | '' | 'lg' | 'xl';
    isOpen: boolean;
    title?: string;
    content?: React.ReactNode;
    variant: 'default' | 'loading' | 'confirm' | 'status' | 'custom';
    footer?: React.ReactNode;
    onClose: () => void;
}

export const useModalStore = create<ModalProps>((set) => ({
    size: '',
    isOpen: false,
    title: '',
    content: undefined,
    variant: 'default',
    footer: undefined,
    onClose: () => {},
}));
