import { FaCheckCircle, FaTrash, FaEdit } from "react-icons/fa";
import { CgAddR } from "react-icons/cg";
import { LunchDay } from "./hooks/LunchScheduleApi.types";

export type Props = {
    day: LunchDay;
    selectedDays: {
        date: string;
        reservationPlace: string | null;
    }[];
    handleDelete: (id: number) => void;
    handleDayClick: (day: LunchDay) => void;
    setHoveredDate: React.Dispatch<React.SetStateAction<string | null>>
    hoveredDate: string | null;
};

export const CalendarItem = (props: Props) => {
    const { day, selectedDays, handleDelete, handleDayClick, setHoveredDate, hoveredDate } = props;
    return (
        <td
            style={{
                outline: "1px dashed grey",
                height: "70px",
                verticalAlign: "top",
                position: "relative",
                cursor: day?.reservationPlace ? "default" : day?.disabled ? "default" : "pointer",
                backgroundColor: day?.disabled
                    ? "#e9ecef"
                    : day?.reservationPlace
                        ? selectedDays.some(
                            (item) => item.date === day.date
                        )
                            ? "#d4edda"
                            : "white"
                        : selectedDays.some(
                            (item) => item.date === day.date
                        )
                            ? "#d4edda"
                            : "#fff",
                opacity: day?.disabled ? 0.5 : 1,
            }}
            onClick={() =>
                day && !day.disabled && !day.reservationPlace && handleDayClick(day)
            }
            onMouseEnter={() =>
                day && !day.disabled && setHoveredDate(day.date)
            }
            onMouseLeave={() =>
                day && !day.disabled && setHoveredDate(null)
            }
        >
            <div className="p-1">
                <span
                    style={{
                        color:
                            day?.reservationPlace && !day?.disabled
                                ? "#0169b5"
                                : day?.disabled
                                    ? "#999"
                                    : selectedDays.some(
                                        (item) => item.date === day.date
                                    )
                                        ? "#28a745"
                                        : "#0169b5",
                    }}
                >
                    {day ? day.day : ""}
                </span>
            </div>
            {day?.reservationPlace && (
                <div
                    className={`reserved-label text-center ${day?.disabled ? "disabled" : ""
                        }`}
                    style={{
                        height: "30%",
                        verticalAlign: "top",
                        position: "relative",
                        backgroundColor:
                            hoveredDate === day?.date && !day.disabled
                                ? "#A0BEE5"
                                : "#0169b5",
                        opacity: day?.disabled ? 0.7 : 1,
                        color: "#fff",
                        cursor: day?.disabled ? "default" : "pointer",
                    }}
                >
                    {day.reservationPlace}
                </div>
            )}
            {hoveredDate === day?.date &&
                day?.reservationPlace &&
                !day.disabled && (
                    <div
                        className="position-absolute d-flex justify-content-center align-items-center"
                        style={{
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            gap: "30px",
                        }}
                    >
                        <FaEdit
                            style={{
                                color: "white",
                                fontSize: "26px",
                                backgroundColor: "#0169b5",
                                padding: "5.5px",
                                borderRadius: "20%",
                                boxShadow: "0 0 5px rgba(0,0,0,0.3)",
                                cursor: "pointer",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDayClick(day);
                            }}
                        />
                        <FaTrash
                            style={{
                                color: "white",
                                fontSize: "26px",
                                backgroundColor: "red",
                                padding: "6.5px",
                                borderRadius: "20%",
                                boxShadow: "0 0 5px rgba(0,0,0,0.3)",
                                cursor: "pointer",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(day.id);
                            }}
                        />
                    </div>
                )}
            {!day?.reservationPlace && (
                <div
                    className="d-flex position-absolute justify-content-center"
                    style={{
                        bottom: "8px",
                        left: "50%",
                        top: "60%",
                        transform: "translate(-50%, -60%)",
                    }}
                >
                    {day &&
                        selectedDays.some(
                            (item) => item.date === day.date
                        ) ? (
                        <FaCheckCircle
                            style={{ color: "#28a745", fontSize: "20px" }}
                        />
                    ) : day && !day.disabled ? (
                        <CgAddR
                            style={{ color: "#0169b5", fontSize: "20px" }}
                        />
                    ) : null}
                </div>
            )}
        </td>
    )
}
