import React, { createContext, useState, useEffect } from "react";
import "dotenv/config";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { encrypt, pwd } from "../services/utiles";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import menuService from "../services/permissions/access-service";
import organigramaService from "../services/organigrama/organigrama";
import config from "../config";
import datosLaboralesService from "../services/datos-laborales-service";
import PanelAdministracionService from "../services/panel-administracion-service";
import fichaContratacionService from "../services/ficha-contratacion-service";
import ReactGA from "react-ga";
import listService from "../services/list-service";
import moduloDtService from "../services/modulo-dt-service";
import { ContextInterface, Tracker } from "./utilities/interfaces";
import { GetSheet } from "../components/fichaIngreso/utilities/interfaces";
import { urlsAws } from "../resources/foo/api-endpoints";
import miEquipoService from "../services/mi-equipo-service";
import { ErrorScreenType } from "../components/commonComponents/error-screen/types";
import { getMaintanceServices, getIsMaintance } from "../services/maintanceServives/maintanceService";
import { scrollToTop } from "../services/utiles";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib-esm/types/Auth";
import { validateAtributes } from "../components/login/login";

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

const TRACKING_ID = process.env.REACT_APP_GA;
const tracker: Tracker = {
  trackingId: typeof TRACKING_ID === "string" ? TRACKING_ID : "",
};
ReactGA.initialize([tracker]);

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    region: config.AWS_REGION,
    userPoolId: config.AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.AWS_COGNITO_CLIENT_ID,
    oauth: {
      domain: config.AWS_COGNITO_CLIENT_DOMAIN_NAME,
      scope: config.AWS_COGNITO_IDP_OAUTH_CLAIMS,
      redirectSignIn: config.AWS_COGNITO_IDP_SIGNIN_URL,
      redirectSignOut: config.AWS_COGNITO_IDP_SIGNOUT_URL,
      responseType: config.AWS_COGNITO_IDP_GRANT_FLOW,
      pkce: true,
    },
  },
});

const Context = createContext<ContextInterface | any>(null);

interface PhotoProps {
  emplid: string;
  photo: string;
  status: string;
  valid: string;
}

const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [active, setActive] = useState<boolean>(false);
  const [shrink, setShrink] = useState<boolean>(false);
  const location = useLocation();
  const [employeeId, setEmployeeId] = useState(
    location.pathname === "/" ? null : localStorage.getItem("employeeId")
  );
  const [emplid, setEmplid] = useState(
    location.pathname === "/" ? null : localStorage.getItem("emplid")
  );
  const boss = localStorage.getItem(encrypt("boss"));
  const [step, setStep] = useState(0);
  const [listStep, setListStep] = useState<never[] | number[]>([]);
  const [bankList, setBankList] = useState(localStorage.getItem("bankList"));
  const [DireccionCHL, setDireccionCHL] = useState(
    localStorage.getItem("DireccionCHL")
  );
  const [getSheet, setGetSheet] = useState<GetSheet | null>(null);
  const [fichaErr, setFichaErr] = useState<string | null>(null);
  const [group, allGroup] = useState<any | null>(null);
  const [groupUser, allGroupUser] = useState<any | null>(null);
  const [grupos, setGrupos] = useState<any | {}>({});
  const [appState, setAppState] = useState(false);
  const [firstLoginData, setFirstLoginData] = useState<{
    [key: string]: string;
  } | null>(null);
  const [auditoria, setAuditoria] = useState<any | boolean>(false);
  const [cn, setCn] = useState(false);
  const [pais, setPais] = useState<string | null>(null);
  const [menuItems, setMenuItems] = useState<any | null>(null);
  const [someLoading, setSomeLoading] = useState<boolean>(false);
  const [userIsBoss, setUserIsBoss] = useState<boolean>(false);
  const [someError, setSomeError] = useState<ErrorScreenType>(
    {} as ErrorScreenType
  );
  const [foto, setFoto] = useState<PhotoProps[]>([]);
  const navigate = useNavigate();
  const [isPublicUrl, setIsPublicUrl] = useState<boolean | null>(null);
  const [showNewsletter, setShowNewsletter] = useState<boolean>(false);
  const [showDonations, setShowDonations] = useState<boolean>(false);
  const [messageMaintenance, setmessageMaintenance] = useState("");
  const [flagFirstLogin, setFlagFirstLogin] = useState<boolean>(false);
  const showNewsletterButton =
    process.env.REACT_APP_NEWSLETTER === "true" || true;
  const DATE_DONATION_EXPIRED = new Date("2024-03-01");
  const showDonationButton =
    process.env.REACT_APP_DONATION === "true" ||
    DATE_DONATION_EXPIRED > new Date();

  useEffect(() => {
    const getIsBoss = async () => {
      const response = await organigramaService.getIsBoss()
      setUserIsBoss(response?.data.isBoss)
    }
    getIsBoss();
  }, [])

  useEffect(() => {
    if (location.pathname !== "/" && !isPublicUrl) {
      if (
        location.pathname !== sessionStorage.getItem("location") &&
        !location.search.includes("?code=")
      ) {
        ReactGA.pageview(location.pathname + location.search);
        if (location.pathname.includes("/nuevo/")) {
          sessionStorage.setItem(
            "location",
            location.pathname.replace("/nuevo", "")
          );
          navigate(location.pathname.replace("/nuevo", ""));
        } else if (!location.pathname.includes("/nuevo")) {
          sessionStorage.setItem("location", location.pathname);
        }
      }
    }
    if (
      location.pathname === "/nuevo" ||
      location.pathname === "/faces/login.xhtml"
    ) {
      navigate("/inicio");
    }
  }, [location, isPublicUrl]);

  useEffect(() => {
    menuItems && menuItems.forEach((menu: any) => {
      if (menu.enabledOnlyBoss && menu.url === location.pathname) {
        if (!userIsBoss) {
          navigate("/inicio");
        }
      }
    });
  }, [menuItems, userIsBoss]);

  useEffect(() => {
    const getAppState = async () => {
      const country = await Auth.currentUserInfo()
        .then((user) => {
          if (
            user.attributes["custom:cd_pais"] &&
            user.attributes["custom:cd_pais"] !== undefined &&
            user.attributes["custom:cd_pais"] !== null &&
            user.attributes["custom:cd_pais"] !== ""
          ) {
            //require(`../resources/css/style-${user.attributes['custom:cd_pais']}.css`)
            return user.attributes["custom:cd_pais"];
          } else {
            setSomeError({ etype: "atributosError" } as ErrorScreenType);
            setSomeLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          return null;
        });

      const menu = await menuService.getMenu();

      if (menu.message) {
        setSomeError({ etype: "internalServerError" } as ErrorScreenType);
        setSomeLoading(false);
        return;
      }

      if (!country) {
        const isPrivateRoute = menu.filter(
          (item: any) => item.url === location.pathname
        );
        if (isPrivateRoute.length === 0) {
          localStorage.setItem("redirect", location.pathname + location.search);
          navigate("/");
        }
        setMenuItems(menu);
        return;
      }

      setPais(country);
      setSomeLoading(true);

      await validateAtributes(setSomeError, setSomeLoading)

      const maintance = await getIsMaintance(setSomeError, setmessageMaintenance);
      if (maintance) {
        setSomeLoading(false);
        return;
      }

      setSomeLoading(false);
      if (!menu && flagFirstLogin) {
        setSomeError({ etype: "internalServerError" } as ErrorScreenType);
        return;
      }

      if (country) {
        if (country === "CL") {
          const isPublic = menu.find(
            (item: any) => item.url === location.pathname
          )?.publico;
          if (isPublic) {
            setMenuItems(menu);
            return;
          }
          const employeeId = await Auth.currentAuthenticatedUser().then(
            (auth) => {
              if (
                localStorage.getItem("jwtId") === null &&
                localStorage.getItem("jwtAuth") === null
              ) {
                let jwtId = `${auth.keyPrefix}.${auth.username}.idToken`;
                let jwtAuth = `${auth.keyPrefix}.${auth.username}.accessToken`;

                localStorage.setItem("jwtId", auth.storage[jwtId]);
                localStorage.setItem("jwtAuth", auth.storage[jwtAuth]);
              }
              if (
                auth.signInUserSession.idToken.payload["locale"] !== undefined
              ) {
                const pwdDate = pwd(
                  auth.signInUserSession.idToken.payload["locale"]
                );
                sessionStorage.setItem("expDate", pwdDate.toString());
              }
              localStorage.setItem(
                "employeeId",
                auth.signInUserSession.idToken.payload["custom:emplid"]
              );
              setCn(
                auth.signInUserSession.idToken.payload.identities[0].userId
                  ? auth.signInUserSession.idToken.payload.identities[0].userId
                  : false
              );
              return auth.signInUserSession.idToken.payload["custom:emplid"];
            }
          );
          const loginData = await datosLaboralesService.firstLogin();
          const state = await PanelAdministracionService.getAppState();
          if (!state) {
            setSomeError({ etype: "internalServerError" } as ErrorScreenType);
            return;
          }

          const componentes = state.map((item: any) => item.componente);
          const codigos = state.map((item: any) => item.codigo);
          let tempMenu = menu
            .map((item: any) => {
              if (!item.publico) {
                if (
                  item.component === "" &&
                  item.haschildrens &&
                  item.subitems.length > 0
                ) {
                  let tempsubitem = item.subitems
                    .map((subitem: any) => {
                      if (subitem.component === "Vacaciones") {
                        if (codigos.includes("EQI")) {
                          subitem.component = "AprobarVacaciones";
                          subitem.url = "/admin/aprobar-vacaciones";
                        } else {
                          subitem.component = "SolicitarVacaciones";
                          subitem.url = "/solicitar-vacaciones";
                        }
                      }
                      if (
                        subitem.component !== "" &&
                        componentes.includes(subitem.component)
                      ) {
                        return subitem;
                      }
                    })
                    .filter((item: any) => item !== undefined);
                  item.subitems = tempsubitem;
                  return item;
                }
                if (
                  item.component !== "" &&
                  componentes.includes(item.component)
                ) {
                  return item;
                }
              } else {
                return item;
              }
            })
            .filter((item: any) => item !== undefined);
          setEmployeeId(employeeId);
          setMenuItems(tempMenu);
          setFirstLoginData(loginData);
          setAppState(state);
          setEmplid(loginData.EMPLID);
        } else {
          setMenuItems(menu);
        }
      }
      // setPais('UY');
    };
    getAppState().then(() => {
      setFlagFirstLogin(true);
    });
  }, []);

  const getMaintance = async () => {
    await getMaintanceServices(setSomeError, setmessageMaintenance);
  };

  useEffect(() => {
    if (menuItems && !someLoading) {
      const publicUrls = menuItems.reduce((filtered: any, item: any) => {
        if (item.publico) {
          const filteredItem = item.url;
          filtered.push(filteredItem);
        }
        return filtered;
      }, []);
      if (!publicUrls.includes(location.pathname)) {
        setIsPublicUrl(false);
        Auth.currentAuthenticatedUser().catch(() => {
          signIn();
        });
      } else {
        setIsPublicUrl(true);
      }
    }
  }, [menuItems, someLoading, location.pathname]);

  useEffect(() => {
    (async () => {
      await getMaintance();
    })();
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/") signIn();
  }, [location]);

  useEffect(() => {
    scrollToTop();
  }, [location]);

  const signIn = async () => {
    try {
      await caches.keys().then((keys) => {
        keys.forEach((key) => {
          if (!key.match(/workbox/g)) {
            caches.delete(key);
          }
        });
      });

      await Auth.federatedSignIn({
        provider: config.AWS_COGNITO_IDP_NAME as CognitoHostedUIIdentityProvider
      });
    } catch (error) {
      console.error("Error during sign in:", error);
    }
  };

  const signOut = async () => {
    sessionStorage.clear();
    for (let key in localStorage) {
      if (
        key !== "bankList" &&
        key !== "DireccionCHL" &&
        key !== "RegionesCHL" &&
        key !== "ComunasCHL" &&
        !key.includes("CognitoIdentityServiceProvider") &&
        !key.includes("amplify")
      ) {
        localStorage.removeItem(key);
      }
    }
    await caches.keys().then((keys) => {
      keys.forEach((key) => {
        if (!key.match(/workbox/g)) {
          caches.delete(key);
        }
      });
    });
    await Auth.signOut().catch((err) => {
      console.error("Signout.signOut():Auth.signOut() err:", err);
      document.cookie
        .split(";")
        .forEach(
          (c) =>
          (document.cookie = c
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" + new Date().toUTCString() + ";path=/"
            ))
        );
      window.location.href = "/";
    });
  };

  const photoUser = () => {
    if (firstLoginData) {
      caches
        .match(urlsAws.getPhotosEquipo + firstLoginData?.EMPLID)
        .then((response) => {
          if (response) {
            response.json().then((data) => {
              if (data) {
                let newData = [data];
                setFoto(newData);
                let temp = firstLoginData;
                if (temp !== null) {
                  temp.PHOTO = data.photo;
                  setFirstLoginData(temp);
                }
              }
            });
          } else {
            miEquipoService.getPhotos(setFoto, [firstLoginData?.EMPLID]);
          }
        });
    }
  };

  useEffect(() => {
    if (pais === "CL") {
      if (firstLoginData && firstLoginData.NEWHIRING !== "Y") {
        photoUser();
      }
    }
  }, [pais, firstLoginData]);

  useEffect(() => {
    if (pais === "CL" && process.env.REACT_APP_CHILE_ENVIRONMENT === "PROD") {
      if (firstLoginData != null) {
        if (Object.entries(firstLoginData).length !== 0) {
          const dataLayer = {
            userid: cn,
            posicion: firstLoginData.POSITION_DESCR,
            unidad: firstLoginData.BUSUNIT_DESCR,
            centro_costo: firstLoginData.COSTCENTER_DESCR,
            plan_salarial: firstLoginData.SALARYPLAN_DESCR,
            empresa: firstLoginData.BUSUNIT_DESCR,
            ubicacion: firstLoginData.LOCATION_DESCR,
            departamento: firstLoginData.DEPARTMENT_DESCR,
            grado_salarial: firstLoginData.SALARYGRADE_DESCR,
            tipo_contrato: firstLoginData.CONTRACTTYPE_DESCR,
          };
          window.dataLayer.unshift({ ...dataLayer });
        }
      }
    }
  }, [firstLoginData, pais]);

  useEffect(() => {
    if (
      pais === "CL" &&
      process.env.REACT_APP_CHILE_ENVIRONMENT === "PROD" &&
      firstLoginData &&
      firstLoginData.NEWHIRING !== "Y"
    ) {
      if (
        bankList === null ||
        bankList === "error" ||
        bankList.includes("API rate limit exceeded")
      )
        fichaContratacionService.getMethods.getBankList(setBankList);
    }
  }, [bankList, pais, firstLoginData]);

  useEffect(() => {
    if (
      pais === "CL" &&
      process.env.REACT_APP_CHILE_ENVIRONMENT === "PROD" &&
      firstLoginData &&
      firstLoginData.NEWHIRING !== "Y"
    ) {
      if (
        DireccionCHL === null ||
        DireccionCHL === "error" ||
        DireccionCHL.includes("API rate limit exceeded")
      )
        listService.getFullDirecciones(setDireccionCHL);
    }
  }, [DireccionCHL, pais, firstLoginData]);

  useEffect(() => {
    if (
      pais === "CL" &&
      process.env.REACT_APP_CHILE_ENVIRONMENT === "PROD" &&
      location.pathname !== "/ficha-ingreso"
    ) {
      if (
        auditoria != false &&
        auditoria.rut != undefined &&
        auditoria.descripcion != undefined &&
        auditoria.user != undefined
      ) {
        moduloDtService.auditoriaDt(
          auditoria.rut,
          auditoria.user,
          auditoria.descripcion
        );
      }
    }
  }, [auditoria, pais]);

  useEffect(() => {
    if (pais === "CL" && process.env.REACT_APP_CHILE_ENVIRONMENT === "PROD") {
      if (firstLoginData && firstLoginData.EMPLID === "") {
        if (firstLoginData.NEWHIRING === "Y") {
          if (sessionStorage.getItem("ficha") !== null) {
            const sheet = sessionStorage.getItem("ficha");
            const savedSheet =
              typeof sheet === "string" ? JSON.parse(sheet) : null;
            setGetSheet(savedSheet as GetSheet);
            let tempArray = Array(savedSheet.numberOfPages).keys();
            let tempArray2 = Array.from(tempArray);
            tempArray2 = tempArray2.map((n) => n + 1);
            setListStep(tempArray2);
          } else {
            fichaContratacionService.getMethods.getFicha(
              setGetSheet,
              setListStep,
              setFichaErr
            );
          }
        } else if (firstLoginData.USERDT !== "") {
          navigate("/carpeta-digital-dt");
        } else {
          document.getElementById("btnModalNotUserInfo")?.click();
        }
      }
    }
  }, [firstLoginData, pais]);

  useEffect(() => {
    if (getSheet !== null && listStep.length) {
      navigate("/ficha-ingreso");
    }
    if (getSheet === null && (fichaErr === "COOM" || fichaErr === "WAIT")) {
      document.getElementById("openCompletedHiring")?.click();
    }
  }, [getSheet, listStep, fichaErr]);

  useEffect(() => {
    if (
      pais === "CL" &&
      firstLoginData &&
      firstLoginData.USERDT !== "" &&
      !location.pathname.includes("-dt")
    ) {
      navigate("/carpeta-digital-dt");
    }
  }, [firstLoginData, pais, location.pathname]);

  return (
    <Context.Provider
      value={{
        active,
        setActive,
        shrink,
        setShrink,
        employeeId,
        setEmployeeId,
        emplid,
        setEmplid,
        boss,
        step,
        setStep,
        listStep,
        setListStep,
        bankList,
        setBankList,
        signIn,
        signOut,
        DireccionCHL,
        setDireccionCHL,
        getSheet,
        setGetSheet,
        fichaErr,
        setFichaErr,
        group,
        allGroup,
        groupUser,
        allGroupUser,
        grupos,
        setGrupos,
        appState,
        setAppState,
        firstLoginData,
        setFirstLoginData,
        photoUser,
        auditoria,
        setAuditoria,
        cn,
        setCn,
        pais,
        setPais,
        menuItems,
        setMenuItems,
        someLoading,
        someError,
        setSomeError,
        foto,
        setFoto,
        isPublicUrl,
        setIsPublicUrl,
        showNewsletter,
        setShowNewsletter,
        showNewsletterButton,
        messageMaintenance,
        setShowDonations,
        showDonations,
        showDonationButton,
        userIsBoss
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
