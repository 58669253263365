import { CalendarItem } from "./CalendarItem"
import type { CalendarMonth, LunchDay } from "./hooks/LunchScheduleApi.types";

export type Props = {
    monthDays: CalendarMonth[];
    selectedMonth: string;
    selectedDays: {
        date: string;
        reservationPlace: string | null;
    }[];
    handleDelete: (id: number) => void;
    handleDayClick: (day: LunchDay) => void;
    setHoveredDate: React.Dispatch<React.SetStateAction<string | null>>
    hoveredDate: string | null;
}

const weekDaysData = [
    { short: "LUN", long: "LUNES" },
    { short: "MAR", long: "MARTES" },
    { short: "MIE", long: "MIÉRCOLES" },
    { short: "JUE", long: "JUEVES" },
    { short: "VIE", long: "VIERNES" },
    { short: "SAB", long: "SÁBADO" },
    { short: "DOM", long: "DOMINGO" },
];

export const CalendarContainer = (props: Props) => {
    const {
        monthDays,
        selectedMonth,
        selectedDays,
        handleDelete,
        handleDayClick,
        setHoveredDate,
        hoveredDate,
    } = props;

    return (
        <table style={{ tableLayout: "fixed", width: "100%" }}>
            <thead style={{ outline: "1px solid #0169b5" }}>
                <tr>
                    {weekDaysData.map((day) => (
                        <th
                            key={day.short}
                            className=" text-white text-center "
                            style={{
                                backgroundColor: "#0169b5",
                            }}
                        >
                            {day.long}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {monthDays
                    .find((month) => month.month === selectedMonth)
                    ?.monthDays.map((week, weekIdx) => (
                        <tr key={weekIdx}>
                            {week.map((day, dayIdx) => (
                                <CalendarItem
                                    key={day.day + dayIdx}
                                    day={day}
                                    selectedDays={selectedDays}
                                    handleDelete={handleDelete}
                                    handleDayClick={handleDayClick}
                                    setHoveredDate={setHoveredDate}
                                    hoveredDate={hoveredDate}
                                />
                            ))}
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}
