import { FaHourglassEnd } from "react-icons/fa";

export const StepTwo = () => {
  return (
    <>
      <h3 style={{ textAlign: "center" }}>
        La solicitud se procesará próximamente y recibirá un correo a su
        correo personal para que proceda con la firma.
        <FaHourglassEnd style={{ color: "orange" }} />
      </h3>
    </>
  );
};
