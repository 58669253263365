import React, { useContext } from "react";
import { httpClientEnv } from "../../../services/apiServices/httpClient";
import OnboardingManagerPage from "./onboardingManager/onboardingManager";

export const HttpClientContext = React.createContext(httpClientEnv);
export const useHttpClient = () => useContext(HttpClientContext);

export const OnboardingManager = () => {
    return (
        <HttpClientContext.Provider value={httpClientEnv}>
            <OnboardingManagerPage/>
        </HttpClientContext.Provider>
    )
};