import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect, Children } from "react";
import parseHTML from "html-react-parser";
import datosPersonalesService from "../../services/datos-personales-service";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { urlsAws } from "../../resources/foo/api-endpoints";
import { validaRut } from "../../services/utiles";

//HEADER DE LA MODAL
const ModalHeader = ({ close, section }) => {
    return (
        <div className="modal-header border-0 pb-0">
            <button type="button" id={"modal-close-" + section.sectionid} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => close()}></button>
        </div>
    );
};

//FOOTER DE LA MODAL
const ModalFooter = ({ action, terms, termsChecked, reqSwitchButton, reset, emptyFields, setEmptyFields }) => {
    const onLine = navigator.onLine
    return (
        <div className="modal-footer border-0 pt-0">
            <button
                disabled={reqSwitchButton ? true : false}
                type="button"
                className="btn btn-primary col-12 col-md-auto"
                data-bs-dismiss="modal"
                onClick={() => {
                    setEmptyFields(true);
                    setTimeout(() => {
                        reset();
                    }, 1000);
                }}
            >
                Cancelar
                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
            </button>
            <button disabled={onLine ? (action === "add" && emptyFields) ? true : (reqSwitchButton ? true : terms !== "" ? (termsChecked ? false : true) : false) : true} type="submit" className="btn btn-degradado col-12 col-md-auto">
                {action === "del" ? "Eliminar" : action === "add" ? "Crear" : action === "edit" ? "Guardar" : "Aceptar"}
                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
            </button>
        </div>
    );
};

//COMPONENTE DE MODAL TERMINOS Y CONDICIONES
const ModalTerms = ({ terms, setTermsChecked, section, setModalTerms }) => {
    return (
        <div className="modal fade" id="terminos-condiciones" tabIndex="-1" aria-labelledby="terminos-condiciones" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body py-4">{terms !== undefined ? parseHTML(terms) : null}</div>
                    <div className="modal-footer border-0 pt-0">
                        <a
                            href="#"
                            className="btn btn-degradado"
                            id="btn-terms-close"
                            data-bs-dismiss="#terminos-condiciones"
                            data-bs-toggle="modal"
                            data-bs-target="#terminos-condiciones"
                            onClick={() => {
                                setModalTerms(false);
                                setTermsChecked(true);
                                document.getElementById("modal" + section.sectionid).classList = "modal fade show";
                            }}
                        >
                            Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

//COMPONENTE DE CAMPOS DE LA MODAL
const Input = ({ direccion, register, errors, modalValues, form, onChange, setValue, getValues, onBlur }) => {
    let dirExp = null;

    if (form.formid === "PAIS_DIRECCION") {
        dirExp = 1;
        form.editable = true;
    } else if (form.formid === "REGION_DIRECCION") {
        dirExp = 1;
        if (getValues("PAIS_DIRECCION") !== "") form.editable = true;
        else form.editable = false;
    } else if (form.formid === "COMUNA_DIRECCION") {
        dirExp = 1;
        if (getValues("REGION_DIRECCION") !== "") {
            form.editable = true;
            let Comunas = JSON.parse(JSON.stringify(direccion));
            Comunas = Comunas !== null && Comunas !== undefined ? Comunas.response.regiones.find((region) => region.state === getValues("REGION_DIRECCION")) : undefined;
            if (Comunas !== undefined) {
                Comunas = Comunas.provincias.map((provincia) => {
                    return Array.isArray(provincia.comunas)
                        ? provincia.comunas.map((comuna) => {
                              return {
                                  value: comuna.city,
                                  label: comuna.nombre,
                                  visible: true,
                              };
                          })
                        : provincia.comunas;
                });
                Comunas = Comunas.flat();
                form.values = Comunas;
            }
        } else form.editable = false;
    }
    return (
        <>
            <div id={form.formid + "_div"} className="mb-3" key={form.formid + String(Math.random())}>
                {form.hidden !== undefined ? (
                    !form.hidden ? (
                        form.label !== undefined ? (
                            <label className="form-label mb-2">
                                <strong>{form.label}</strong>
                            </label>
                        ) : null
                    ) : null
                ) : null}
                {form.type === "dropdown" ? (
                    <select
                        id={form.formid + "input"}
                        className={"form-select form-control"}
                        hidden={form.hidden !== undefined ? form.hidden : false}
                        disabled={dirExp !== null ? !form.editable : modalValues.action === "edit" ? !form.editable : null}
                        {...register(form.formid, {
                            required: "* El campo " + form.label + " es obligatorio",
                            onChange: (e) => onChange(e),
                            onBlur: (e) => onBlur(e),
                        })}
                    >
                        <option value="">Seleccione una opción</option>
                        {form.values !== undefined
                            ? Children.toArray(form.values.map((option) => {
                                  return modalValues.action === "edit" && !form.editable ? (
                                      <option id={form.formid + option.label} value={option.value} visibility={String(option.visible)}>
                                          {option.label}
                                      </option>
                                  ) : /* condicion que consulta si existe la lista uniqueValuesAlreadyUsed */
                                  modalValues.uniqueValuesAlreadyUsed ? (
                                      modalValues.uniqueValuesAlreadyUsed[form.formid] ? (
                                          /* si existe la lista uniqueValuesAlreadyUsed verifica si el valor de la opcion esta usado */
                                          !modalValues.uniqueValuesAlreadyUsed[form.formid].includes(option.value) ? (
                                              <option id={form.formid + option.label} value={option.value} visibility={String(option.visible)}>
                                                  {option.label}
                                              </option>
                                          ) : null
                                      ) : (
                                          <option id={form.formid + option.label} value={option.value} visibility={String(option.visible)}>
                                              {option.label}
                                          </option>
                                      )
                                  ) : (
                                      <option id={form.formid + option.label} key={option.value} value={option.value} visibility={String(option.visible)}>
                                          {option.label}
                                      </option>
                                  );
                              }))
                            : null}
                    </select>
                ) : form.type === "textarea" ? (
                    <textarea
                        className="form-control"
                        type={form.type}
                        placeholder={form.placeholder}
                        disabled={modalValues.row !== false ? !form.editable : null}
                        maxLength={form.maxlength !== 0 ? form.maxlength : null}
                        hidden={form.hidden !== undefined ? form.hidden : false}
                        {...register(form.formid, {
                            required: "* El campo " + form.label + " es obligatorio",
                            onChange: (e) => onChange(e),
                            onBlur: (e) => onBlur(e),
                        })}
                    />
                ) : form.type === "checkbox" ? (
                    <input
                        id={form.formid + "input"}
                        className="form-check-input form-control"
                        type="checkbox"
                        hidden={form.hidden !== undefined ? form.hidden : false}
                        disabled={modalValues.action === "edit" ? !form.editable : modalValues.action === "add" ? !form.editable : null}
                        {...register(form.formid, {
                            required: form.optional ? null : form.formid === "CODIGO_FAMILIAR" ? null : "* El campo " + form.label + " es obligatorio",
                            onChange: (e) => {
                                if (e.target.checked) setValue(form.formid, true);
                                else setValue(form.formid, false);
                            },
                            onBlur: (e) => onBlur(e),
                        })}
                    />
                ) : (
                    <input
                        id={form.formid + "input"}
                        className={form.type === "checkbox" ? "form-check-input form-control" : "form-control"}
                        type={form.type === "date" ? "date" : form.type === "checkbox" ? "checkbox" : form.type === "number" ? "number" : form.tye === "email" ? "email" : "text"}
                        placeholder={form.placeholder}
                        onKeyDown={form.type === "date" ? (e) => e.preventDefault() : null}
                        maxLength={form.maxlength !== 0 ? form.maxlength : null}
                        hidden={form.hidden !== undefined ? form.hidden : false}
                        max={form.type === "date" ? new Date().toISOString().split("T")[0] : null}
                        disabled={modalValues.action === "edit" ? !form.editable : modalValues.action === "add" ? !form.editable : null}
                        {...register(form.formid, {
                            required: form.optional ? null : form.formid === "CODIGO_FAMILIAR" ? null : "* El campo " + form.label + " es obligatorio",
                            onChange: (e) => onChange(e),
                            onBlur: (e) => onBlur(e),
                            pattern:
                                form.type === "rut"
                                    ? {
                                          value: /^[0-9]{7,8}[-|‐]{1}[0-9kK]{1}$/,
                                          message: "* Formato de rut incorrecto (Ej: 1234567-8)",
                                      }
                                    : form.type === "phone"
                                    ? {
                                          value: /^[+]{1}[0-9]{11}$/,
                                          message: "* Formato de telefono incorrecto",
                                      }
                                    : form.type === "email"
                                    ? {
                                          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                          message: "* Formato de correo incorrecto",
                                      }
                                    : null,
                        })}
                    />
                )}
                {errors[form.formid] !== undefined ? (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                        {errors[form.formid].message}
                    </div>
                ) : null}
            </div>
            {form.information !== "" ? (
                <div key={`information ${form.formid}`}>
                    <p>{form.information}</p>
                </div>
            ) : null}
        </>
    );
};

//COMPONENTE DE LA MODAL
const Modal = ({ direccion, modalValues, setModalVal, section, setData }) => {
    const emplid = localStorage.getItem("emplid");
    const [modalTerms, setModalTerms] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [reqSwitchButton, setReqSwitchButton] = useState(false);
    const [emptyFields, setEmptyFields] = useState(true);
    const [success, setSuccess] = useState(false);
    const [requestMessage, setRequestMessage] = useState("");
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
        setError,
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: modalValues.modalObject,
    });
    const close = () => {
        setEmptyFields(true);
        if (success === 0 || success === 1) {
            setReqSwitchButton(false);
            setTermsChecked(false);
            setTimeout(() => {
                if (window.sessionStorage.getItem("carrusel") !== null) {
                    datosPersonalesService.datosPersonales(setData, window.sessionStorage.getItem("carrusel"), emplid);
                } else {
                    caches.open("api-cache").then((cache) => {
                        cache.match(urlsAws.firstLogin).then((response) => {
                            if (response) {
                                response.json().then((data) => {
                                    datosPersonalesService.datosPersonales(setData, data.PERSONALDATA[0], emplid);
                                });
                            }
                        });
                    });
                }
            }, 500);
        } else {
            setTimeout(() => {
                reset();
            }, 500);
        }
    };
    const onSubmit = (data) => {
        for (let key in data) {
            if (typeof data[key] === "string") {
                if (data[key].includes(" ")) {
                    data[key] = data[key].trim();
                    if (data[key] === "") {
                        for (let i = 0; i < modalValues.forms.length; i++) {
                            if (key === modalValues.forms[i].formid) {
                                if (modalValues.forms[i].optional === false) {
                                    setError(key, { type: "custom", message: "* El campo no puede contener solo espacios" });
                                    return;
                                } else {
                                    data[key] = "";
                                }
                            }
                        }
                    }
                }
            }
        }

        for (let key in data) {
            if (/^[0-9]{7,8}[-|‐]{1}[0-9kK]{1}$/.test(data[key])) {
                if (!validaRut(data[key])) {
                    setError(key, { type: "custom", message: "* Rut invalido" });
                    return;
                }
            }
        }
        for (let key in data) {
            for (let i = 0; i < modalValues.forms.length; i++) {
                if (modalValues.forms.formid === key) {
                    if (modalValues.forms.type === "checkbox") {
                        if (data[key] === "") data[key] = false;
                    } else {
                        if (data[key] === "") {
                            setError(key, { type: "custom", message: "* Error en formato de campo" });
                            return;
                        }
                    }
                }
            }
        }
        let rq = {},
            listrq = [],
            splitDosPuntos = [];
        for (const key in data) {
            for (let i = 0; i < modalValues.forms.length; i++) {
                if (modalValues.forms[i].formid === key) {
                    listrq.push([key, data[key].toString()]);
                }
            }
        }
        /* remover un elemento de una lista */
        if (modalValues.request !== undefined) {
            let splitComas = modalValues.request.split(",");
            for (let i = 0; i < splitComas.length; i++) splitDosPuntos.push(splitComas[i].split(":"));
            for (let i = 0; i < splitDosPuntos.length; i++) {
                if (splitDosPuntos[i][1][0] === "?") {
                    let splitPipeLine = splitDosPuntos[i][1].split("|");
                    let values = splitPipeLine[1].split(";"); /* [Y,N] */
                    for (let j = 0; j < listrq.length; j++) {
                        if (splitDosPuntos[i][1].includes(listrq[j][0])) {
                            if (listrq[j][1]) {
                                splitDosPuntos[i][1] = values[0];
                            } else {
                                splitDosPuntos[i][1] = values[1];
                            }
                        }
                    }
                    /* Y;N */
                } else if (splitDosPuntos[i][1][0] === "%") {
                    splitDosPuntos[i][1] = splitDosPuntos[i][1].replace("%", "");
                    let splitPuntoyComa = splitDosPuntos[i][1].split(";");
                    if (modalValues.action === "add") splitDosPuntos[i][1] = splitPuntoyComa[0];
                    if (modalValues.action === "edit") splitDosPuntos[i][1] = splitPuntoyComa[1];
                    if (modalValues.action === "del") splitDosPuntos[i][1] = splitPuntoyComa[2];
                } else {
                    for (let j = 0; j < listrq.length; j++) if (splitDosPuntos[i][1].includes(listrq[j][0])) splitDosPuntos[i][1] = listrq[j][1];
                }
            }
            for (let i = 0; i < splitDosPuntos.length; i++) {
                rq[splitDosPuntos[i][0]] = splitDosPuntos[i][1];
            }
            rq["emplid"] = emplid;
            /* hacer la request */
            setSuccess(false);
            setRequestMessage(false);
            setReqSwitchButton(true);
            datosPersonalesService.sendRequestModal(rq, modalValues.endpoint, setSuccess, setRequestMessage, close, section.sectionid);
        }
    };
    const handleChange = (e) => {
        //CONDICIONES PARA EL MANEJO DE CAMPOS DE DIRECCIONES
        if (e.target.name === "PAIS_DIRECCION") {
            if (e.target.value !== "") {
                document.getElementById("REGION_DIRECCIONinput").disabled = false;
            } else {
                setValue("REGION_DIRECCION", "");
                setValue("COMUNA_DIRECCION", "");
                document.getElementById("REGION_DIRECCIONinput").disabled = true;
                document.getElementById("COMUNA_DIRECCIONinput").disabled = true;
            }
        } else if (e.target.name === "REGION_DIRECCION") {
            if (e.target.value !== "") {
                document.getElementById("COMUNA_DIRECCIONinput").disabled = false;
                let Comunas = JSON.parse(JSON.stringify(direccion));
                Comunas = Comunas.response.regiones.find((region) => region.state === e.target.value);
                Comunas = Comunas.provincias.map((provincia) => {
                    return Array.isArray(provincia.comunas)
                        ? provincia.comunas.map((comuna) => {
                              return {
                                  value: comuna.city,
                                  label: comuna.nombre,
                                  visible: true,
                              };
                          })
                        : provincia.comunas;
                });
                Comunas = Comunas.flat();
                if (Array.isArray(Comunas)) {
                    Comunas.sort((a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    });
                }
                let select = document.getElementById("COMUNA_DIRECCIONinput");
                //eliminar todos los hijos del select
                while (select.firstChild) {
                    select.removeChild(select.firstChild);
                }
                //agregar los nuevos hijos
                for (let i = 0; i < Comunas.length; i++) {
                    let opt = document.createElement("option");
                    opt.value = Comunas[i].value;
                    opt.innerHTML = Comunas[i].label;
                    document.getElementById("COMUNA_DIRECCIONinput").appendChild(opt);
                    if (i === 0) {
                        opt.value = "";
                        opt.innerHTML = "Seleccione una opción";
                        document.getElementById("COMUNA_DIRECCIONinput").appendChild(opt);
                    }
                }
            } else {
                setValue("COMUNA_DIRECCION", "");
                setValue("PROVINCIA_DIRECCION", "");
                document.getElementById("COMUNA_DIRECCIONinput").value = "";
                if (document.getElementById("COMUNA_DIRECCIONinput")) document.getElementById("COMUNA_DIRECCIONinput").disabled = true;
            }
        } else if (e.target.name === "COMUNA_DIRECCION") {
            let provincia = "",
                region = "";
            if (e.target.value !== "") {
                let Comunas = JSON.parse(JSON.stringify(direccion));
                for (let i = 0; i < Comunas.response.regiones.length; i++) {
                    if (Comunas.response.regiones[i].state === document.getElementById("REGION_DIRECCIONinput").value) {
                        region = Comunas.response.regiones[i].state;
                    }
                    if (Array.isArray(Comunas.response.regiones[i].provincias)) {
                        for (let j = 0; j < Comunas.response.regiones[i].provincias.length; j++) {
                            for (let k = 0; k < Comunas.response.regiones[i].provincias[j].comunas.length; k++) {
                                if (Comunas.response.regiones[i].provincias[j].comunas[k].city === e.target.value) {
                                    provincia = Comunas.response.regiones[i].provincias[j].county;
                                }
                            }
                        }
                    } else {
                        for (let j = 0; j < Comunas.response.regiones[i].provincias.comunas.length; j++) {
                            if (Comunas.response.regiones[i].provincias.comunas[j].city === e.target.value) {
                                provincia = Comunas.response.regiones[i].provincias.county;
                            }
                        }
                    }
                }
                setValue("REGION_DIRECCION", region);
                setValue("COMUNA_DIRECCION", e.target.value);
                setValue("PROVINCIA_DIRECCION", provincia);
            } else {
                setValue("PROVINCIA_DIRECCION", "");
                setValue("COMUNA_DIRECCION", "");
            }
        }
        //CONDICIONES PARA EL MANEJO DE CAMPOS CONTACTO EMERGENCIA
        else if (e.target.name === "NOMBRE_EMERGENCIA" && modalValues.action === "add") {
            setValue("OLDNOM_EMERGENCIA", e.target.value);
        } else setValue(e.target.name, e.target.value);
    };
    const onBlur = () => {
        let temp = Object.values(getValues());
        temp =  temp.filter((value) => value === "");
        if (temp.length === 0) {
            setEmptyFields(false);
        }
        else if (temp.length === 1 && modalValues.sectionid === "FAMILIARES") {
            setEmptyFields(false);
        }
    }
    useEffect(() => {
        if (modalTerms) {
            const openmodal = () => {
                setTermsChecked(true);
                setModalTerms(false);
                document.getElementById("modal" + section.sectionid).classList = "modal fade show";
            };
            document.addEventListener("click", openmodal);
            return () => {
                document.removeEventListener("click", openmodal);
            };
        }
    }, [modalTerms]);

    //RESETEAR LOS COMPOS DE LA MODAL AL ABRIR OTRA MODAL
    useEffect(() => {
        reset(modalValues.modalObject);
    }, [modalValues]);

    return (
        <>
            <div className="modal fade" id={"modal" + section.sectionid} tabIndex="-1" aria-labelledby={"modal" + section.sectionid} aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit(onSubmit)} role="form" noValidate="novalidate" id={"form" + section.sectionid} className="needs-validation">
                            {modalValues ? (
                                <>
                                    <ModalHeader close={close} section={section} />
                                    <div className="modal-body">
                                        {modalValues.action === "del" ? (
                                            <>
                                                {modalValues.action === "del" ? null : <h2 className="h5 text-uppercase mb-4">Eliminar {modalValues.title}</h2>}
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                    <i className="fa fa-times-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                                                    <p className="h5">
                                                        <strong>¿Desea borrar este registro?</strong>
                                                    </p>
                                                    {modalValues.row
                                                        ? modalValues.row.map((r) => {
                                                              if (modalValues.action === "del" || modalValues.action === "edit") setValue(r.formid, r.value);
                                                              else setValue(r.formid, "");
                                                              return <input key={r.formid + String(Math.random())} type="hidden" id={r.formid} value={r.value} {...register(r.formid)} />;
                                                          })
                                                        : null}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <h2 className="h5 text-uppercase mb-4">{modalValues.title}</h2>
                                                {modalValues.forms !== undefined
                                                    ? Children.toArray(modalValues.forms.map((form) => {
                                                        return <Input direccion={direccion} register={register} errors={errors} modalValues={modalValues} onChange={handleChange} onBlur={onBlur} form={form} setValue={setValue} getValues={getValues} />;
                                                    }))
                                                    : null}

                                                {modalValues.terms !== "" ? (
                                                    <>
                                                        <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                                                            <Link
                                                                to=""
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setModalTerms(true);
                                                                    document.getElementById("modal" + section.sectionid).classList = "modal fade hide";
                                                                }}
                                                                className="text-decoration-none"
                                                                data-bs-dismiss={"modal" + section.sectionid}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#terminos-condiciones"
                                                            >
                                                                <i className="fa fa-file-text-o fa-fw" aria-hidden="true"></i>
                                                                {!termsChecked ? <label style={{ color: "red" }}>*</label> : null} Ver términos y condiciones
                                                            </Link>
                                                            <input type="checkbox" required={true} id="termsCheckBox" hidden checked={termsChecked} disabled={true} />
                                                        </div>
                                                    </>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                    <ModalFooter emptyFields={emptyFields} setEmptyFields={setEmptyFields} reset={reset} setModalVal={setModalVal} action={modalValues.action} terms={modalValues.terms} termsChecked={termsChecked} reqSwitchButton={reqSwitchButton} setReqSwitchButton={setReqSwitchButton} />
                                </>
                            ) : null}
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id={"modal-success-" + section.sectionid} tabIndex="-1" aria-labelledby={"modal-success-" + section.sectionid} aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button
                                type="button"
                                id="modal-close-success"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    close();
                                }}
                            ></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            {success == 1 && (
                                <>
                                    <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>
                                    <h2 className="h5">¡Éxito!</h2>
                                    <p>{requestMessage}</p>
                                </>
                            )}
                            {success == 0 && (
                                <>
                                    <i className="fa fa-times-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                                    <p>{requestMessage}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <a hidden id={"success-btn-" + section.sectionid} data-bs-toggle="modal" data-bs-target={"#modal-success-" + section.sectionid} />
            <ModalTerms terms={modalValues.terms} setTermsChecked={setTermsChecked} section={section} setModalTerms={setModalTerms} />
        </>
    );
};

//COMPONENTE DE CONFIGURACION INICIAL DE LA MODAL
const ModalComponent = ({ direccion, section, modalVal, setModalVal, setData }) => {
    let uniqueValues = {};
    let modalValues = {
        title: section.title,
        action: modalVal.action,
        forms: section.forms,
        row: 0,
        rowid: modalVal.rowid,
        request: section.request,
        endpoint: section.endpoint,
        uniqueValuesAlreadyUsed: uniqueValues,
        terms: section.terms,
        sectionid: section.sectionid,
        modalObject: {},
    };
    // condicion que en caso de que se este editando una fila, se obtiene la lista de valores ya usados cuando los valores son unicos
    if (section.forms !== undefined) {
        for (let i = 0; i < section.forms.length; i++) {
            if (section.forms[i].unique) {
                uniqueValues[section.forms[i].formid] = [];
                let fid = section.forms[i].formid;
                if (Array.isArray(section.rows)) {
                    for (let i = 0; i < section.rows.length; i++) {
                        if (section.rows[i] !== undefined) {
                            if (section.rows[i].items !== undefined) {
                                for (let j = 0; j < section.rows[i].items.length; j++) {
                                    if (section.rows[i].items[j].formid === fid) {
                                        uniqueValues[fid].push(section.rows[i].items[j].value);
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (section.rows && section.rows.items) {
                        for (let j = 0; j < section.rows.items.length; j++) {
                            if (section.rows.items[j].formid === fid) {
                                uniqueValues[fid].push(section.rows.items[j].value);
                            }
                        }
                    }
                }
            }
        }
    }
    //creacion del objeto modalObject que contiene los valores de los formularios
    if (modalVal.action === "add") {
        modalValues.forms.map((form) => {
            modalValues.modalObject[form.formid] = "";
        });
        modalValues.row = false;
    }
    if (section.rows) {
        //creacion del objeto modalObject que contiene los valores de los formularios
        if (modalVal.action === "edit" || modalVal.action === "del") {
            if (Array.isArray(section.rows)) {
                for (let i = 0; i < section.rows.length; i++) if (section.rows[i].row === modalVal.rowid) modalValues.row = section.rows[i].items;
            } else modalValues.row = section.rows.items;

            modalValues.row &&
                modalValues.forms.map((form) => {
                    for (let i = 0; i < modalValues.row.length; i++) {
                        if (modalValues.row[i].formid === form.formid) {
                            modalValues.modalObject[form.formid] = modalValues.row[i].value;
                        }
                    }
                });
        }
        //CONVIERTE LOS VALORES Y e N en true y false
        for (let i = 0; i < modalValues.row.length; i++) {
            if (modalValues.row[i].value === "Y" && modalValues.row[i].type === "checkbox") {
                modalValues.modalObject[modalValues.row[i].formid] = true;
            } else if (modalValues.row[i].value === "N" && modalValues.row[i].type === "checkbox") {
                modalValues.modalObject[modalValues.row[i].formid] = false;
            }
        }
    }
    //funcion para filtrar las comunas de acuerdo a la region seleccionada
    useEffect(() => {
        let region = null,
            comunas = null;
        if (modalValues.action === "edit" && section.sectionid === "DIRECCIONES") {
            for (let j = 0; j < modalValues.row.length; j++) {
                if (modalValues.row[j].formid === "REGION_DIRECCION") {
                    region = modalValues.row[j].value;
                }
            }
            let datos = JSON.parse(JSON.stringify(direccion));
            if (datos !== null && datos !== undefined) {
                for (let i = 0; i < datos.response.regiones.length; i++) {
                    if (datos.response.regiones[i].state === region) {
                        if (Array.isArray(datos.response.regiones[i].provincias)) {
                            comunas = datos.response.regiones[i].provincias.map((provincia) => {
                                return Array.isArray(provincia.comunas)
                                    ? provincia.comunas.map((comuna) => {
                                          return {
                                              value: comuna.city,
                                              label: comuna.nombre,
                                              visible: true,
                                          };
                                      })
                                    : [
                                          {
                                              value: provincia.comunas.city,
                                              label: provincia.comunas.nombre,
                                              visible: true,
                                          },
                                      ];
                            });
                        } else {
                            comunas = datos.response.regiones[i].provincias.comunas.map((comuna) => {
                                return {
                                    value: comuna.city,
                                    label: comuna.nombre,
                                    visible: true,
                                };
                            });
                        }
                    }
                }
                //unir las sublistas de comuna
                let comunasUnidas = [];
                if (Array.isArray(comunas)) {
                    for (let i = 0; i < comunas.length; i++) {
                        for (let j = 0; j < comunas[i].length; j++) {
                            comunasUnidas.push(comunas[i][j]);
                        }
                    }
                    comunas = JSON.parse(JSON.stringify(comunasUnidas));
                }
                for (let i = 0; i < modalValues.forms.length; i++) {
                    if (modalValues.forms[i].formid === "COMUNA_DIRECCION") {
                        modalValues.forms[i].values = comunas.sort((a, b) => {
                            if (a.label < b.label) {
                                return -1;
                            }
                            if (a.label > b.label) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                }
            }
        }
    }, []);
    return <Modal direccion={direccion} modalValues={modalValues} setModalVal={setModalVal} section={section} setData={setData} />;
};

ModalHeader.propTypes = {
    section: PropTypes.object,
    modalVal: PropTypes.object,
    setModalVal: PropTypes.func,
    setData: PropTypes.func,
    close: PropTypes.func,
};

ModalFooter.propTypes = {
    section: PropTypes.object,
    modalVal: PropTypes.object,
    setModalVal: PropTypes.func,
    setData: PropTypes.func,
    action: PropTypes.string,
    terms: PropTypes.bool,
    termsChecked: PropTypes.func,
    reqSwitchButton: PropTypes.bool,
    reset: PropTypes.func,
    emptyFields: PropTypes.bool,
    setEmptyFields: PropTypes.func,
    setOnLine: PropTypes.func,
};

ModalTerms.propTypes = {
    section: PropTypes.object,
    modalVal: PropTypes.object,
    setModalVal: PropTypes.func,
    setData: PropTypes.func,
    terms: PropTypes.bool,
    termsChecked: PropTypes.func,
    setModalTerms: PropTypes.func,
    setTermsChecked: PropTypes.func,
};

Input.propTypes = {
    form: PropTypes.object,
    modalValues: PropTypes.object,
    setModalValues: PropTypes.func,
    direccion: PropTypes.object,
    register: PropTypes.func,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    onBlur: PropTypes.func,
    
};

Modal.propTypes = {
    direccion: PropTypes.object,
    modalValues: PropTypes.object,
    setModalVal: PropTypes.func,
    section: PropTypes.object,
    setData: PropTypes.func,
};

ModalComponent.propTypes = {
    direccion: PropTypes.object,
    section: PropTypes.object,
    modalVal: PropTypes.object,
    setModalVal: PropTypes.func,
    setData: PropTypes.func,
};

export default ModalComponent;