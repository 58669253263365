import React, { useState, useEffect, FormEvent, FunctionComponent } from "react";
import { Props } from "../../utilities/interfaces";
import { useForm, SubmitHandler } from "react-hook-form";
import ImageModal from "../../commons/plain/modals/image";
import Preview from "./preview";
import Upload from "../../commons/modals/upload";
import OfflineModal from "../../commons/plain/modals/offline";
import { scrollToTop } from "../../../../services/utiles";

interface Props1 extends Props {
    prevStep(): void;
    photoData: string;
    setPhotoData: React.Dispatch<React.SetStateAction<string>>;
}

interface HiddenPhoto {
    hiddenPhoto: string;
}

const permitedTypes = ["png", "jpg", "jpeg"];

const FifthStep: FunctionComponent<Props1> = ({ options, nextStep, prevStep, photoData, setPhotoData }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<HiddenPhoto>();

    const hiddenPhoto = watch("hiddenPhoto");
    const [photo, setPhoto] = useState(sessionStorage.getItem("photo"));
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (photo !== null) {
            setValue("hiddenPhoto", photo);
        }
    }, [photo]);

    const formSubmitHandler: SubmitHandler<HiddenPhoto> = (data: HiddenPhoto) => {
        if (navigator.onLine) {
            setDisableButton(true)
            setPhotoData(data.hiddenPhoto);
            sessionStorage.setItem("photo", data.hiddenPhoto);
            nextStep();
        } else {
            document.getElementById("offline")?.click();
        }
    };

    useEffect(() => {
        return () => setDisableButton(false);
    }, []);

    return (
        <>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(formSubmitHandler)}
            >
                <div id={"fotoCredencial"} className="box mb-4">
                    <h2 className="h4 tit-section" children={"Foto Credencial"} />
                    <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
                        <ul className="list-column-1 list-unstyled">
                            <li>
                                <span className="data-list-box__title">{"Foto Credencial*"}</span>
                                <span className="data-list-box__text" key={"addFoto"}>
                                    {"La foto debe ser formato tipo carné con fondo blanco, tamaño maximo 1MB"}
                                    <a href="/fichacontratacion" data-bs-toggle="modal" data-bs-target="#info-foto-credencial">
                                        <i className="fa fa-question-circle-o fa-fw mt-2 fa-lg color-primary" aria-hidden="true" />
                                    </a>
                                </span>
                                <ImageModal />
                            </li>
                            <li>
                                <span className="data-list-box__title">{"Tipo de documento"}</span>
                                <span className="data-list-box__text">
                                    <button type="button" className={window.innerWidth > 340 ? "btn btn-degradado px-5" : "btn btn-degradado mb-2"} data-bs-toggle="modal" data-bs-target="#adjuntar-foto-credencial" children={"Adjuntar"} />
                                </span>
                                {errors["hiddenPhoto"] &&
                                    <div className="invalid-feedback d-block">
                                        {"Debe adjuntar una imagen con extensión jpeg, jpg o png que no supere los 1MB"}
                                    </div>}
                                <Upload 
                                    setPhoto={setPhoto} 
                                    fileName={"foto credencial"} 
                                    modalId={"adjuntar-foto-credencial"} 
                                    permitedTypes={permitedTypes} 
                                    target="hiddenPhoto"
                                    targetValue={hiddenPhoto} 
                                    setValues={() => {}}
                                    setDocumentItems={() => {}}
                                    setError={() => {}}
                                />
                                <input type="hidden" { ...register("hiddenPhoto", {
                                    required: "Debe adjuntar una imagen con extensión jpeg, jpg o png que no supere los 1MB"
                                })} />
                            </li>
                        </ul>
                        {photo !== null && photo !== "" ?
                            <Preview photo={photo} setPhoto={setPhoto} />
                        : null}
                    </div>
                </div>
                <div className="d-grid d-md-flex justify-content-md-between gap-2">
                    <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); prevStep() }}>
                        <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                        {"Anterior"}
                    </button>
                    <button type="submit" className="btn btn-degradado" disabled={disableButton}>
                        {"Siguiente"}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </form>
            <OfflineModal />
        </>
    );
};

export default FifthStep;