import React, { Children, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Upload from "../../../commons/modals/upload";
import { DocumentData, DocumentItem, PensionData } from "../../../utilities/interfaces";
import { FieldErrorsImpl, UseFormRegister, UseFormSetError, UseFormSetValue, UseFormWatch } from "react-hook-form";
import AlertInfo from "../../../../commonComponents/AlertInfo";

interface Health {
    health?: string;
}

interface Props {
    documentItems: Array<DocumentItem | undefined>;
    setDocumentItems: React.Dispatch<React.SetStateAction<Array<DocumentItem | undefined>>>;
    setValues: UseFormSetValue<DocumentData | PensionData | Health>;
    register: UseFormRegister<DocumentData | PensionData | Health>;
    errors: Partial<FieldErrorsImpl>;
    watch: UseFormWatch<DocumentData | PensionData | Health>;
    isTotalSizeTooLarge?: boolean;
    setError: UseFormSetError<DocumentData | PensionData | Health>;
}

const DocumentList: FunctionComponent<Props> = ({ isTotalSizeTooLarge, documentItems, setDocumentItems, setValues, register, errors, watch, setError }) => {
  return (
    <div className="box mb-4">
        <h2 className="h4 tit-section" children={"Documentos"} />
        <AlertInfo text="Por favor, subir documentacion requerida legible, tamaño maximo 1MB por documento." color="alert-primary" />
        <div className="box-description mb-3 color-primary">{"Debe ingresar los siguientes documentos:"}</div>
        <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
            <ul className="list-column-1 list-unstyled">
                {Children.toArray(
                    documentItems.map((doc, index) => {
                        if (doc !== undefined) {
                            const targetValue = watch(doc.target);
                            return (
                                <>
                                    <li>
                                        {doc.name.length > 20 ?
                                            <span className="data-list-box__title" key={index}>
                                                {doc.name.substring(0, 20)}
                                                <br className="d-none d-md-inline-block" />
                                                {doc.name.substring(20, doc.name.length)}
                                                {"*"}
                                            </span>
                                        :
                                            <span className="data-list-box__title" key={index}>
                                                {doc.name + "*"}
                                            </span>}
                                        <span className="data-list-box__text">
                                            <button disabled={isTotalSizeTooLarge} type="button" className="btn btn-degradado px-5" data-bs-toggle="modal" data-bs-target={"#" + doc.modalId}>
                                                Adjuntar
                                            </button>
                                            {doc.value !== "" ? (
                                                <>
                                                    <i className="fa fa-check fa-fw mt-2 ms-3 fa-lg color-primary" aria-hidden="true" />
                                                </>
                                            ) : null}
                                        </span>
                                        {errors[doc.target] &&
                                            <div className="invalid-feedback d-block">
                                                {`Debe adjuntar ${doc.name} en formato "jpg", "jpeg", "png" o "pdf" sin superar los 1MB`}
                                            </div>}
                                        {doc.value !== "" ? (
                                            <div className="mt-3">
                                                <Link
                                                    to=""
                                                    className="mt-2"
                                                    onClick={(e) => {e.preventDefault(); setValues(doc.target, ""); setDocumentItems((prevState) => {
                                                        return prevState.map((d) => {
                                                            if (d !== undefined) {
                                                                if (d.target === doc.target) {
                                                                    return {
                                                                        ...d,
                                                                        value: "",
                                                                    };
                                                                } else {
                                                                    return { ... d };
                                                                }
                                                            }
                                                        });
                                                    })}}
                                                    style={{
                                                        pointerEvents: window.navigator.onLine ? "auto" : "none",
                                                    }}
                                                    children={
                                                        <>
                                                            <i className="fa fa-trash-o ms-3 fa-lg color-primary" aria-hidden="true" />
                                                        </>
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                    </li>
                                    <input type="hidden" {...register(doc.target, {
                                        required: `Debe adjuntar ${doc.name} en formato "jpg", "jpeg", "png" o "pdf"`
                                    })} />
                                    <Upload fileName={doc.name} modalId={doc.modalId} permitedTypes={["jpg", "jpeg", "png", "pdf"]} target={doc.target} setValues={setValues} setDocumentItems={setDocumentItems} targetValue={targetValue as string} setError={setError} />
                                </>
                            );
                        }
                    })
                )}
            </ul>
        </div>
        { isTotalSizeTooLarge &&
            <div className="invalid-feedback d-block">
               *La suma de los archivos adjuntos no debe superar los 1MB
            </div>
        }
    </div>
  )
}

export default DocumentList;