import { FormProps } from "../../interfaces/interfaces";
import React, { Children, FC, useEffect, useState } from "react";
import RadioInput from "../../../Commons/Inputs/radioInput";
import SelectInput from "../../../Commons/Inputs/selectInput";
import TextInput from "../../../Commons/Inputs/textInput";
import TextAreaInput from "../../../Commons/Inputs/textAreaInput";
import FilesRemoteJob from "../../../Commons/Inputs/fileInput";

const FormRemoteJob:FC<FormProps> = ({form, formState, control,  handleDropdownChange, setTotalFiles, totalFiles, getValues, fileInputRefs }) => {
    const fileSizeLimit = 10 * 1024 * 1024; // 10 MB
    const [totalSize, setTotalSize] = useState(0);

    useEffect(() => {
        if (totalFiles) {
          const valuesArray = Object.values(totalFiles);
          if (valuesArray.length > 0) {
            const total = valuesArray.reduce((acc, fileSize) => acc + Number(fileSize), 0);
            setTotalSize(total);
          }
        }
      }, [totalFiles]);

    return(
        <>
            {Children.toArray(form.map((input, i) => {
                let required = input.validations.some((valid) => valid.type === 'required');
                return(
                    <>
                        <li style={{backgroundColor: i % 2 === 0 ? '#f2f2f2' : 'white'}}>
                            <span className={"data-list-box__title text-uppercase w-sm-30"}>{input.label} {required ? '*' : ''} 
                            {input.tooltip && <i
                                className="fa fa-info-circle fa-fw fa-lg color-primary mb-3 ms-2"
                                aria-hidden="true"
                                title={input.tooltip}
                                data-placement="top"
                                ></i>
                            }</span>
                            
                            {input.type === 'text' || input.type === 'email' || input.type === 'number' || input.type === 'date' ? (
                               <TextInput control={control} formState={formState} input={input}/>
                            ): input.type === 'textarea' ?(
                                <TextAreaInput control={control} formState={formState} input={input}/>
                            ): input.type === 'select' ? (
                                <SelectInput control={control} formState={formState} input={input} handleFunction={handleDropdownChange} getValues={getValues}/>
                            ): input.type === 'radio' && input.options.length > 0 ?
                                <RadioInput control={control} formState={formState} input={input}/>
                            : input.type === 'file' && <FilesRemoteJob control={control} formState={formState} input={input} setTotalFiles={setTotalFiles} totalFiles={totalFiles} fileInputRef={fileInputRefs}/>}
                        </li>
                        {i === form.length - 1 && input.type === 'file' && 
                            <>
                                {totalSize > fileSizeLimit && (
                                    <p className="link-danger h6 mt-2"> Se ha excedido el límite de peso de 10 MB.</p>
                                )}
                            </>
                        }
                    </>
                )
            }))}
        </>
    )
}
export default FormRemoteJob;