import { useState, useEffect } from "react";
import { OnboardingUserProps } from "../../utilities/interfaces";
import { getOnboardingByUser } from "../../utilities/apiRequest";
import { toggleModal } from "../../../commonComponents/Modal";
import ModalOnboardingContent from "./modalContent";

const ModalOnboarding = () => {
    const [onboardingUser, setOnboardingUser] = useState<OnboardingUserProps[]>([]);

    useEffect(() => {
        const getData = async () => {
            if(!localStorage.getItem("onboardingLoginFlag")){
                const data = await getOnboardingByUser();
                setOnboardingUser(data);
                localStorage.setItem("onboardingLoginFlag", "true");
            }
        };
        getData();
    }, []);

    useEffect(() => {
        if (onboardingUser.length > 0) {
            toggleModal({
                variant: 'custom',
                content: <ModalOnboardingContent onboarding={onboardingUser} />,
            });
        }
    }, [onboardingUser]);

    return null;
};

export default ModalOnboarding;
