import { InsuranceContent } from "./InsuranceContent";
import { InsuranceProvider } from "./context/InsuranceContext";

export const InsurancePage = () => {
  return (
    <InsuranceProvider>
  <InsuranceContent />
  </InsuranceProvider>
  )
};
