import React, { Children, FC, useEffect, useRef, useState } from "react";
import { convertNumber, formatDate } from "../../../../../../../services/utiles";
import { getExcelResumen } from "../../../../../utilities/apiServices/apiRequest";
import { NewsTable, TableProps } from "../../../../../utilities/interfaces";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/Array";
import ReactPaginate from "react-paginate";
import ModalLoad from "../../../../../../commonComponents/ModalLoad";

const TableIndex: FC<TableProps> = ({
    newsList,
    filterStatus,
    filterCategory,
    endDateFilter,
    filterCountry,
    startDateFilter,
    refConfirmAction,
    refStatus,
    setIdNews,
    setShowTable,
    setIsEditNews,
    setDataModal,
    refLoad,
    refLoadClose,
    refCloseStatus
}) => {
    const [filterNewsList, setFilterNewsList] = useState<NewsTable[]>([]);
    const [errorDownload, setErrorDownload] = useState(-1);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageItems, setPageItems] = useState<NewsTable[][]>([]);

    const handlePageClick = (selectedItem: { selected: number }) => {
        setCurrentPage(selectedItem.selected);
    };

    const HandleDownloadExcel = (idNews: number) => {
        refLoad && refLoad.current && refLoad.current.click();
        getExcelResumen(idNews, setErrorDownload, refLoadClose);
    }

    let headers = [
        {
            "label": "ID",
            "value": "id"
        },
        {
            "label": "País",
            "value": "country"
        },
        {
            "label": "Vigencia",
            "value": "vigency"
        },
        {
            "label": "Título",
            "value": "title"
        },
        {
            "label": "Autor",
            "value": "autor"
        },
        {
            "label": "Categoría",
            "value": "category"
        },
        {
            "label": "Estado",
            "value": "status"
        },
    ]

    useEffect(() => {
        if (newsList) {
            let filters = newsList.newsList.filter((news) => {
                const fechaHasta = new Date(news.date_to);
                const fechaDesde = new Date(news.date_from);

                const country = filterCountry ? filterCountry === news.category.country.id : true;
                const dateStart = startDateFilter ? fechaHasta >= new Date(startDateFilter) : true;
                const dateEnd = endDateFilter ? fechaDesde <= new Date(endDateFilter) : true;
                const category = filterCategory ? Number(filterCategory) === news.category.id : true;
                const status = filterStatus ? filterStatus === news.status : true;
                const validDate = startDateFilter && endDateFilter ? new Date(startDateFilter) <= new Date(endDateFilter) : true

                return country && dateStart && dateEnd && category && status && validDate;
            })
            setFilterNewsList(filters)
        }
    }, [newsList, endDateFilter, filterCountry, startDateFilter, filterCategory, filterStatus])


    useEffect(() => {
        if (filterNewsList.length > 0) {
            setCurrentPage(0);
            setPageItems(pipe(
                filterNewsList.filter((r) => r.id), A.chunksOf(10)
            ))
        }
    }, [filterNewsList])

    useEffect(() => {
        if (errorDownload === 1) {
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            setDataModal({
                msg: 'Ha ocurrido un error al obtener la información, por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                }
            })
            setTimeout(() => {
                refStatus && refStatus.current && refStatus.current.click();
                setErrorDownload(-1);
            }, 500)
        }
    }, [errorDownload])

    return (
        <>
            <div className="table-responsive">
                <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                    <thead>
                        <tr>
                            {Children.toArray(headers.map((header, i) => {
                                return (
                                    <>
                                        <td>{header.label}</td>
                                    </>
                                )
                            }))}
                            <td className="text-center">Acciones</td>
                        </tr>
                    </thead>
                    <tbody>
                        {filterNewsList.length > 0 && pageItems && pageItems.length > 0 ?
                            Children.toArray(pageItems[currentPage].map((news, i) => {
                                const titleGroup = news.news_group.map((group) => group.title).join("\n");
                                return (
                                    <>
                                        <tr>
                                            <td>{news.id}</td>
                                            <td>{news.category.country.description}</td>
                                            <td>{formatDate(news.date_from)} al {formatDate(news.date_to)} </td>
                                            <td>{news.title}</td>
                                            <td>{news.created_by}</td>
                                            <td>{news.category.title}</td>
                                            <td>{news.status === 'published' ? 'Publicado' : news.status === 'unpublished' ? 'Despublicado' : 'Borrador'}</td>
                                            <td className="text-nowrap">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span className="me-2"><i className="fa fa-users color-orange fa-fw" title={titleGroup} aria-hidden="true"></i> {news.news_group.length}</span>
                                                    <span className="me-2"><i className="fa fa-eye color-orange fa-fw" aria-hidden="true" title="Vistos"></i> {convertNumber(news.total_views)}</span>
                                                    <span className="me-2"><i className="fa fa-heart-o color-orange fa-fw" aria-hidden="true" title="Likes"></i> {convertNumber(news.total_likes || 0)}</span>
                                                    <span className="me-2"><i className="fa fa-comment-o color-orange fa-fw" aria-hidden="true" title="Comentarios"></i> {convertNumber(news.total_comments || 0)}</span>
                                                    <div>
                                                        <button className="btn btn-sm btn-news-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v fa-fw color-dark" aria-hidden="true"></i></button>
                                                        <ul className="dropdown-menu border-orange dropdown-menu-hover-orange">
                                                            <li><a className="dropdown-item" onClick={() => {
                                                                HandleDownloadExcel(news.id);
                                                            }}><i className="fa fa-file-text fa-fw" aria-hidden="true"></i> Descargar</a></li>
                                                            <li><a className="dropdown-item" onClick={() => {
                                                                setIdNews(news.id)
                                                                setIsEditNews(true)
                                                                setShowTable(false);
                                                            }}><i className="fa fa-pencil fa-fw" aria-hidden="true"></i> Editar</a></li>
                                                            <li><a className="dropdown-item" onClick={() => {
                                                                setIdNews(news.id)
                                                                refConfirmAction && refConfirmAction.current && refConfirmAction.current.click();
                                                            }}><i className="fa fa-trash fa-fw" aria-hidden="true"></i> Despublicar </a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            }))
                            :
                            <>
                                <tr>
                                    <td>
                                        <p>No se ha encontrado información.</p>
                                    </td>
                                    {Children.toArray(headers.map((a, b) => {
                                        return (
                                            <>
                                                <td></td>
                                            </>
                                        )
                                    }))}
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
                <div className="accordion accordion-flush accordion-light accordion-table d-block d-md-none">
                    {filterNewsList.length > 0 && pageItems && pageItems.length > 0 ?
                        Children.toArray(pageItems[currentPage].map((news, i) => {
                            const titleGroup = news.news_group.map((group) => group.title).join("\n");
                            return (
                                <>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"acordeon-" + i}>
                                            <button className={i === 0 ? "accordion-button " : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-" + i} aria-expanded={i === 0 ? "true" : "false"} aria-controls={"accordion-" + i}>
                                                <strong>País</strong> {news.category.country.description}
                                            </button>
                                        </h2>
                                        <div id={"accordion-" + i} className={i === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"accordion-" + i}>
                                            <div className="accordion-body p-0">
                                                <div className="data-list-box">
                                                    <ul className="data-list-section--item list-unstyled">
                                                        <li>
                                                            <span className="data-list-box__title">ID</span>
                                                            <span className="data-list-box__text">{news.id}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Vigencia</span>
                                                            <span className="data-list-box__text">{formatDate(news.date_from)} al {formatDate(news.date_to)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Título</span>
                                                            <span className="data-list-box__text">{news.title}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Autor</span>
                                                            <span className="data-list-box__text">{news.created_by}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Categoría</span>
                                                            <span className="data-list-box__text">{news.category.title}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Estado</span>
                                                            <span className="data-list-box__text">{news.status === 'published' ? 'Publicado' : news.status === 'unpublished' ? 'Despublicado' : 'Borrador'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Acciones</span>
                                                            <span className="data-list-box__text">
                                                                <div className="d-flex align-items-center">
                                                                    <span className="me-auto"><i className="fa fa-users color-orange fa-fw fa-lg" aria-hidden="true" title={titleGroup}></i>{news.news_group.length}</span>
                                                                    <span className="me-auto "><i className="fa fa-eye color-orange fa-fw fa-lg" aria-hidden="true"></i>{convertNumber(news.total_views)}</span>
                                                                    <span className="me-auto"><i className="fa fa-heart-o color-orange fa-fw fa-lg" aria-hidden="true"></i>{convertNumber(news.total_likes || 0)}</span>
                                                                    <span className="me-auto"><i className="fa fa-comment-o color-orange fa-fw fa-lg" aria-hidden="true"></i>{convertNumber(news.total_comments || 0)}</span>
                                                                    <a className="btn btn-sm btn-news-3" onClick={() => {
                                                                        HandleDownloadExcel(news.id);
                                                                    }}><i className="fa fa-file-text color-orange fa-fw fa-lg" aria-hidden="true"></i></a>
                                                                    <a className="btn btn-sm btn-news-3" onClick={() => {
                                                                        setIdNews(news.id);
                                                                        setIsEditNews(true);
                                                                        setShowTable(false);
                                                                    }}><i className="fa fa-pencil color-orange fa-fw fa-lg" aria-hidden="true"></i></a>
                                                                    <a className="btn btn-sm btn-news-3" onClick={() => {
                                                                        setIdNews(news.id);
                                                                        refConfirmAction && refConfirmAction.current && refConfirmAction.current.click();
                                                                    }}><i className="fa fa-trash color-orange fa-fw fa-lg" aria-hidden="true"></i></a>
                                                                </div>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }))
                        :
                        <>
                            <div className="text-center">
                                <p className="">No se ha encontrado información.</p>
                            </div>
                        </>
                    }
                </div>
            </div>
            {pageItems !== undefined && pageItems.length > 0 ? (
                <nav className="mt-5" aria-label="Paginación">
                    <ReactPaginate
                        className="pagination justify-content-center align-items-center"
                        breakLabel=" ... "
                        nextLabel=" > "
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={0}
                        marginPagesDisplayed={1}
                        pageCount={pageItems.length}
                        previousLabel=" < "
                        renderOnZeroPageCount={() => null}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        breakLinkClassName={"page-link"}
                        pageLinkClassName={"page-link"}
                        pageClassName={"page-item"}
                        breakClassName={"page-item"}
                        previousClassName={"page-item"}
                        nextClassName={"page-item"}
                        activeClassName={"page-item active"}
                        activeLinkClassName={"page-link active"}
                    />
                </nav>
            ) : null}

            <ModalLoad text="Descargando reporte..." refOpen={refLoad} refClose={refLoadClose} data_target="modalLoadReportID" />
        </>
    )
}
export default TableIndex;