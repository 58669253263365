import React, { FC, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { urlsAws } from "../../resources/foo/api-endpoints";
import { toggleModal } from "../commonComponents/Modal";
import SurveyForm from "./components/SurveyForm";
import useApiForm from "./hooks/useApiForm";
import logout from "../elements/logout";
import Header from "../elements/header";
import { SurveyFormValues } from "./interfaces";

const SurveyPublic: FC = () => {
  const { search } = useLocation();
  const methods = useForm<SurveyFormValues>();

  const token = useMemo(() => {
    if (!search) return null;
    const tokenParam = search.split("Authorization=")[1];
    return tokenParam ? { type: 'token', value: tokenParam } : null;
  }, []); 

  const url = useMemo(() => {
    if (!token) return "";
    return `${urlsAws.survey}?${token.type}=${token.value}`;
  }, []); 

  const {
    initialData,
    initialError,
    submitData,
    submitError,
    loading,
    handleSubmit: handleSubmitCallback,
  } = useApiForm({ url, session: undefined });

  useEffect(() => {
    if (loading) {
      toggleModal({ variant: 'loading' });
    } else {
      toggleModal();
    }
  }, [loading]);

  useEffect(() => {
    if (initialError || submitData || submitError) {
      toggleModal({
        variant: 'status',
        status: initialError || submitError ? 'error' : 'ok',
        text: initialError || submitError || 
          "Hemos recibido satisfactoriamente tus respuestas. Recuerda que son confidenciales y para uso exclusivo de RRHH. Gracias por ayudarnos a mejorar la experiencia de nuestr@s colaboradores.",
        onAccept: () => {
          if (initialError || submitData) {
            logout();
          }
        }
      });
    }
  }, [initialError, submitError, submitData]);

  return (
    <>
      <Header />
      <main id="main" className="wrapper no-menu mt-5">
        <div id="content" className="container">
          {initialData && (
            <SurveyForm
              survey={initialData}
              handleSubmitCallback={handleSubmitCallback}
              methods={methods}
            />
          )}
        </div>
      </main>
    </>
  );
};

export default SurveyPublic; 