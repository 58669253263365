import { useEffect } from "react";
import { useAxiosFetch } from "../../../hooks";
import { LunchScheduleApi, PlacesToReserveApi } from "./LunchScheduleApi.types";

export const useLunchScheduleFetch = () => {
  const [fetchLunchSchedule, dataLunchSchedule] =
    useAxiosFetch<LunchScheduleApi>({
      url: `/api/lunch-schedule`,
      method: "GET",
    });

  const [getPlacesToReserve, dataPlacesToReserve] = useAxiosFetch<
    PlacesToReserveApi[]
  >({
    url: `/api/lunch-schedule/places-to-reserve`,
    method: "GET",
  });

  const [deleteReserve, dataDeleteReserve] = useAxiosFetch({
    url: `/api/lunch-schedule/delete-reserve`,
    method: "DELETE",
  });

  const [postReserve, dataReserve] = useAxiosFetch({
    url: `/api/lunch-schedule/reserve`,
    method: "POST",
  });

  const [updateReserve, dataUpdateReserve] = useAxiosFetch({
    url: `/api/lunch-schedule/update-reserve`,
    method: "PATCH",
  });

  useEffect(() => {
    fetchLunchSchedule();
    getPlacesToReserve();
  }, []);

  const handleDeleteReserve = async (id: number) => {
    try {
      await deleteReserve({ params: { id } });
      await fetchLunchSchedule();
    } catch (err) {
      console.error("Error al eliminar la reserva:", err);
    }
  };

  const handleReserve = async (
    reservations: { date: string; reservationPlace: string }[]
  ) => {
    try {
      await postReserve({ data: reservations });
      await fetchLunchSchedule();
    } catch (err) {
      console.error("Error al realizar la reserva:", err);
    }
  };

  const handleUpdateReserve = async (updatedData: {
    date?: string;
    reservationPlace?: string;
    id?: number;
  }) => {
    try {
      await updateReserve({
        url: `/api/lunch-schedule/update-reserve/${updatedData.id}`,

        data: updatedData,
      });

      await fetchLunchSchedule();
    } catch (err) {
      console.error("Error al actualizar la reserva:", err);
    }
  };

  const lunchScheduleMapper = () => {
    return {
      countryCode: dataLunchSchedule.data?.countryCode,
      employeeNumber: dataLunchSchedule.data?.employeeNumber,
      calendarData:
        dataLunchSchedule.data?.calendarData.map((month, index) => ({
          ...month,
          active: index === 0,
          monthDays: month.monthDays.map((dayGroup) =>
            dayGroup.map((day) => ({
              ...day,
            }))
          ),
        })) || [],
    };
  };

  const placesToReserveMapper = () => {
    if (dataPlacesToReserve.data)
      return dataPlacesToReserve.data.map((place) => ({
        id: place.id,
        placeName: place.placeName,
      }));
    return [];
  };

  const getMonths = (): { id: string; month: string }[] => {
    return (
      lunchScheduleMapper().calendarData?.map((month) => ({
        id: month.id,
        month: month.month,
      })) || []
    );
  };

  return {
    dataLunchSchedule: lunchScheduleMapper(),
    dataPlacesToReserve: placesToReserveMapper(),
    handleDeleteReserve,
    getMonths,
    fetchLunchSchedule,
    handleUpdateReserve,
    handleReserve,
    isLoading:
      dataLunchSchedule.isLoading ||
      dataPlacesToReserve.isLoading ||
      dataDeleteReserve.isLoading ||
      dataReserve.isLoading,
    status: dataReserve.status || dataDeleteReserve.status,
  } as const;
};
