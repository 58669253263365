import react from 'react'
import Auth from "@aws-amplify/auth";
import LoginService from "../../services/login/login-service"

const getUserAttributes = async () => {
    const data = await Auth.currentUserInfo()
        .then((user) => {
            if (user) {
                return {
                    pais: user.attributes["custom:cd_pais"],
                    documento: user.attributes["custom:emplid"],
                    legajo: user.attributes["profile"]
                };
            }
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
    return data
}

const validateField = (field, validData, isDocumento = false) => {
    if (!field) return { descripcion: "fail", data: false, atribute: field }; // Sin dato
    if (field === validData) return { descripcion: "success", data: true, atribute: field }; // Dato correcto

    if (isDocumento) {
        if (validData === 200) return { descripcion: "success", data: true, atribute: field };
        return { descripcion: "data error", data: true, atribute: field };
    }

    return { descripcion: "data error", data: true, atribute: field }; // Dato incorrecto
};

export const validateAtributes = async (setSomeError, setSomeLoading) => {

    const attributes = await getUserAttributes();

    if (attributes.pais === 'CL') return;

    const validationLegajo = await LoginService.getValidationLegajo(attributes.pais)
    const validationDocumento = await LoginService.getValidationDocumento()

    const paisStatus = attributes.pais ? { descripcion: "success", data: true, atribute: attributes.pais } : { descripcion: "fail", data: false, atribute: attributes.pais };
    const legajoStatus = validateField(attributes.legajo, validationLegajo);
    const documentoStatus = validateField(attributes.documento, validationDocumento, true);

    const body = {
        pais: paisStatus,
        documento: documentoStatus,
        legajo: legajoStatus,
    }

    const statuses = [paisStatus, legajoStatus, documentoStatus];

    if (statuses.some(status => status.descripcion !== "success")) {
        await LoginService.AddErrorLogin(body);
    }
    if (!attributes.legajo || validationLegajo != attributes.legajo) {
        setSomeError({ etype: "atributosError" });
        setSomeLoading(false);
    }

}