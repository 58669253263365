import { Children, FC, useEffect } from "react";
import { SelectGroupsProps, RowGroupProps, Groups } from "../../../../utilities/interfaces";
import { onboardingStore } from "../../../../utilities/store";
import { useFormContext } from "react-hook-form";

const SelectGroups: FC<SelectGroupsProps> = ({ onboardingDetail, region }) => {
    const parameters = onboardingStore(state => state.parameters);
    const { setValue, watch, formState: { errors }, trigger } = useFormContext();
    
    const countryGroups = parameters.countries
        .find(c => c.country_id === region)?.groups || [];
    
    const groups = countryGroups.map(g => ({
        id: g.id,
        title: g.title,
        region: region
    }));

    const NOT_GROUP = "";
    const selectedGroups :Groups[] = watch('groups') || [];

    useEffect(() => {
        if (onboardingDetail && onboardingDetail.onboardingGroups.length > 0) {
            const selectedGroups = onboardingDetail.onboardingGroups.map((g) => ({
                id: g.id,
                title: g.title,
                region: g.region
            }));

            setValue('groups', selectedGroups);
        }
    }, [onboardingDetail]);

    const handleAddGroup = () => {
        const newGroups = [...selectedGroups, { id: NOT_GROUP, title: '', region: '' }];
        setValue('groups', newGroups);
    };

    const AddGroups = (id: string, index: number) => {
        const group = groups.find((g) => g.id === id);
        if(!group) return;
        setValue('groups', selectedGroups.map((g, i) => (i === index ? group : g)));
    };

    const DeleteGroup = (element: Groups) => {
        setValue('groups', selectedGroups.filter((e) => e.id !== element.id));
        trigger('groups');
    };

    return (
        <>
            <div className="box mb-4">
                <div className="tit-group d-flex justify-content-between align-items-center mt-4">
                    <h3 className="h5 fw-bold m-0">Seleccione los grupos que podrán ver esta publicación</h3>
                    <a className="mt-2" onClick={() => handleAddGroup()}>
                        <i className="fa fa-plus-square-o fa-lg fa-fw" aria-hidden="true"></i>
                    </a>
                </div>

                <div className="mt-3 p-3 bg-light-2 rounded-4 mb-4">
                    <table className="table table-section table-borderless align-middle d-none d-md-table text-uppercase">
                        <thead>
                            <tr>
                                <th className="col-6">Grupo</th>
                                <th className="col-3 text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Children.toArray(selectedGroups.map((element, index) => (
                                <RowGroup 
                                    index={index}
                                    element={element}
                                    AddGroups={AddGroups}
                                    DeleteGroup={DeleteGroup}
                                    NOT_GROUP={NOT_GROUP}
                                    region={region}
                                />
                            )))}
                        </tbody>
                    </table>
                    <div className="data-list-box data-list-box-align-middle data-list-box-form d-md-none">
                        {Children.toArray(selectedGroups.map((element, index) => (
                                <MobileSelectGroups
                                    index={index}
                                    element={element}
                                    AddGroups={AddGroups}
                                    DeleteGroup={DeleteGroup}
                                    NOT_GROUP={NOT_GROUP}
                                    region={region}
                                />
                            ))
                        )}
                    </div>
                </div>
                {errors.groups && <div style={{ display: "block" }} className="invalid-feedback">{errors.groups.message as string}</div>}
            </div>
        </>
    );
};

const RowGroup: FC<RowGroupProps> = ({ index, element, AddGroups, DeleteGroup, NOT_GROUP, region }) => {
    const parameters = onboardingStore(state => state.parameters);
    const { trigger } = useFormContext();
    const countryGroups = parameters.countries
        .find(c => c.country_id === region)?.groups || [];
    
    const groups = countryGroups.map(g => ({
        id: g.id,
        title: g.title,
        region: region
    }));

    return (
        <tr key={index}>
            <td>
                <select
                    id={`inputState${element.id}`}
                    className="form-select"
                    defaultValue={element.id}
                    onChange={(e) => {
                        AddGroups(e.target.value, index);
                        trigger('groups');
                    }}
                >
                    <option value=""> Seleccione Grupo </option>
                    {element.id === NOT_GROUP && groups.length > 0 ? (
                        Children.toArray(groups
                            .filter((g) => g.region === region)
                            .map((name, ind) => (
                                <option
                                    key={`array1${ind}`}
                                    value={name.id}>
                                    {name.title}
                                </option>
                            ))
                        )
                    ) : (
                        Children.toArray(groups
                            .filter((g) => g.region === region)
                            .map((name, ind) => (
                                <option
                                    key={`array1${ind}`}
                                    value={name.id}
                                >
                                    {name.title}
                                </option>
                            ))
                        )
                    )}
                </select>
            </td>
            <td className="text-center">
                <a id={`trash${index}`} onClick={(e) => {DeleteGroup(element)}}>
                    <i className="fa fa-trash-o fa-fw me-2" aria-hidden="true"></i>
                </a>
            </td>
        </tr>
    )
}

const MobileSelectGroups: FC<RowGroupProps> = ({ index, element, AddGroups, DeleteGroup, NOT_GROUP, region }) => {
    const parameters = onboardingStore(state => state.parameters);
    const { trigger } = useFormContext();

    const countryGroups = parameters.countries
        .find(c => c.country_id === region)?.groups || [];
    
    const groups = countryGroups.map(g => ({
        id: g.id,
        title: g.title,
        region: region
    }));

    return (
        <ul className="list-column-1 list-unstyled" key={index}>
            <li>
                <span className="data-list-box__title">
                    Grupo
                </span>
                <span className="data-list-box__text">
                    <select
                        id={`inputState${element.id}`}
                        className="form-select"
                        defaultValue={element.id}
                        onChange={(e) => {
                            AddGroups(e.target.value, index);
                            trigger('groups');
                        }}
                    >
                        <option value=""> Seleccione Grupo </option>
                        {element.id === NOT_GROUP && groups.length > 0 ? (
                            Children.toArray(groups
                                .filter((g) => g.region === region)
                                .map((name, ind) => (
                                    <option
                                        key={`array1${ind}`}
                                        value={name.id}>
                                        {name.title}
                                    </option>
                                ))
                            )
                        ) : (
                            Children.toArray(groups
                                .filter((g) => g.region === region)
                                .map((name, ind) => (
                                    <option
                                        key={`array1${ind}`}
                                        value={name.id}
                                    >
                                        {name.title}
                                    </option>
                                ))
                            )
                        )}
                    </select>
                </span>
            </li>
            <li>
                <span className="data-list-box__title">
                    Acciones
                </span>
                <span className="data-list-box__text">
                    <a
                        id={`trash${index}`}
                        onClick={() => {DeleteGroup(element)}}>
                        <i className="fa fa-trash-o fa-fw me-2" aria-hidden="true"></i>
                    </a>
                </span>
            </li>
        </ul>
    )
}

export default SelectGroups;