import { BreadcrumbList } from "../../components/breadcrumb";
import { Tab, Tabs } from "../../components/tab";
import { CompulsoryLifeInsurance } from "./compulsoryLifeInsurance/CompulsoryLifeInsurance";
import { OptionalLifeInsurance } from "./optionalLifeInsurance/OptionalLifeInsurance";
import { BurialInsurance } from "./burialInsurance/BurialInsurance";

export const InsuranceContent = () => {
  return (
    <div className="p-sm-3 container m-auto">
      <BreadcrumbList items={["Seguros"]} />
      <h1>Seguros</h1>
      <Tabs headerClassName="gap-5">
        <Tab label="Seguro de vida obligatorio" icon="plus" variant="line">
          <CompulsoryLifeInsurance />
        </Tab>
        <Tab label="Seguro de vida Optativo" icon="edit" variant="line">
          <OptionalLifeInsurance />
        </Tab>
        <Tab label="Seguro Sepelios" icon="hand" variant="line">
          <BurialInsurance />
        </Tab>
      </Tabs>
    </div>
  );
};
