import React, { useState, useEffect, useContext } from 'react';
import ReplacementsGrid from './replacements-grid';
import ReplacementsManagementService from '../../services/asignar-reemplazos/management-replacements.service';
import { BsExclamationCircle } from 'react-icons/bs';
import styles from './replacements.module.css';
import { FaRegSquarePlus } from 'react-icons/fa6';
import Modal from 'react-bootstrap/Modal';
import { FaChevronRight } from 'react-icons/fa';
import { debounce } from 'lodash';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Context } from '../../context';

const ManagementComponent = () => {
    const [replacements, setReplacements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataNotFetched, setDataNotFetched] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [results, setResults] = useState([]);
    const [replacementsResults, setReplacementsResults] = useState([]);
    const [loadingReplacementsResults, setLoadingReplacementsResults] = useState(false);
    const [loadingResults, setLoadingResults] = useState(false);
    const [showAutocompleteResults, setShowAutocompleteResults] = useState(false);
    const [showAutoCompleteReplacementResults, setShowAutoCompleteReplacementResults] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedReplacement, setSelectedReplacement] = useState({
        replacementUsername: '',
        fileNumber: '',
        nroDocument: '',
        init: '',
        end: '',
        name: '',
        email: '',
        replaced_name: '',
        replacedUsername: '',
        replacedFileNumber: '',
    });
    const [success, setSuccess] = useState(false);
    const [response, setResponse] = useState({});
    const [reload, setReload] = useState(false);
    const { pais } = useContext(Context);
    
    useEffect(() => {
        getReplacements();
    }, []);

    useEffect(() => {
        if (reload) {
            getReplacements();
            setReload(false);
        }
    }, [reload])
    useEffect(() => {
        if (success) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: green; font-size: 18px"> Completado </div>',
                html: `<p>El reemplazo se ha asignado correctamente con la siguiente información:</p>
                    <ul style= "list-style-type: none; text-align: center; font-size: 16px">
                        <li><strong>Empleado de reemplazo:</strong> ${response?.fullname}</li>
                        <li><strong>Empleado reemplazado:</strong> ${response?.replaced_name}</li>
                        <li><strong>Fecha inicio reemplazo:</strong> ${moment.utc(response?.init_date).format('DD-MM-YYYY')}</li>
                        <li><strong>Fecha fin:</strong> ${moment.utc(response?.return_date).format('DD-MM-YYYY')}</li>
                    </ul>`,
                confirmButtonColor: '#0169b5',
            }).then((result) => {
                if (result.isConfirmed) {
                    setSelectedReplacement({
                        replacementUsername: '',
                        fileNumber: '',
                        nroDocument: '',
                        init: '',
                        end: '',
                        name: '',
                        email: '',
                        replaced_name: '',
                        replacedUsername: '',
                        replacedFileNumber: '',
                        replacedNroDocument: ''
                    })
                    setResponse({});
                    setSuccess(false);
                    setShowCreateModal(false);
                }
            });
        }
    }, [success])
    useEffect(() => {
        if (errorMessage) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: `<div style="text-align: center; font-size: 15px"> ${errorMessage} </div>`,
                confirmButtonColor: '#0169b5',
            }).then((result) => {
                if (result.isConfirmed) {
                    setErrorMessage('');
                }
            });
        }
    }, [errorMessage])

    const getReplacements = async () => {
        try {
            await ReplacementsManagementService.getReplacements(setLoading, setReplacements, setDataNotFetched);
        }
        catch (error) {
            console.error('Error while getting replacements:', error);
        }
    }

    const handleReplacedDynamicSearch = debounce(async (e) => {
        setShowAutocompleteResults(true)
        const query = e.target.value.trim();
        if (!query) {
            setResults([]);
            setLoadingResults(false);
            return;
        }
        const isRegional = e.target.name !== "employeeToReplace";

        await ReplacementsManagementService.searchReplacements(query, setLoadingResults, setResults, isRegional, pais);
    }, 1000);

    const handleReplacementDynamicSearch = debounce(async (e) => {
        setShowAutoCompleteReplacementResults(true)
        const query = e.target.value.trim();
        if (!query) {
            setReplacementsResults([]);
            setLoadingReplacementsResults(false);
            return;
        }
        await ReplacementsManagementService.searchReplacements(query, setLoadingReplacementsResults, setReplacementsResults);
    }, 1000);

    const handleInputChange = (fieldName, value) => {
        setSelectedReplacement(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };
    const handleInputListChange = (values) => {
        setSelectedReplacement(prevState => ({
            ...prevState,
            ...values
        }));
        if (values.replaced_name) {
            document.getElementById('employeeToReplace').value = values.replaced_name;
            setShowAutocompleteResults(false);
        };
        if (values.name) {
            document.getElementById('replacementEmployee').value = values.name;
            setShowAutoCompleteReplacementResults(false);
        };
    };
    const validateDates = (init, end) => {
        let currentDate = moment().startOf('day');
        let selectedInitDate = moment(init);
        let selectedEndDate = moment(end);

        if (selectedInitDate.isAfter(selectedEndDate)) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> La fecha de inicio del reemplazo no puede ser posterior a la fecha de fin.</div>',
                confirmButtonColor: '#0169b5',
            });

            return false;
        }

        if (selectedInitDate.isBefore(currentDate)) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> La fecha de inicio del reemplazo no puede ser previa a la fecha actual.</div>',
                confirmButtonColor: '#0169b5',
            });
            return false;
        }
        if (selectedEndDate.isBefore(currentDate)) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> La fecha de inicion del reemplazo no puede ser previa a la fecha actual.</div>',
                confirmButtonColor: '#0169b5',
            });
            return false;
        }
        return true;
    }

    const handleCreate = async () => {
        const areDatesValid = validateDates(selectedReplacement.init, selectedReplacement.end);
        if (areDatesValid) {
            await ReplacementsManagementService.saveReplacement(setLoadingCreate, selectedReplacement, setResponse, setSuccess, setErrorMessage);
        }
    }

    return (
        <div className="d-flex justify-content-center p-0">
            {loading ? (
                <div className="spinner-border text-primary"></div>
            ) : dataNotFetched ? (
                <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                    <BsExclamationCircle className="inline-block mr-2" /> No se encontraron datos asociados o usted no posee acceso a este módulo.
                </div>
            ) : replacements.length > 0 ? (
                <React.Fragment>
                    <div className='row'>
                        <div onClick={() => setShowCreateModal(true)} className={`d-flex col mb-2 ${styles['new-btn']}`}>
                            <Tippy placement="right" content={"Asignar un reemplazo a alguno de sus subordinados"}>
                                <div>Asignar Nuevo Reemplazo <FaRegSquarePlus className={styles['new-icon']} /></div></Tippy>
                        </div>
                        <ReplacementsGrid replacements={replacements} onUpdate={setReload} />
                    </div>

                </React.Fragment>
            ) : null}

            <Modal show={showCreateModal}
                onHide={() => {
                    setShowCreateModal(false);
                    setResults([]);
                    setReplacementsResults([]);
                    setSelectedReplacement({
                        replacementUsername: '',
                        fileNumber: '',
                        nroDocument: '',
                        init: '',
                        end: '',
                        name: '',
                        email: '',
                        replaced_name: '',
                        replacedUsername: '',
                        replacedFileNumber: '',
                        replacedNroDocument: ''
                    })
                }}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className={styles['modal-title']}>Crear Reemplazo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*  Replaced Employee*/}
                    <div className="mb-3">
                        <label htmlFor="employeeToReplace" className='mb-1'>Seleccionar empleado a reemplazar:</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="employeeToReplace" 
                            name="employeeToReplace" 
                            placeholder='Buscar Empleado...'
                            onChange={handleReplacedDynamicSearch}
                        />
                        <div className={`${styles['employee-list']}`}>
                            <div className={styles['autocomplete-results']} style={{ display: showAutocompleteResults ? 'block' : 'none' }}>
                                {loadingResults && (
                                    <div className='d-flex justify-content-center'>
                                        <div className={`spinner-border spinner-border-sm text-primary m-2`}></div>
                                    </div>
                                )}
                                {!loadingResults && (
                                    results.map((employee) => (
                                        <div
                                            key={employee.identity}
                                            className={`${styles['employee-item']} d-flex align-items-center gap-2 ${selectedReplacement.replaced_name === employee.fullname ? styles['selected'] : ''}`}
                                        >
                                            <div onClick={() => handleInputListChange({
                                                replaced_name: employee.fullname,
                                                replacedUsername: employee.ad,
                                                replacedFileNumber: employee.legajo,
                                                replacedNroDocument: employee.nro_document
                                            })}>{employee.fullname}, {employee.position}, {employee.country}</div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Init and Return Date */}
                    <div className="mb-3">
                        <label htmlFor="init" className='mb-1'>Fecha de inicio:</label>
                        <input type="date" className="form-control" id="init"
                            onChange={(e) => handleInputChange("init", e.target.value)}
                            value={
                                selectedReplacement.init
                            } />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="end" className='mb-1'>Fecha de regreso:</label>
                        <input type="date" className="form-control" id="end"
                            onChange={(e) => handleInputChange("end", e.target.value)}
                            value={
                                selectedReplacement.end
                            } />
                    </div>

                    {/* Replacement */}
                    <div className="mb-3">
                        <label htmlFor="replacementEmployee" className='mb-1'>Empleado de reemplazo:</label>
                        <input type="text" className="form-control" id="replacementEmployee" placeholder="Buscar empleado..."
                            onChange={handleReplacementDynamicSearch}
                        />
                        <div className={`${styles['employee-list']}`}>
                            <div className={styles['autocomplete-results']} style={{ display: showAutoCompleteReplacementResults ? 'block' : 'none' }}>
                                {loadingReplacementsResults && (
                                    <div className='d-flex justify-content-center'>
                                        <div className={`spinner-border spinner-border-sm text-primary m-2`}></div>
                                    </div>
                                )}
                                {!loadingReplacementsResults && (
                                    replacementsResults.map((employee) => (
                                        <div
                                            key={employee.identity}
                                            className={`${styles['employee-item']} d-flex align-items-center gap-2 ${selectedReplacement.name === employee.fullname ? styles['selected'] : ''}`}
                                        >
                                            <div onClick={() => handleInputListChange({
                                                name: employee.fullname,
                                                nroDocument: employee.nro_document,
                                                fileNumber: employee.legajo,
                                                email: employee.email,
                                                replacementUsername: employee.ad

                                            })}>{employee.fullname}, {employee.position}, {employee.country}</div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Save Button */}
                    <div className={'col-12'}>
                        <div className={`${styles['button-container']} `}>
                            <button onClick={() => handleCreate()}
                                disabled={selectedReplacement.employeeToReplace === '' || selectedReplacement.init === '' || selectedReplacement.end === '' || selectedReplacement.replacementEmployee === ''}
                                className={`${styles['save-button']} ${loading ? styles['save-button-load'] : ''}`}>
                                Asignar {loadingCreate && <div className="spinner-border spinner-border-sm"></div>}
                                {!loadingCreate && <FaChevronRight />}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );

}

export default ManagementComponent;
