import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RemoteJob } from "../../RemoteJob/pages";
import BonusForm from "../components/BonusIndex";
import { typeBonus } from "../interfaces/interfaces";
import Bonos from "../../../bono";
import BreadcrumRequest from "../../Commons/Breadcrum/BreadcrumMyRequests";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "../../../../utils/enum/featureFlags.enum";
import { LoadingSpinner } from "../../../commonComponents/LoadingSpinner";

const Bonuses = () => {
  const { tipo }: { tipo?: typeBonus } = useParams();
  const { data, isError, isLoading } = useFeatureFlag(
    FEATURE_FLAGS.PREMIO_HABITACIONAL
  );
  const navigate = useNavigate();
  const [showPremioHab, setShowPremioHab] = useState(false);

  useEffect(() => {
    if (data && data?.isEnabled && !isLoading && !isError) {
      setShowPremioHab(true);
    }
    return () => {
      setShowPremioHab(false);
    };
  }, [data, isError, isLoading]);

  switch (tipo) {
    case "defuncion":
    case "matrimonio":
    case "nacimiento":
      return <Bonos />;

    case "premiohabitacional":
      if (isError) break;
      if (isLoading) return <LoadingSpinner />;
      if (showPremioHab) {
        return (
          <>
            <BreadcrumRequest title="Premio Habitacional" />
            <BonusForm type={tipo} />
          </>
        );
      }

      break;

    case "ley-de-conciliacion":
      return <RemoteJob />;
  }

  return (
    <>
      <div className="text-center mt-5">
        <p>
          Ha ocurrido un error al obtener la información o el bono no existe.
          Por favor, intente más tarde.
        </p>
      </div>
      <button
        onClick={() => navigate("/mis-solicitudes")}
        type="button"
        className="btn btn-link order-md-first"
      >
        Volver
      </button>
    </>
  );
};

export default Bonuses;
