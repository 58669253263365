import portalogoprod from "../resources/images/Logo-produccion.svg";
import portalogostage from "../resources/images/Logo-stage.svg";
import portalogotest from "../resources/images/Logo-test.svg";
import portalogodev from "../resources/images/Logo-dev.svg";
import portalogoOriginal from "../resources/images/mi-portal.svg";


type Environment = 'development' | 'test' | 'staging' | 'production';

const logoMap: Record<Environment, string> = {
    development: portalogodev,
    test: portalogotest,
    staging: portalogostage,
    production: portalogoprod,
};

export const getLogoByEnvironment = (): string => {

    // eslint-disable-next-line no-undef
    const env = process.env.REACT_APP_ENV  as Environment;

    return logoMap[env] || portalogoprod;
};