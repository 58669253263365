import React, { useState, useEffect } from 'react';
import styles from "../../resources/css/style-COL.module.css";
import { BotonsSaveCancel } from "../commonComponents/BotonsSave";
import { useForm } from "react-hook-form";
import { countrys } from './CountryDefn';
import { updateItem } from "../../services/maintanceServives/maintanceService";
import Swal from 'sweetalert2';

export const ModificarContenido = ({ onCancel, onSave, itemId, features }) => {

    const [infoAdd, setInfoAdd] = useState({})
    const [noDataAdd, setNoDataAdd] = useState(false)
    const [dataAdd, setDataAdd] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm()

    useEffect(() => {
        const findItemById = (id) => {
            const info = features.find(item => item.id === id);
            setInfoAdd(info);
        };
        findItemById(itemId);

    }, [itemId, features]);

    const onSaveClick = async (data) => {
        try {
            const submitData = {
                id: infoAdd.id,
                title: data.title === "" ? infoAdd.title : data.title,
                region: data.region === "" ? infoAdd.region : data.region,
                component: data.component === "" ? infoAdd.component : data.component,
                url: data.url === "" ? infoAdd.url : data.url,
                publico: data.publico === "" ? infoAdd.publico : data.publico,
                hasChildrens: data.hasChildrens === "" ? infoAdd.hasChildrens : data.hasChildrens,
                classValue: data.classValue === "" ? infoAdd.class : data.classValue,
                hasParent: data.hasParent === "" ? infoAdd.parent : data.hasParent,
                level: data.level === "" ? infoAdd.level : data.level,
                defaultComponent: data.defaultComponent === "" ? infoAdd.dfltComponent : data.defaultComponent,
                order: data.order === "" ? infoAdd.order : data.order,
                label: data.label === "" ? infoAdd.label : data.label,
                enabled: data.enabled === "" ? infoAdd.enabled : data.enabled,
                mantainance: data.mantainance === "" ? infoAdd.Maintenance : data.mantainance,
                enabledOnlyBoss: data.enabledOnlyBoss === "" ? infoAdd.enabledOnlyBoss : data.enabledOnlyBoss,
            };
            await updateItem(submitData, setNoDataAdd, setDataAdd, itemId, onSave);
            reset(infoAdd)
        } catch (error) {
            console.error(error);
            setNoDataAdd(true);
        }
    }

    const handleStatusError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        })
    }

    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        })
    }

    useEffect(() => {
        if (noDataAdd) {
            handleStatusError();
        }

        if (dataAdd) {
            handleStatusSuccess();
        }
    }, [noDataAdd, dataAdd]);

    return (
        <>
            <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Modificar</h2>

                <form>
                    {/* <!-- Primera Fila --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}> Título </label>
                            <input type="text" className={styles["form-control"]} {...register("title", { maxLength: 50 })} defaultValue={infoAdd.title} />
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Región</label>
                            <div className={styles["input-group", { required: "* Campo obligatorio" }]}>
                                <select {...register("region")} className={styles["select-col"]} >
                                    <option value="" >Seleccione</option>
                                    {
                                        countrys?.map((l, index) => {
                                            return (
                                                <option key={index} value={l.valor === 'PL' ? '' : l.valor} selected={infoAdd.region === l.valor}>{l.descripcion === 'Publicas' ? 'Ninguno' : l.descripcion}</option>
                                            )
                                        })}
                                </select>
                                <small className={styles["form-error-message"]}>{errors.region?.message}</small>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Segunda Fila --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}> Componente </label>
                            <input type="text" className={styles["form-control"]} {...register("component", { maxLength: 50 })} defaultValue={infoAdd.component} />
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}> Url </label>
                            <input type="text" className={styles["form-control"]} {...register("url", { maxLength: 50 })} defaultValue={infoAdd.url} />
                        </div>
                    </div>

                    {/* <!-- Tercer Fila --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Publico</label>
                            <div className={styles["input-group"]}>
                                <select {...register("publico")} className={styles["select-col"]} >
                                    <option value="" >Seleccione</option>
                                    <option value="1" selected={infoAdd.publico === 1}>Si</option>)
                                    <option value="0" selected={infoAdd.publico === 0}>No</option>)
                                </select>
                            </div>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Has childrens</label>
                            <div className={styles["input-group"]}>
                                <select {...register("hasChildrens")} className={styles["select-col"]} >
                                    <option value="" >Seleccione</option>
                                    <option value="1" selected={infoAdd.hasChildrens === 1}>Si</option>)
                                    <option value="0" selected={infoAdd.hasChildrens === 0}>No</option>)
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Cuarta Fila --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}> Class </label>
                            <input type="text" className={styles["form-control"]} {...register("classValue", { maxLength: 50 })} defaultValue={infoAdd.class} />
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}> Parent </label>
                            <input type="number" className={styles["form-control"]} min={0} {...register("hasParent")} defaultValue={infoAdd.parent} />
                        </div>
                    </div>

                    {/* <!-- Quinta Fila --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}> Level </label>
                            <input type="number" className={styles["form-control"]} min={0} {...register("level")} defaultValue={infoAdd.level} />
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Componente Default</label>
                            <div className={styles["input-group"]}>
                                <select {...register("defaultComponent")} className={styles["select-col"]} >
                                    <option value="" >Seleccione</option>
                                    <option value="1" selected={infoAdd.dfltComponent === 1}>Si</option>)
                                    <option value="0" selected={infoAdd.dfltComponent === 0}>No</option>)
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Sexta Fila --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}> Order </label>
                            <input type="number" className={styles["form-control"]} min={0} {...register("order")} defaultValue={infoAdd.order} />
                        </div>

                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}> Label </label>
                            <input type="text" className={styles["form-control"]} {...register("label")} defaultValue={infoAdd.label} />
                        </div>

                    </div>

                    {/* <!-- Septima Fila --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Enabled</label>
                            <div className={styles["input-group"]}>
                                <select {...register("enabled")} className={styles["select-col"]} >
                                    <option value="" >Seleccione</option>
                                    <option value="1" selected={infoAdd.enabled === 1}>Si</option>)
                                    <option value="0" selected={infoAdd.enabled === 0}>No</option>)
                                </select>
                            </div>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Mantenimiento</label>
                            <div className={styles["input-group"]}>
                                <select {...register("mantainance")} className={styles["select-col"]} >
                                    <option value="" >Seleccione</option>
                                    <option value="1" selected={infoAdd.Maintenance}>Si</option>)
                                    <option value="0" selected={!infoAdd.Maintenance}>No</option>)
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Disponible solo Jefe</label>
                            <div className={styles["input-group"]}>
                                <select {...register("enabledOnlyBoss")} className={styles["select-col"]} >
                                    <option value="" >Seleccione</option>
                                    <option value="1" selected={infoAdd.enabledOnlyBoss === 1}>Si</option>)
                                    <option value="0" selected={infoAdd.enabledOnlyBoss === 0}>No</option>)
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Final de las Filas --> */}
                </form>

                <BotonsSaveCancel cancelHandler={onCancel} saveHandler={handleSubmit(onSaveClick)} />

            </div>
        </>
    )
}