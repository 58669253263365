import React from "react";
import { CollectiveAgreementDetailContentProps } from "./hooks/CollectiveAgreementDetail.types";
import { FaDownload } from "react-icons/fa";

export const CollectiveAgreementDetailContent: React.FC<
  CollectiveAgreementDetailContentProps
> = ({ selectedRequest, onBack }) => {
  const statusColors: { [key: string]: string } = {
    Aprobado: "#90ee90",
    Pendiente: "#87cefa",
    Cancelado: "#f08080",
  };

  return (
    <>
      <div
        className="card p-5"style={{borderRadius: "8px",boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",}}>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <p>
              <strong>Beneficio adquirido:</strong> {" "}{selectedRequest?.benefitName}
            </p>
            <p>
              <strong>Nro Solicitud:</strong> {selectedRequest?.requestId}
            </p>
            <p>
              <strong>Fecha Solicitud:</strong> {selectedRequest?.dateRequest}
            </p>
          </div>
          <div className="col-md-4">
            <p>
              <strong>Nro Beneficio:</strong> {selectedRequest?.benefitId}
            </p>
            {/* <p>
              <strong>Archivo:</strong>{" "}
              <a href="/path-to-your-file" className="text-primary">
                Descargar <FaDownload className="ms-1" />
              </a>
            </p> */}
          </div>
          <span>
            <span
              style={{
                color: statusColors[selectedRequest?.status || "Pendiente"],
              }}
            >
              ●
            </span>{selectedRequest?.status}
          </span>
        </div>
        <button className="btn btn-primary mt-3" onClick={onBack}>
          Volver al listado
        </button>
      </div>
    </>
  );
};
