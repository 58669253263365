import { FC } from "react";
import { onboardingStore } from "../../../../utilities/store";

const SelectsOnb: FC = () => {
  const parameters = onboardingStore((state) => state.parameters);
  const countries = parameters.countries.map((p) => ({
    id: p.country_id,
    name: p.country_name
  }));
  const filters = onboardingStore((state) => state.filters);
  const setFilters = onboardingStore((state) => state.setFilters);

  return (
    <div className="row mb-1 w-auto">
      <div className="col-md">
        <label className="form-label">País</label>
        <div className="input-group mb-3">
          <select
            defaultValue={filters.country}
            className="form-select"
            onChange={(e) => {
              setFilters({ ...filters, country: e.target.value });
            }}
          >
            <option value=""> Todos </option>
            <option value="regional">Regional</option>
            {countries.map((country, index) => (
              <option key={"array1" + index} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-md">
        <label className="form-label">Vigencia Desde</label>
        <div className="d-flex align-items-center">
          <input
            type="date"
            placeholder=""
            className="form-control"
            defaultValue={filters.startDate || ""}
            onChange={(e) => {
              setFilters({ ...filters, startDate: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="col-md">
        <label className="form-label">Vigencia Hasta</label>
        <div className="d-flex align-items-center">
          <input
            type="date"
            placeholder=""
            className="form-control"
            defaultValue={filters.endDate || ""}
            onChange={(e) => {
              setFilters({ ...filters, endDate: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="col-md">
        <label className="form-label">Estado de Publicación</label>
        <div className="input-group mb-3">
          <select
            className="form-select"
            defaultValue={''}
            onChange={(e) => {
              if (e.target.value === undefined) {
                setFilters({ ...filters, status: "" });
              } else {
                setFilters({ ...filters, status: e.target.value });
              }
            }}
          >
            <option value="">
              Todos
            </option>
            <option value="published">Publicada</option>
            <option value="unpublished">Despublicada</option>
            <option value="draft">Borrador</option>
          </select>
        </div>
      </div>
    </div>
  );
};
export default SelectsOnb;
