import React, { FC, useEffect, useRef, useState } from "react";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { useForm } from "react-hook-form";
import { BenefitProps, GetParamsBenefit, Groups, NewBenefitProps, ParametersFilter, PostBenefit, typeLinks, bodyPostBenefit, GetBenefitByIDProps, HistoryProp, typeFile, DataModalDinamicProps, GetPhotoBenefit } from "../../../utilities/interfaces";
import ContentBenefit from "./components/content/content";
import FormBenefit from "./components/form/form";
import { HttpError } from "../../../../../services/apiServices/interfaces";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import { httpClientEnv } from "../../../../../services/apiServices/httpClient";
import { crudBenefit, getBenefitByID, getParamsBenefit, getRemotePhotosBenefits } from "../../../utilities/apiServices/apiRequest";
import ModalLoad from "../../../../commonComponents/ModalLoad";
import { scrollToTop } from "../../../../../services/utiles";
import ModalInfoDinamic from "../../../../commonComponents/ModalnfoDinamic";
import ModalConfirmAction from "../../../../commonComponents/ModalConfirmAction";
import ModalPreview from "./components/modalPrev/modalPreview";

const NewBenefit: FC<NewBenefitProps> = ({
    setNewBenefit, 
    setShowTable, 
    isEditBenefit, 
    setIdBenefit,  
    idBenefit, 
    setIsEditBenefit, 
    refConfirmAction,  
    refLoad, 
    refStatus, 
    refLoadClose,
    pais
}) => {
    const { register, handleSubmit, getValues, control, setValue, reset, watch,  formState: {errors, isDirty, isValid}, trigger, clearErrors, setError } = useForm<BenefitProps>({ mode: "onSubmit" });
    const [postDataBenefit, setPostDataBenefit] = useState<RemoteData<HttpError, PostBenefit>>(RD.initial);
    const [remoteDataBenefitByID, setRemoteDataBenefitByID] = useState<RemoteData<HttpError, GetBenefitByIDProps>>(RD.initial);
    const [remoteDataParams, setRemoteDataParams] = useState<RemoteData<HttpError, GetParamsBenefit>>(RD.initial);
    const [remoteDataPhoto, setRemoteDataPhoto] = useState<RemoteData<HttpError, GetPhotoBenefit>>(RD.initial);
    const [textDescr, setTextDescr] = useState('');
    const [textCond, setTextCond] = useState('');
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [arrayGroup, setArrayGroup] = useState<Groups[]>([]);
    const [arrayLinks, setArrayLinks] = useState<typeLinks[]>([])
    const [selectCountry, setSelectCountry] = useState(pais || '');
    const [selectModule, setSelectModule] = useState("");
    const [selectCategory, setSelectCategory] = useState('');
    const [errorSelectGroup, setErrorSelectGroup] = useState('');
    const [errorUrl, setErrorUrl] = useState('');
    const [msjErrorDate, setMsjErrorDate] = useState('');
    const [imgCard, setImgCard] = useState('');
    const [imgBanner, setImgBanner] = useState('');
    const [file, setFile] = useState<typeFile>({file: '', name_file: ''});
    const [isFile, setIsFile] = useState<boolean>(false);
    const [history, setHistory] = useState<HistoryProp[]>([])
    const [paramFilter, setParamFilter] = useState<ParametersFilter[]>([]);
    const [dataModal, setDataModal] = useState<DataModalDinamicProps>({
        callBack : ()=> null,
        icon: '',
        msg: ''
    })
    const watchedValues = watch();
    const refConfirmActionSubmit = useRef<HTMLButtonElement>(null);
    const refCloseStatus = useRef<HTMLButtonElement>(null);

    const createOrUpdateBenefit = async () => {
        refLoad && refLoad.current && refLoad.current.click();
        const newGroups = arrayGroup.filter((group) => !group.disabled)
        const uniqueIds = new Set<number | string>();
        const uniqueNewGroups = newGroups.filter((group) => {
        if (!uniqueIds.has(group.id)) {
            uniqueIds.add(group.id);
            return true;
        }
        return false;
        });

        let body: bodyPostBenefit = {
            title: getValues('title'),
            is_visible_title: getValues('is_visible_title') ? getValues('is_visible_title') : false,
            is_featured: getValues('is_featured'),
            subtitle: getValues('subtitle'),
            category_id: getValues('category_id'),
            img_banner: imgBanner,
            img_card: imgCard,
            date_to: getValues('date_to'),
            date_from: getValues('date_from'),
            file: file.file,
            name_file: file.name_file,
            description: getValues('description').trim(),
            condition: getValues('condition').trim(),
            module_id: selectModule ? selectModule : null,
            status: getValues('status'),
            country_id: selectCountry,
            groups: uniqueNewGroups,
            links: arrayLinks
        }

        if (isEditBenefit) {
            body.id = idBenefit
        }

        let method = isEditBenefit ? 'PUT' : 'POST';
        RTE.run(await crudBenefit(body, method), httpClientEnv)
            .then(E.fold(e => { return setPostDataBenefit(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess' && data.value.valid) {
                    refLoadClose && refLoadClose.current && refLoadClose.current.click();
                    refStatus && refStatus.current && refStatus.current.click();
                    setDataModal({
                        msg: getValues('status') === 'published' ? 'Beneficio publicado correctamente' : 'Borrador guardado exitosamente',
                        icon: 'fa-check-circle-o',
                        callBack: () => {
                            refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                            setNewBenefit(false);
                            setShowTable(true);
                            setIsEditBenefit(false);
                        }
                    })

                    return setPostDataBenefit(RD.success(a))
            } else {
                return setPostDataBenefit(RD.failure({ tag: 'httpRequestError', error: 'Valid createOrUpdateBenefit' }))
            } 
        }))
    };

    const getParams = async () => {
        refLoad && refLoad.current && refLoad.current.click();
        setRemoteDataParams(RD.pending)
        RTE.run(await getParamsBenefit(), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataParams(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess' && data.value.response.parameters.length > 0) {
                setTimeout(()=> {
                    refLoadClose && refLoadClose.current && refLoadClose.current.click();
                },500)
                return setRemoteDataParams(RD.success(a))
            } else {
                return setRemoteDataParams(RD.failure({ tag: 'httpRequestError', error: 'length getParams' }))
            } 
        }))
    }

    const GetBenefitByID = async (idBenef: number) => {
        setRemoteDataBenefitByID(RD.pending)
        RTE.run(await getBenefitByID(idBenef), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataBenefitByID(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    return setRemoteDataBenefitByID(RD.success(a));
                } else {
                    return setRemoteDataBenefitByID(RD.failure({ tag: 'httpRequestError', error: 'length GetBenefitByID 0' }));
                } 
            }))
    };

    const resetStatus = () => {
        setNewBenefit(false)
        setShowTable(true)
        reset();
        setRemoteDataBenefitByID(RD.initial)
        setIdBenefit(0);
        scrollToTop();
        if (isEditBenefit) {
            setIsEditBenefit(false)
        }
        refCloseStatus && refCloseStatus.current && refCloseStatus.current.click()
    }

    const onSubmit = () => {
        let filterArrayGroup = arrayGroup.filter((gr) => gr.id === 0);
        let filterLinks = arrayLinks.filter((link) => link.url === '' || !link.url.includes("https://"));
        let dateIni = new Date (getValues('date_from'))
        let dateFin = new Date (getValues('date_to'));
        if (dateFin < dateIni) {
            setMsjErrorDate("La fecha debe ser mayor o igual a la fecha de inicio");
            const divScroll = document.getElementById('divDates');
            divScroll && divScroll.scrollIntoView();
        } else if (arrayGroup.length === 0 || filterArrayGroup.length > 0) {
            setErrorSelectGroup('Seleccione uno o más grupos.')
            const divScroll = document.getElementById('divGroups');
            divScroll && divScroll.scrollIntoView();
        } else if(filterLinks.length > 0){
            setErrorUrl('Ingrese una url válida')
            const divScroll = document.getElementById('divVideos');
            divScroll && divScroll.scrollIntoView();
        } else {
            setMsjErrorDate('');
            setErrorSelectGroup('');
            setErrorUrl('');
            createOrUpdateBenefit();
        }
    }

    useEffect(()=>{
        scrollToTop();
        getParams();
    },[])

    useEffect(()=> {
        if(postDataBenefit._tag === 'RemoteFailure'){
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            setDataModal({
                msg:'Ha ocurrido un error al crear beneficio, por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click()
                }
            })
            setTimeout(()=> {
                refStatus && refStatus.current && refStatus.current.click();
            },500)
        }
    },[postDataBenefit])

    useEffect(()=> {
        if(remoteDataParams._tag === 'RemoteSuccess') {
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            let params = remoteDataParams.value.response.parameters;
            const filteredParams = params
            .filter(param => param.country === selectCountry)
            .map(param => ({
                ...param,
                categories: param.categories.flat(),
                groups: param.groups.flat(),
                modules: param.modules.flat(),
            }));
            setParamFilter(selectCountry !=='' ? filteredParams : params);
            if(isLoadingData){
                setIsLoadingData(false);
            } else {
                setValue('category_id', 0);
                trigger('category_id');
                setArrayGroup([]);
            }

        } else if(remoteDataParams._tag === 'RemoteFailure') {
            setTimeout(()=> {
                refLoadClose && refLoadClose.current && refLoadClose.current.click();
            },500)
            setDataModal({
                msg:'Ha ocurrido un error al conseguir información, por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    resetStatus();
                }
            })
            setTimeout(()=> {
                refStatus && refStatus.current && refStatus.current.click();
            },1000);

        }
    },[remoteDataParams, selectCountry])

    useEffect(()=> {
        if (!isDirty || !isValid) {
            setDisabledBtn(true)
        } else {
            setDisabledBtn(false)
        }
    },[isDirty, isValid])

    useEffect(()=> {
        if(isEditBenefit && idBenefit > 0) {
            GetBenefitByID(idBenefit)
        }
    },[isEditBenefit, idBenefit])

    useEffect(()=>{
        if (remoteDataBenefitByID._tag === 'RemoteSuccess') {
            let params = remoteDataBenefitByID.value.response.benefit;
            let groups = remoteDataBenefitByID.value.response.groups;
            let videos = remoteDataBenefitByID.value.response.benefit.videos;
            let history = remoteDataBenefitByID.value.response.benefit.history;
            let idBenefit = remoteDataBenefitByID.value.response.benefit.id;
            getRemotePhotosBenefits([idBenefit], setRemoteDataPhoto, setImgCard, true, setImgBanner)
            setIsLoadingData(true);
            setSelectCountry(params.country_id);
            setValue('title', params.title);
            setValue('subtitle', params.subtitle);
            setValue('is_visible_title', params.is_visible_title);
            setValue('is_featured', params.is_featured ? params.is_featured : false);
            setValue('category_id', params.category_id);
            setValue('date_to', params.date_to ? params.date_to : "");
            setValue('date_from', params.date_from ? params.date_from : "");
            setValue('description', params.description);
            setTextDescr(params.description);
            setTextCond(params.condition);
            setValue('condition', params.condition);
            setArrayLinks(videos);
            setValue('module_id', params.module_id)
            setSelectModule(params.module_id ? String(params.module_id) : "");
            if (groups.length === 0) {
                refLoadClose && refLoadClose.current && refLoadClose.current.click();
                refStatus && refStatus.current && refStatus.current.click();
                setDataModal({
                    msg:'Ha ocurrido un error al conseguir información de grupos, por favor, intente más tarde.',
                    icon: 'fa-exclamation-triangle',
                    callBack: () => {
                        resetStatus();
                    }
                })
            }
            setArrayGroup(groups);
            setIsFile(remoteDataBenefitByID.value.response.isFile);
            if(history){
                const orderHistory = [...history].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());            setHistory(history);
                setHistory(orderHistory);
            }
        }
    },[remoteDataBenefitByID])

    useEffect(()=>{
        if (selectCategory !=='') {
            setValue('category_id', Number(selectCategory))
        }
    },[selectCategory])
    
    return(
        <>
            <section className="gestion-de-beneficios">
            <h1>Gestión de Beneficios</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormBenefit 
                        register={register}
                        arrayGroup={arrayGroup}
                        setArrayGroup={setArrayGroup}
                        selectCountry={selectCountry}
                        setSelectCountry={setSelectCountry}
                        errorSelectGroup={errorSelectGroup}
                        errors={errors} 
                        control={control}
                        imgBanner={imgBanner}  
                        setImgBanner={setImgBanner}
                        imgCard={imgCard}
                        setImgCard={setImgCard}
                        setFile={setFile}
                        msjErrorDate={msjErrorDate}
                        setSelectCategory={setSelectCategory}
                        isEditBenefit={isEditBenefit}
                        isErrorPhoto={remoteDataPhoto._tag === 'RemoteFailure'}
                        paramFilter={paramFilter.length > 0 ? paramFilter : [] }
                        pais={pais}
                        isFile={isFile}
                        idBenefit={idBenefit}
                        clearErrors={clearErrors}
                        setError={setError}
                        file={file}
                        setIsFile={setIsFile}
                        setValue={setValue}
                        watch={watch}
                        />
                    <ContentBenefit 
                        errors={errors} 
                        errorUrl={errorUrl}
                        textDescr={textDescr}  
                        arrayLinks={arrayLinks}
                        selectModule={selectModule}
                        history={history} 
                        paramFilter={paramFilter.length > 0 ? paramFilter : [] }
                        isEditBenef={isEditBenefit} 
                        control={control} 
                        register={register}
                        setValue={setValue}
                        setTextDescr={setTextDescr} 
                        setArrayLinks={setArrayLinks}                        
                        setSelectModule={setSelectModule}
                        trigger={trigger}
                        setTextCond={setTextCond}
                        textCond={textCond}
                    />
                    <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap mt-4">
                        <div className="d-grid d-md-flex gap-2">
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#preview">Previsualizar</button>
                            <button type="button" onClick={()=>{
                                refConfirmActionSubmit && refConfirmActionSubmit.current && refConfirmActionSubmit.current.click();
                            }} disabled={Object.keys(errors).length > 0 || disabledBtn || remoteDataParams._tag === 'RemoteFailure'} className="btn btn-degradado ms-md-2" data-bs-toggle="modal" data-bs-target="#seguro-publicar">Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                        </div>
                        <button type="button" className="btn btn-link order-md-first" data-bs-toggle="modal" data-bs-target="#back" onClick={() =>{
                            refConfirmAction && refConfirmAction.current && refConfirmAction.current.click();
                        }}>Volver</button>
                    </div>
                </form>
            </section>

            <ModalInfoDinamic refOpen={refStatus} icon={dataModal.icon} text={dataModal.msg} subtext={dataModal.submsg} callBack={dataModal.callBack} refClose={refCloseStatus}/>

            <ModalLoad text="Cargando información..." refOpen={refLoad} refClose={refLoadClose} data_target={'modalLoadNewBenefit'}/>
            
            <ModalConfirmAction refOpen={refConfirmActionSubmit} data_target={'modalConfirmAction2'} text={watchedValues.status === 'published' ? `¿Estás seguro que deseas publicar el beneficio?`: '¿Estás seguro que deseas guardar el borrador?'} 
            callBack={()=>{ onSubmit() }}/>

            <ModalConfirmAction refOpen={refConfirmAction} text={`¿Estás seguro que deseas volver al listado? Se perderán los datos ingresados.`} 
            callBack={()=>{ resetStatus() }}/>

            <ModalPreview arrayLinks={arrayLinks} imgCard={imgCard} watchedValues={watchedValues}/>
        </>
    )    

}
export default NewBenefit;