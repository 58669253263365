import { FC } from "react";
import { ModalStrategy, DefaultModalProps, ConfirmActionModalProps, StatusModalProps, LoadingModalProps, ModalProps, CustomModalProps } from "./interfaces";
import { useModalStore } from "../../../stores/useModalStore";

class DefaultModalStrategy implements ModalStrategy {
    constructor(private props: DefaultModalProps) {}
    
    getModalContent() {
        return {
            title: this.props.title,
            content: this.props.content,
            footer: this.props.footer
        };
    }
}

class LoadingModalStrategy implements ModalStrategy {
    constructor(private props: LoadingModalProps) {}
    
    getModalContent() {
        return {
            content: (
                <div className="text-center">
                    <span className="spinner-border spinner-border-sm" />
                    <p>{this.props.text || 'Cargando información...'}</p>
                </div>
            ),
            footer: undefined
        };
    }
}

class ConfirmActionModalStrategy implements ModalStrategy {
    constructor(private props: ConfirmActionModalProps) {}
    
    getModalContent() {
        return {
            content: <h2 className="h5">{this.props.text}</h2>,
            footer: (
                <>
                    <button
                        type="button"
                        className="btn btn-primary px-5"
                        onClick={() => useModalStore.setState({ isOpen: false })}
                    >
                        No
                    </button>
                    <button
                        onClick={() => {
                            this.props.onConfirm();
                            useModalStore.setState({ isOpen: false });
                        }}
                        type="button"
                        className="btn btn-degradado px-5"
                    >
                        Sí <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                    </button>
                </>
            )
        };
    }
}

class StatusModalStrategy implements ModalStrategy {
    constructor(private props: StatusModalProps) {}
    
    getModalContent() {
        return {
            content: (
                <>
                    <div className="d-flex justify-content-center flex-column">
                        {this.props.status === 'ok' && <i className="fa fa-check-circle-o fa-5x color-orange" aria-hidden="true"></i>}
                        {this.props.status === 'error' && <i className="fa fa-exclamation-circle fa-5x color-orange" aria-hidden="true"></i>}
                        <p className="mt-3">{this.props.text}</p> 
                    </div>
                    <button
                        type="button"
                        className="btn btn-degradado px-5 mt-3"
                        onClick={() => {useModalStore.setState({ isOpen: false }); this.props.onAccept()}}
                    >
                        Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                    </button>   
                </>
            )
        };
    }
}

class CustomModalStrategy implements ModalStrategy {
    constructor(private props: CustomModalProps) {}
    
    getModalContent() {
        return {
            variant: 'custom',
            content: this.props.content
        };
    }
}

function createModalStrategy(props: ModalProps): ModalStrategy {
    let strategy: ModalStrategy;
    switch(props.variant) {
        case 'loading':
            strategy = new LoadingModalStrategy(props);
            break;
        case 'confirm':
            strategy = new ConfirmActionModalStrategy(props);
            break;
        case 'status':
            strategy = new StatusModalStrategy(props);
            break;
        case 'custom':
            strategy = new CustomModalStrategy(props);
            break;
        default:
            strategy = new DefaultModalStrategy(props);
    }
    const modalContent = strategy.getModalContent();
    return {
        getModalContent: () => ({
            variant: props.variant,
            title: modalContent.title || undefined,
            content: modalContent.content,
            footer: modalContent.footer || undefined
        })
    };
}

export const toggleModal = (props?: ModalProps) => {
    if (props) {
        const strategy = createModalStrategy(props);
        const modalContent = strategy.getModalContent();
        useModalStore.setState({
            isOpen: true,
            size: props.size || '',
            variant: props.variant,
            ...modalContent
        });
    } else {
        useModalStore.setState({
            isOpen: false,
            title: undefined,
            content: undefined,
            footer: undefined,
            size: '',
            variant: 'default',
            onClose: () => {}
        });
    }
};

export const Modal: FC = () => {
    const {
        isOpen,
        content,
        variant,
        title,
        footer,
        onClose,
        size
    } = useModalStore((state) => state);
    const state = useModalStore((state) => state);

    if (!isOpen) {
        return null;
    }

    if (variant === "custom") {
        return (
            <div className="position-fixed top-0 start-0 w-100 h-100" style={{ zIndex: 1055 }}>
                {content}
                <div className="modal-backdrop fade show"></div>
            </div>
        );
    }

    const modalSize = size ? `modal-${size}` : '';

    const handleClose = () => {
        toggleModal();
        onClose && onClose();
    };
    return (
        <>
            <div
                className={`modal fade ${isOpen ? 'show' : ''}`}
                id="genericModal"
                aria-labelledby="genericModal"
                aria-hidden={!isOpen}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                style={{ display: isOpen ? 'block' : 'none' }}
            >
                <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            {title && <h5 className="modal-title">{title}</h5>}
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            />
                        </div>
                        <div className="modal-body text-center py-4">
                            {content}
                        </div>
                        {footer && (
                            <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                                {footer}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};
