import React, { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Auth from "@aws-amplify/auth";
import { useForm } from "react-hook-form";
import { ISurvey, SurveyFormValues } from "./interfaces";
import { toggleModal } from "../commonComponents/Modal";
import { urlsAws } from "../../resources/foo/api-endpoints";
import useApiForm from "./hooks/useApiForm";
import { BreadCrumbsDinamic } from "../commonComponents";
import SurveyFormComponent from "./components/SurveyForm";

const SurveyContent: FC<{ session: CognitoUserSession }> = ({ session }) => {
  const { search } = useLocation();
  const methods = useForm<SurveyFormValues>();
  const navigate = useNavigate();

  const token = useMemo(() => {
    if (!search) return null;
    const surveyParam = search.split("survey=")[1];
    return surveyParam ? { type: 'survey', value: surveyParam } : null;
  }, []); 

  const url = useMemo(() => {
    if (!token) return "";
    return `${urlsAws.survey}?${token.type}=${token.value}`;
  }, []); 

  const {
    initialData,
    initialError,
    submitData,
    submitError,
    loading,
    handleSubmit: handleSubmitCallback,
  } = useApiForm({ url, session });

  useEffect(() => {
    if (loading) {
      toggleModal({ variant: 'loading' });
    } else {
      toggleModal();
    }
  }, [loading]);

  useEffect(() => {
    if (initialError || submitData || submitError) {
      toggleModal({
        variant: 'status',
        status: initialError || submitError ? 'error' : 'ok',
        text: initialError || submitError || 
          "Hemos recibido satisfactoriamente tus respuestas. Recuerda que son confidenciales y para uso exclusivo de RRHH. Gracias por ayudarnos a mejorar la experiencia de nuestr@s colaboradores.",
        onAccept: () => {
          if (initialError || submitData) {
            navigate("/inicio");
          }
        }
      });
    }
  }, [initialError, submitError, submitData, navigate]);

  return (
    <>
      <div className="m-3">
        <BreadCrumbsDinamic paths={[{ label: "Encuesta", url: "/encuestas" }]} />
      </div>
      {initialData && (
        <SurveyFormComponent
          survey={initialData as ISurvey}
          handleSubmitCallback={handleSubmitCallback}
          methods={methods}
        />
      )}
    </>
  );
};

const SurveyPrivate: FC = () => {
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    
    const checkAuth = async () => {
      try {
        const currentSession = await Auth.currentSession();
        if (mounted) {
          setSession(currentSession);
        }
      } catch (error) {
        console.error('Error al verificar sesión:', error);
        if (mounted) {
          setSession(null);
          navigate('/');
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    checkAuth();

    return () => {
      mounted = false;
    };
  }, [navigate]);

  if (isLoading || !session) {
    return null;
  }

  return <SurveyContent session={session} />;
};

export default SurveyPrivate; 