import React, { FC } from "react";
import { modalPreview, typeLinks } from "../../../../../utilities/interfaces";
import Slider from "../../../../../../commonComponents/Slider";
import { formatDate } from "@fullcalendar/react";
import moment from "moment";

const transformDataForSlider = (data: typeLinks[]) => {
    return data.map((video, i) => (
        <div key={i} className="col-12 col-md-4">
            <div className="ratio ratio-16x9 mb-4">
                <iframe className="embed-responsive-item" src={video.url}></iframe>
            </div>
        </div>
    ));
};

const ModalPreview: FC<modalPreview> = ({ watchedValues, imgCard, arrayLinks, isFile }) => {
    const renderHTML = (rawHTML: any) => (
        <div
            className="render-html-content"
            dangerouslySetInnerHTML={{ __html: rawHTML }}
        />
    );

    let filterLinks = arrayLinks.filter((link) => link.url && link.url.includes("https://"));

    return (
        <>
            <div className="modal fade" id="preview" aria-labelledby="preview" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered custom-modal-width">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h2 className="h5">Vista previa</h2>
                            <div className="box mb-4">
                                <div className="row align-items-center g-0">
                                <div className="col-md-8 d-flex flex-column justify-content-center">
                                    <div className="card-body p-4">
                                        <div className="color-gradient-1 fw-bold fs-1 mb-4">{watchedValues?.title}</div>
                                        <p className="fs-4 fw-bold m-0">{watchedValues?.subtitle}</p>
                                    </div>
                                </div>

                                <div className="col-md-4 text-md-end d-flex flex-column align-items-md-end">
                                    <div className="align-items-start">
                                        {isFile && (
                                            <p className="fs-6 mb-2 text-start">
                                                {'Descargar: '}
                                                <a
                                                    className="btnLink"
                                                >
                                                    Archivo adjunto <i className="fa fa-download fa-fw color-orange" aria-hidden="true"></i>
                                                </a>
                                            </p>
                                        )}
                                        <p className="fs-6 text-start">
                                            {' Publicado: '}
                                            <span className="color-primary">{moment(new Date()).format("DD/MM/YYYY")}</span>
                                        </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="text-end mb-4">
                            <div className="mt-4 text-center text-md-end btn-news">
                                <button className="btn color-primary-strong" onClick={(e) => {}}><i className="fa fa-comment-o fa-fw fa-lw color-primary" aria-hidden="true"></i></button>
                                <button className="btn color-primary-strong"><i id="rojo" onClick={(e) => { }} style={{ color: "#d2d2d2" }} className="fa fa-heart fa-fw fa-lg" aria-hidden="true"></i> 0 Me gusta</button>
                                <button className="btn color-primary-strong" onClick={(e) => {}}><i className="fa fa-paper-plane-o fa-fw color-primary" aria-hidden="true"></i> Compartir</button>
                                </div>
                            </div>

                            <div className="box mb-4">
                                <h2 className="h4 tit-section">Descripción</h2>
                                <div className="condition-content">{renderHTML(watchedValues?.description)}</div>
                            </div>
                            {watchedValues?.condition &&
                                <div className="box mb-4">
                                    <h2 className="h4 tit-section">Condiciones</h2>
                                    <div className="condition-content">{renderHTML(watchedValues?.condition)}</div>
                                </div>
                            }
                            {filterLinks.length > 0 &&
                                <div className="box mb-5">
                                    <h2 className="h4 tit-section">Videos</h2>
                                    <Slider
                                        id="videos-beneficios"
                                        data={transformDataForSlider(filterLinks)}
                                    />
                                </div>
                            }
                        </div>
                        <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                            <button type="submit" className="btn btn-degradado col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal">Cerrar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalPreview;