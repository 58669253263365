import { useCallback, useEffect, useState } from "react"

export const useCrud = <T extends { id: string | number }>(
    initialData: T[] = []
) => {
    const [dataList, setDataList] = useState<T[]>(initialData);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setDataList(initialData);
      }, [initialData]);

    const createItem = useCallback(
        async (newItem: Omit<T, 'id'>) => {
            setIsLoading(true);
            try {
                const createdItem = {
                    ...newItem,
                    id: Date.now()
                } as T;

                setDataList(prev => [...prev, createdItem]);
                return createdItem;
            } catch (err) {
                setError(err instanceof Error ? err.message : "Error creating Item");
                return null;
            }
            finally {
                setIsLoading(false);
            }
        },
        [],
    )

    const deleteItem = useCallback(
        async (itemId: string | number) => {
            setIsLoading(true);
            try {
                setDataList(prev => prev.filter(item => item.id !== itemId));
                setError(null);
            }
            catch (err) {
                setError(err instanceof Error ? err.message : "Error delete Item");
                return null;
            }
            finally {
                setIsLoading(false);
            }
        },
        [],
    );

    const updateItem = useCallback(
        (updatedItem: T) => {
            setIsLoading(true);
            try {
                setDataList(prev => prev.map(item => item.id === item.id ? updatedItem : item));
                setError(null);
            }
            catch (err) {
                setError(err instanceof Error ? err.message : "Error delete Item");
                return null;
            }
            finally {
                setIsLoading(false);
            }
        },
        [],
    )

    return {
        dataList,
        setDataList,
        isLoading,
        error,
        createItem,
        updateItem,
        deleteItem
    } as const;
} 