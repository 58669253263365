const logout = () => {
    sessionStorage.clear();
    for (var key in localStorage) {
        if (key !== "bankList" && key !== "DireccionCHL" && key !== "RegionesCHL" && key !== "ComunasCHL") {
            localStorage.removeItem(key);
        }
    }
    caches.keys().then((keys) => {
        keys.forEach((key) => {
            if (!key.match(/workbox/g)) {
                caches.delete(key);
            }
        });
    });

    window.location.pathname = "/";
};

export default logout;
