import Modal from "react-bootstrap/Modal";
import { ModalItemFormProps } from "./crudGenerator.types";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

export const ModalItemForm = <T extends { id: string | number }>(
  props: ModalItemFormProps<T>
) => {
  const {
    show,
    handleClose,
    formFields,
    createItem,
    editedItem,
    setEditItem,
    updateItem,
    titleModalCreateEdit,
  } = props;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = (values: any) => {
    if (editedItem && updateItem) {
      updateItem(values);
    } else {
      createItem && createItem(values);
    }
    onClose();
  };

  const onClose = () => {
    editedItem && setEditItem(null);
    handleClose();
    reset();
  };

  useEffect(() => {
    if (show) {
      if (editedItem) {
        reset(editedItem);
      } else {
        const initialValues = formFields.reduce((acc, field) => {
          acc[field.id] = "";
          return acc;
        }, {} as Record<string, any>);
        reset(initialValues);
      }
    }
  }, [editedItem, formFields, reset, show]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editedItem?.id ? "Editar" : "Crear"} {titleModalCreateEdit}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            {formFields.map((field, i) => (
              <div className="col-12 mb-3" key={field.id + i}>
                <label className="form-label">
                  {field.label}
                  {field.required && <span className="color-red">*</span>}
                </label>
                {field.type === "select" ? (
                  <select
                    className="form-control"
                    {...register(field.id, {
                      required: field.required,
                    })}
                  >
                    {field.options?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    className="form-control"
                    {...register(field.id, {
                      required: field.required,
                      maxLength: 255,
                      ...(field.min !== undefined ? { min: field.min } : {}),
                      ...(field.max !== undefined ? { max: field.max } : {}),
                    })}
                    min={field.min}
                    max={field.max}
                  />
                )}
                {errors[field.id] && (
                  <small className="form-error-message">{field.errorMsg}</small>
                )}
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-between">
            <button onClick={onClose} className="btn btn-primary" type="button">
              Cancelar
            </button>
            <button className="btn btn-degradado">Guardar</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
